<template>
    <div>
        <el-select
            v-model="selectedTimeZone" 
            filterable
            placeholder="Search..."
            class="w-full flex-1"
            :disabled="disabled"
        >
            <el-option 
                v-for="option in options"
                :key="option"
                :value="option"
                :label="option.replace(/_/g, ' ')"
            >
                {{ option | timeZone }}
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            options: [
                'America/Adak',
                'America/Anchorage',
                'America/Atikokan',
                'America/Atka',
                'America/Blanc-Sablon',
                'America/Boise',
                'America/Cambridge_Bay',
                'America/Chicago',
                'America/Coral_Harbour',
                'America/Dawson_Creek',
                'America/Dawson',
                'America/Denver',
                'America/Detroit',
                'America/Edmonton',
                'America/Fort_Wayne',
                'America/Glace_Bay',
                'America/Goose_Bay',
                'America/Halifax',
                'America/Indiana/Indianapolis',
                'America/Indiana/Knox',
                'America/Indiana/Marengo',
                'America/Indiana/Petersburg',
                'America/Indiana/Vevay',
                'America/Indiana/Vincennes',
                'America/Indianapolis',
                'America/Inuvik',
                'America/Iqaluit',
                'America/Juneau',
                'America/Kentucky/Louisville',
                'America/Kentucky/Monticello',
                'America/Knox_IN',
                'America/Los_Angeles',
                'America/Louisville',
                'America/Menominee',
                'America/Moncton',
                'America/Montreal',
                'America/New_York',
                'America/Nipigon',
                'America/Nome',
                'America/North_Dakota/Center',
                'America/North_Dakota/New_Salem',
                'America/Pangnirtung',
                'America/Phoenix',
                'America/Rainy_River',
                'America/Rankin_Inlet',
                'America/Regina',
                'America/Shiprock',
                'America/St_Johns',
                'America/Swift_Current',
                'America/Thunder_Bay',
                'America/Toronto',
                'America/Vancouver',
                'America/Whitehorse',
                'America/Winnipeg',
                'America/Yakutat',
                'America/Yellowknife',
                'Canada/Atlantic',
                'Canada/Central',
                'Canada/East-Saskatchewan',
                'Canada/Eastern',
                'Canada/Mountain',
                'Canada/Newfoundland',
                'Canada/Pacific',
                'Canada/Saskatchewan',
                'Canada/Yukon',
                'Pacific/Honolulu',
                'Pacific/Johnsto',
                'Pacific/Midway',
                'Pacific/Wake',
            ],
            // Initialize selectedTimeZone in data to prevent the reference error
            selectedTimeZone: this.value,
        };
    },
    watch: {
        value(newValue) {
            this.selectedTimeZone = newValue; // Update selectedTimeZone when the prop changes
        },
        selectedTimeZone(newValue) {
            this.$emit('input', newValue); // Emit the new value for v-model
            this.$emit('update-tenant');
        }
    }
}
</script>
