/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCard = /* GraphQL */ `
  query GetCard($id: ID!) {
    getCard(id: $id) {
      id
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const listCards = /* GraphQL */ `
  query ListCards(
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        stripeSetupIntent
        stripeCustomerId
        stripePaymentMethodId
        active
        createdAt
        chargeError
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCompanyScoreCard = /* GraphQL */ `
  query GetCompanyScoreCard($id: ID!) {
    getCompanyScoreCard(id: $id) {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      pickupSuccessBehaviors
      pickupSuccessBehaviorsText
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyScoreCards = /* GraphQL */ `
  query ListCompanyScoreCards(
    $filter: ModelCompanyScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyScoreCards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        overall
        safetyAndCompliance
        comprehensiveAudit
        safetyScore
        safeDriving
        safeDrivingText
        fico
        seatbeltOff
        seatbeltOffText
        speedingEvent
        speedingEventText
        dvcrCompliance
        dvcrComplianceText
        onTimeCompliance
        onTimeComplianceText
        complianceScoreText
        workingHoursCompliance
        workingHoursComplianceText
        dspAudit
        dspAuditText
        thirtyDaysNoticeText
        customerEscalationDefectDPMO
        customerEscalationDefectDPMOText
        team
        highPerformersShare
        highPerformersShareText
        lowPerformersShare
        lowPerformersShareText
        attritionRate
        attritionRateText
        quality
        deliveryCompletion
        deliveryCompletionText
        deliveredAndReceived
        deliveredAndReceivedText
        standardWorkComplianceText
        photoOnDelivery
        photoOnDeliveryText
        contactCompliance
        contactComplianceText
        scanCompliance
        scanComplianceText
        attendedDeliveryAccuracy
        attendedDeliveryAccuracyText
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        distractionsRateText
        followingDistanceRateText
        signSignalViolationsRateText
        scorecardPdf
        podQualityPdf
        customerFeedbackPdf
        ppsCsv
        harshBrakingEvent
        harshCorneringEvent
        harshBrakingEventText
        harshCorneringEventText
        deliverySlotPerformance
        deliverySlotPerformanceText
        tenantId
        customerDeliveryExperience
        customerDeliveryFeedback
        customerDeliveryFeedbackText
        cdfDpmo
        cdfDpmoText
        breachOfContract
        tenuredWorkforce
        tenuredWorkforceText
        deliverySuccessBehaviors
        deliverySuccessBehaviorsText
        pickupSuccessBehaviors
        pickupSuccessBehaviorsText
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCounselingTemplate = /* GraphQL */ `
  query GetCounselingTemplate($id: ID!) {
    getCounselingTemplate(id: $id) {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
    }
  }
`;
export const listCounselingTemplates = /* GraphQL */ `
  query ListCounselingTemplates(
    $filter: ModelCounselingTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounselingTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        templateName
        counselingNotes
        priorDiscussionSummary
        correctiveActionSummary
        consequencesOfFailure
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageTemplate = /* GraphQL */ `
  query GetMessageTemplate($id: ID!) {
    getMessageTemplate(id: $id) {
      id
      group
      name
      message
      createdAt
      updatedAt
    }
  }
`;
export const listMessageTemplates = /* GraphQL */ `
  query ListMessageTemplates(
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRecurringMessages = /* GraphQL */ `
  query GetRecurringMessages($id: ID!) {
    getRecurringMessages(id: $id) {
      id
      group
      messageName
      messageBody
      sendTime
      channelType
      senderId
      senderName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const listRecurringMessagess = /* GraphQL */ `
  query ListRecurringMessagess(
    $filter: ModelRecurringMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurringMessagess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        messageName
        messageBody
        sendTime
        channelType
        senderId
        senderName
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDrugTest = /* GraphQL */ `
  query GetDrugTest($id: ID!) {
    getDrugTest(id: $id) {
      id
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listDrugTests = /* GraphQL */ `
  query ListDrugTests(
    $filter: ModelDrugTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrugTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        date
        location
        results
        file
        fileName
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getInjury = /* GraphQL */ `
  query GetInjury($id: ID!) {
    getInjury(id: $id) {
      id
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      completedByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const listInjurys = /* GraphQL */ `
  query ListInjurys(
    $filter: ModelInjuryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInjurys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPendingMessage = /* GraphQL */ `
  query GetPendingMessage($id: ID!) {
    getPendingMessage(id: $id) {
      id
      group
      createdAt
      staffId
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const listPendingMessages = /* GraphQL */ `
  query ListPendingMessages(
    $filter: ModelPendingMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        createdAt
        staffId
        channelType
        destinationNumber
        destinationEmail
        numberOfCO
        numberOfPR
        numberOfWeeklyCO
        numberOfWeeklyPR
        numberOfDailyCO
        numberOfMentorCO
        numberOfNetradyneCO
        numberOfEocCO
        numberOfDailyPR
        numberOfMentorPR
        numberOfNetradynePR
        numberOfEocPR
        bodyText
        bodyTextCO
        bodyTextPR
        bodyTextDailyCO
        bodyTextDailyPR
        bodyTextMentorCO
        bodyTextMentorPR
        bodyTextNetradyneCO
        bodyTextNetradynePR
        bodyTextEocCO
        bodyTextEocPR
        bodyTextWeeklyCO
        bodyTextWeeklyPR
        subject
        bodyHtml
        importId
        smsSendInformation
        emailSendInformation
        isRead
        startLastWeeklyImport
        endLastWeeklyImport
        lastDailyImport
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReplacedByStandbyByRoute = /* GraphQL */ `
  query GetReplacedByStandbyByRoute($id: ID!) {
    getReplacedByStandbyByRoute(id: $id) {
      id
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listReplacedByStandbyByRoutes = /* GraphQL */ `
  query ListReplacedByStandbyByRoutes(
    $filter: ModelReplacedByStandbyByRouteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReplacedByStandbyByRoutes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        routeNumber
        standby
        time
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
    }
  }
`;
export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        defaultCostStandard
        defaultCostBundle
        defaultCostPerformance
        defaultCostRostering
        defaultCostStaff
        defaultCostVehicles
        importDisabled
        importPodDisabled
        importCxDisabled
        importPpsDisabled
        importMentorDisabled
        importNetradyneDisabled
        importEocDisabled
        isZohoCrmDisabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDailyRostersColumns = /* GraphQL */ `
  query GetDailyRostersColumns($id: ID!) {
    getDailyRostersColumns(id: $id) {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
    }
  }
`;
export const listDailyRostersColumnss = /* GraphQL */ `
  query ListDailyRostersColumnss(
    $filter: ModelDailyRostersColumnsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyRostersColumnss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        order
        label
        isFixed
        isDisplayed
        displayedFor
        btnEditCustomList
        customListType
        isCustomizable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTextractJob = /* GraphQL */ `
  query GetTextractJob($id: ID!) {
    getTextractJob(id: $id) {
      id
      group
      owner
      userId
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      cxFeedbackSummary {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      properParkingSequenceSummary {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTextractJobs = /* GraphQL */ `
  query ListTextractJobs(
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTextractJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPodQualitySummary = /* GraphQL */ `
  query GetPodQualitySummary($id: ID!) {
    getPodQualitySummary(id: $id) {
      id
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPodQualitySummarys = /* GraphQL */ `
  query ListPodQualitySummarys(
    $filter: ModelPodQualitySummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodQualitySummarys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCxFeedbackSummary = /* GraphQL */ `
  query GetCxFeedbackSummary($id: ID!) {
    getCxFeedbackSummary(id: $id) {
      id
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCxFeedbackSummarys = /* GraphQL */ `
  query ListCxFeedbackSummarys(
    $filter: ModelCxFeedbackSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCxFeedbackSummarys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWave = /* GraphQL */ `
  query GetWave($id: ID!) {
    getWave(id: $id) {
      id
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const listWaves = /* GraphQL */ `
  query ListWaves(
    $filter: ModelWaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        startTime
        endTime
        waveName
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStripeQueue = /* GraphQL */ `
  query GetStripeQueue($id: ID!) {
    getStripeQueue(id: $id) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listStripeQueues = /* GraphQL */ `
  query ListStripeQueues(
    $filter: ModelStripeQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        tenant
        result
        invoiceId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAuditLogArchive = /* GraphQL */ `
  query GetAuditLogArchive($id: ID!) {
    getAuditLogArchive(id: $id) {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      createdAt
      auditLogCreatedAt
      ipAddress
      pageUrl
      userType
      updatedAt
    }
  }
`;
export const listAuditLogArchives = /* GraphQL */ `
  query ListAuditLogArchives(
    $filter: ModelAuditLogArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditLogArchives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        createdAt
        auditLogCreatedAt
        ipAddress
        pageUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNickname = /* GraphQL */ `
  query GetNickname($id: ID!) {
    getNickname(id: $id) {
      id
      name
      nicknames
      createdAt
      updatedAt
    }
  }
`;
export const listNicknames = /* GraphQL */ `
  query ListNicknames(
    $filter: ModelNicknameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNicknames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nicknames
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cardsByGroup = /* GraphQL */ `
  query CardsByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cardsByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        stripeSetupIntent
        stripeCustomerId
        stripePaymentMethodId
        active
        createdAt
        chargeError
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const companyScoreCardsByGroup = /* GraphQL */ `
  query CompanyScoreCardsByGroup(
    $group: String
    $yearWeek: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyScoreCardsByGroup(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        overall
        safetyAndCompliance
        comprehensiveAudit
        safetyScore
        safeDriving
        safeDrivingText
        fico
        seatbeltOff
        seatbeltOffText
        speedingEvent
        speedingEventText
        dvcrCompliance
        dvcrComplianceText
        onTimeCompliance
        onTimeComplianceText
        complianceScoreText
        workingHoursCompliance
        workingHoursComplianceText
        dspAudit
        dspAuditText
        thirtyDaysNoticeText
        customerEscalationDefectDPMO
        customerEscalationDefectDPMOText
        team
        highPerformersShare
        highPerformersShareText
        lowPerformersShare
        lowPerformersShareText
        attritionRate
        attritionRateText
        quality
        deliveryCompletion
        deliveryCompletionText
        deliveredAndReceived
        deliveredAndReceivedText
        standardWorkComplianceText
        photoOnDelivery
        photoOnDeliveryText
        contactCompliance
        contactComplianceText
        scanCompliance
        scanComplianceText
        attendedDeliveryAccuracy
        attendedDeliveryAccuracyText
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        distractionsRateText
        followingDistanceRateText
        signSignalViolationsRateText
        scorecardPdf
        podQualityPdf
        customerFeedbackPdf
        ppsCsv
        harshBrakingEvent
        harshCorneringEvent
        harshBrakingEventText
        harshCorneringEventText
        deliverySlotPerformance
        deliverySlotPerformanceText
        tenantId
        customerDeliveryExperience
        customerDeliveryFeedback
        customerDeliveryFeedbackText
        cdfDpmo
        cdfDpmoText
        breachOfContract
        tenuredWorkforce
        tenuredWorkforceText
        deliverySuccessBehaviors
        deliverySuccessBehaviorsText
        pickupSuccessBehaviors
        pickupSuccessBehaviorsText
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const scorecardsByTenantAndYearWeek = /* GraphQL */ `
  query ScorecardsByTenantAndYearWeek(
    $tenantId: ID
    $yearWeek: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scorecardsByTenantAndYearWeek(
      tenantId: $tenantId
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        overall
        safetyAndCompliance
        comprehensiveAudit
        safetyScore
        safeDriving
        safeDrivingText
        fico
        seatbeltOff
        seatbeltOffText
        speedingEvent
        speedingEventText
        dvcrCompliance
        dvcrComplianceText
        onTimeCompliance
        onTimeComplianceText
        complianceScoreText
        workingHoursCompliance
        workingHoursComplianceText
        dspAudit
        dspAuditText
        thirtyDaysNoticeText
        customerEscalationDefectDPMO
        customerEscalationDefectDPMOText
        team
        highPerformersShare
        highPerformersShareText
        lowPerformersShare
        lowPerformersShareText
        attritionRate
        attritionRateText
        quality
        deliveryCompletion
        deliveryCompletionText
        deliveredAndReceived
        deliveredAndReceivedText
        standardWorkComplianceText
        photoOnDelivery
        photoOnDeliveryText
        contactCompliance
        contactComplianceText
        scanCompliance
        scanComplianceText
        attendedDeliveryAccuracy
        attendedDeliveryAccuracyText
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        distractionsRateText
        followingDistanceRateText
        signSignalViolationsRateText
        scorecardPdf
        podQualityPdf
        customerFeedbackPdf
        ppsCsv
        harshBrakingEvent
        harshCorneringEvent
        harshBrakingEventText
        harshCorneringEventText
        deliverySlotPerformance
        deliverySlotPerformanceText
        tenantId
        customerDeliveryExperience
        customerDeliveryFeedback
        customerDeliveryFeedbackText
        cdfDpmo
        cdfDpmoText
        breachOfContract
        tenuredWorkforce
        tenuredWorkforceText
        deliverySuccessBehaviors
        deliverySuccessBehaviorsText
        pickupSuccessBehaviors
        pickupSuccessBehaviorsText
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const counselingTemplatesByGroup = /* GraphQL */ `
  query CounselingTemplatesByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCounselingTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    counselingTemplatesByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        templateName
        counselingNotes
        priorDiscussionSummary
        correctiveActionSummary
        consequencesOfFailure
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageTemplatesByGroup = /* GraphQL */ `
  query MessageTemplatesByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageTemplatesByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const recurringMessageByGroup = /* GraphQL */ `
  query RecurringMessageByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recurringMessageByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        messageName
        messageBody
        sendTime
        channelType
        senderId
        senderName
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const drugTestsByGroup = /* GraphQL */ `
  query DrugTestsByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDrugTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    drugTestsByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        location
        results
        file
        fileName
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const injuriesByGroup = /* GraphQL */ `
  query InjuriesByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInjuryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    injuriesByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const injuriesByStaff = /* GraphQL */ `
  query InjuriesByStaff(
    $staffId: String
    $sortDirection: ModelSortDirection
    $filter: ModelInjuryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    injuriesByStaff(
      staffId: $staffId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const injuriesByGroupAndCreatedAt = /* GraphQL */ `
  query InjuriesByGroupAndCreatedAt(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInjuryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    injuriesByGroupAndCreatedAt(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const pendingMessagesByGroup = /* GraphQL */ `
  query PendingMessagesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPendingMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pendingMessagesByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        staffId
        channelType
        destinationNumber
        destinationEmail
        numberOfCO
        numberOfPR
        numberOfWeeklyCO
        numberOfWeeklyPR
        numberOfDailyCO
        numberOfMentorCO
        numberOfNetradyneCO
        numberOfEocCO
        numberOfDailyPR
        numberOfMentorPR
        numberOfNetradynePR
        numberOfEocPR
        bodyText
        bodyTextCO
        bodyTextPR
        bodyTextDailyCO
        bodyTextDailyPR
        bodyTextMentorCO
        bodyTextMentorPR
        bodyTextNetradyneCO
        bodyTextNetradynePR
        bodyTextEocCO
        bodyTextEocPR
        bodyTextWeeklyCO
        bodyTextWeeklyPR
        subject
        bodyHtml
        importId
        smsSendInformation
        emailSendInformation
        isRead
        startLastWeeklyImport
        endLastWeeklyImport
        lastDailyImport
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const replacedRoutesByGroup = /* GraphQL */ `
  query ReplacedRoutesByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReplacedByStandbyByRouteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    replacedRoutesByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        routeNumber
        standby
        time
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const dailyRostersColumnsByGroup = /* GraphQL */ `
  query DailyRostersColumnsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyRostersColumnsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyRostersColumnsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        order
        label
        isFixed
        isDisplayed
        displayedFor
        btnEditCustomList
        customListType
        isCustomizable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const textractJobsByGroup = /* GraphQL */ `
  query TextractJobsByGroup(
    $group: String
    $jobStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByGroup(
      group: $group
      jobStatus: $jobStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const textractJobsByOwner = /* GraphQL */ `
  query TextractJobsByOwner(
    $owner: String
    $jobStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByOwner(
      owner: $owner
      jobStatus: $jobStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const textractJobsByJobId = /* GraphQL */ `
  query TextractJobsByJobId(
    $jobId: String
    $jobStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByJobId(
      jobId: $jobId
      jobStatus: $jobStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const textractJobsByOwnerAndIsProcessedS = /* GraphQL */ `
  query TextractJobsByOwnerAndIsProcessedS(
    $owner: String
    $isProcessedS: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByOwnerAndIsProcessedS(
      owner: $owner
      isProcessedS: $isProcessedS
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const textractJobsByGroupAndDate = /* GraphQL */ `
  query TextractJobsByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const textractJobsByGroupAndWeek = /* GraphQL */ `
  query TextractJobsByGroupAndWeek(
    $group: String
    $week: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByGroupAndWeek(
      group: $group
      week: $week
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const podQualitySummaryByGroupAndWeek = /* GraphQL */ `
  query PodQualitySummaryByGroupAndWeek(
    $group: String
    $week: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPodQualitySummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    podQualitySummaryByGroupAndWeek(
      group: $group
      week: $week
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const podQualitySummaryByGroupAndYearWeek = /* GraphQL */ `
  query PodQualitySummaryByGroupAndYearWeek(
    $group: String
    $yearWeek: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPodQualitySummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    podQualitySummaryByGroupAndYearWeek(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cxFeedbackSummaryByGroupAndWeek = /* GraphQL */ `
  query CxFeedbackSummaryByGroupAndWeek(
    $group: String
    $week: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCxFeedbackSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cxFeedbackSummaryByGroupAndWeek(
      group: $group
      week: $week
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cxFeedbackSummaryByGroupAndYearWeek = /* GraphQL */ `
  query CxFeedbackSummaryByGroupAndYearWeek(
    $group: String
    $yearWeek: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCxFeedbackSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cxFeedbackSummaryByGroupAndYearWeek(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const stripeQueueByGroup = /* GraphQL */ `
  query StripeQueueByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStripeQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stripeQueueByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        tenant
        result
        invoiceId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const archivelogsByGroupUserAndCreatedAt = /* GraphQL */ `
  query ArchivelogsByGroupUserAndCreatedAt(
    $group: String
    $emailCreatedAt: ModelAuditLogArchiveByGroupUserAndCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    archivelogsByGroupUserAndCreatedAt(
      group: $group
      emailCreatedAt: $emailCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        createdAt
        auditLogCreatedAt
        ipAddress
        pageUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const archivelogsByTenantIDAndMutationName = /* GraphQL */ `
  query ArchivelogsByTenantIDAndMutationName(
    $tenantID: String
    $mutationName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    archivelogsByTenantIDAndMutationName(
      tenantID: $tenantID
      mutationName: $mutationName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        createdAt
        auditLogCreatedAt
        ipAddress
        pageUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const archivelogsByGroupUserMutationAndCreatedAt = /* GraphQL */ `
  query ArchivelogsByGroupUserMutationAndCreatedAt(
    $group: String
    $emailMutationNameCreatedAt: ModelAuditLogArchiveByGroupUserMutationAndCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    archivelogsByGroupUserMutationAndCreatedAt(
      group: $group
      emailMutationNameCreatedAt: $emailMutationNameCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        createdAt
        auditLogCreatedAt
        ipAddress
        pageUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const nicknamesByName = /* GraphQL */ `
  query NicknamesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelNicknameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NicknamesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nicknames
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccident = /* GraphQL */ `
  query GetAccident($id: ID!) {
    getAccident(id: $id) {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listAccidents = /* GraphQL */ `
  query ListAccidents(
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const accidentsByGroup = /* GraphQL */ `
  query AccidentsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const accidentsByStaff = /* GraphQL */ `
  query AccidentsByStaff(
    $staffId: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByStaff(
      staffId: $staffId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const accidentsByVehicle = /* GraphQL */ `
  query AccidentsByVehicle(
    $vehicleId: String
    $accidentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByVehicle(
      vehicleId: $vehicleId
      accidentDate: $accidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const accidentsByGroupAndAccidentDate = /* GraphQL */ `
  query AccidentsByGroupAndAccidentDate(
    $group: String
    $accidentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByGroupAndAccidentDate(
      group: $group
      accidentDate: $accidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const accidentsByStaffAndHistoryType = /* GraphQL */ `
  query AccidentsByStaffAndHistoryType(
    $staffId: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByStaffByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByStaffAndHistoryType(
      staffId: $staffId
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const accidentsByVehicleAndHistoryType = /* GraphQL */ `
  query AccidentsByVehicleAndHistoryType(
    $vehicleId: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByVehicelByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByVehicleAndHistoryType(
      vehicleId: $vehicleId
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const accidentsByGroupAndHistoryType = /* GraphQL */ `
  query AccidentsByGroupAndHistoryType(
    $group: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByGroupByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByGroupAndHistoryType(
      group: $group
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      message {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const attachmentsByGroup = /* GraphQL */ `
  query AttachmentsByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attachmentsByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEocScore = /* GraphQL */ `
  query GetEocScore($id: ID!) {
    getEocScore(id: $id) {
      id
      group
      date
      level
      average
      averageDailyCompliance
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listEocScores = /* GraphQL */ `
  query ListEocScores(
    $filter: ModelEocScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEocScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        date
        level
        average
        averageDailyCompliance
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eocScoresByTextractJob = /* GraphQL */ `
  query EocScoresByTextractJob(
    $group: String
    $textractJobId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEocScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eocScoresByTextractJob(
      group: $group
      textractJobId: $textractJobId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        level
        average
        averageDailyCompliance
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eocScoresByGroup = /* GraphQL */ `
  query EocScoresByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEocScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eocScoresByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        level
        average
        averageDailyCompliance
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eocScoresByGroupAndDate = /* GraphQL */ `
  query EocScoresByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEocScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eocScoresByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        level
        average
        averageDailyCompliance
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eocScoresByGroupLevelAndDate = /* GraphQL */ `
  query EocScoresByGroupLevelAndDate(
    $group: String
    $levelDate: ModelEocScoreByGroupLevelAndDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEocScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eocScoresByGroupLevelAndDate(
      group: $group
      levelDate: $levelDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        level
        average
        averageDailyCompliance
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCounseling = /* GraphQL */ `
  query GetCounseling($id: ID!) {
    getCounseling(id: $id) {
      id
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      counselingTypeId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      severity {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const listCounselings = /* GraphQL */ `
  query ListCounselings(
    $filter: ModelCounselingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounselings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const counselingsByGroup = /* GraphQL */ `
  query CounselingsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCounselingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    counselingsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const counselingsByGroupAndStatus = /* GraphQL */ `
  query CounselingsByGroupAndStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCounselingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    counselingsByGroupAndStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const counselingsByGroupAndDate = /* GraphQL */ `
  query CounselingsByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCounselingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    counselingsByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const counselingsByGroupAndDateSigned = /* GraphQL */ `
  query CounselingsByGroupAndDateSigned(
    $group: String
    $dateSigned: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCounselingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    counselingsByGroupAndDateSigned(
      group: $group
      dateSigned: $dateSigned
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getShortenUrl = /* GraphQL */ `
  query GetShortenUrl($id: ID!) {
    getShortenUrl(id: $id) {
      id
      group
      type
      shortenId
      expirationTTL
      urlToken
      attachmentId
      rosterChecklistSubjectId
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listShortenUrls = /* GraphQL */ `
  query ListShortenUrls(
    $filter: ModelShortenUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShortenUrls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        type
        shortenId
        expirationTTL
        urlToken
        attachmentId
        rosterChecklistSubjectId
        isOpenLink
        urlParameters
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const shortenUrlsByShortenId = /* GraphQL */ `
  query ShortenUrlsByShortenId(
    $shortenId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShortenUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shortenUrlsByShortenId(
      shortenId: $shortenId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        shortenId
        expirationTTL
        urlToken
        attachmentId
        rosterChecklistSubjectId
        isOpenLink
        urlParameters
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const shortenUrlsByGroup = /* GraphQL */ `
  query ShortenUrlsByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelShortenUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shortenUrlsByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        shortenId
        expirationTTL
        urlToken
        attachmentId
        rosterChecklistSubjectId
        isOpenLink
        urlParameters
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const shortenUrlsByAttachmentId = /* GraphQL */ `
  query ShortenUrlsByAttachmentId(
    $attachmentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShortenUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shortenUrlsByAttachmentId(
      attachmentId: $attachmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        shortenId
        expirationTTL
        urlToken
        attachmentId
        rosterChecklistSubjectId
        isOpenLink
        urlParameters
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const shortenUrlsByRosterChecklistSubject = /* GraphQL */ `
  query ShortenUrlsByRosterChecklistSubject(
    $rosterChecklistSubjectId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShortenUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shortenUrlsByRosterChecklistSubject(
      rosterChecklistSubjectId: $rosterChecklistSubjectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        shortenId
        expirationTTL
        urlToken
        attachmentId
        rosterChecklistSubjectId
        isOpenLink
        urlParameters
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDailyRoster = /* GraphQL */ `
  query GetDailyRoster($id: ID!) {
    getDailyRoster(id: $id) {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      notes {
        items {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const listDailyRosters = /* GraphQL */ `
  query ListDailyRosters(
    $filter: ModelDailyRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyRosters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const dailyRostersByGroup = /* GraphQL */ `
  query DailyRostersByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyRostersByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const dailyRostersByGroupAndNotesDate = /* GraphQL */ `
  query DailyRostersByGroupAndNotesDate(
    $group: String
    $notesDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyRostersByGroupAndNotesDate(
      group: $group
      notesDate: $notesDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const dailyRostersByNotesDate = /* GraphQL */ `
  query DailyRostersByNotesDate(
    $notesDate: String
    $sortDirection: ModelSortDirection
    $filter: ModelDailyRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyRostersByNotesDate(
      notesDate: $notesDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchedulerTask = /* GraphQL */ `
  query GetSchedulerTask($id: ID!) {
    getSchedulerTask(id: $id) {
      id
      type
      apiEndpoint
      apiStage
      apiPath
      lambdaName
      group
      taskPayload
      scheduledDate
      executionId
      initiated
      createdAt
      updatedAt
    }
  }
`;
export const listSchedulerTasks = /* GraphQL */ `
  query ListSchedulerTasks(
    $filter: ModelSchedulerTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedulerTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        apiEndpoint
        apiStage
        apiPath
        lambdaName
        group
        taskPayload
        scheduledDate
        executionId
        initiated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const schedulerTasksByGroup = /* GraphQL */ `
  query SchedulerTasksByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchedulerTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulerTasksByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        apiEndpoint
        apiStage
        apiPath
        lambdaName
        group
        taskPayload
        scheduledDate
        executionId
        initiated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const schedulerTasksByType = /* GraphQL */ `
  query SchedulerTasksByType(
    $group: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchedulerTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulerTasksByType(
      group: $group
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        apiEndpoint
        apiStage
        apiPath
        lambdaName
        group
        taskPayload
        scheduledDate
        executionId
        initiated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRosterChecklist = /* GraphQL */ `
  query GetRosterChecklist($id: ID!) {
    getRosterChecklist(id: $id) {
      id
      name
      completionFrequencyId
      assignedToId
      group
      tenantId
      sendTime
      totalSimpleItems
      totalVehicleItems
      status
      createdAt
      updatedAt
      vehicleTypes {
        items {
          id
          vehicleTypeId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedUsers {
        items {
          id
          userId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      checklistTasks {
        items {
          id
          type
          taskId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistItems {
        items {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          requiredPhotoSpecifications
          photoSpecifications
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      completionFrequency {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      assignedTo {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listRosterChecklists = /* GraphQL */ `
  query ListRosterChecklists(
    $filter: ModelRosterChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosterChecklists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistsByGroup = /* GraphQL */ `
  query RosterChecklistsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRosterChecklistVehicleType = /* GraphQL */ `
  query GetRosterChecklistVehicleType($id: ID!) {
    getRosterChecklistVehicleType(id: $id) {
      id
      vehicleTypeId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      VehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listRosterChecklistVehicleTypes = /* GraphQL */ `
  query ListRosterChecklistVehicleTypes(
    $filter: ModelRosterChecklistVehicleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosterChecklistVehicleTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehicleTypeId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        VehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistVehicleTypesByGroup = /* GraphQL */ `
  query RosterChecklistVehicleTypesByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistVehicleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistVehicleTypesByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehicleTypeId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        VehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistVehicleTypesByRosterChecklist = /* GraphQL */ `
  query RosterChecklistVehicleTypesByRosterChecklist(
    $rosterChecklistId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistVehicleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistVehicleTypesByRosterChecklist(
      rosterChecklistId: $rosterChecklistId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehicleTypeId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        VehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistVehicleTypesByVehicleType = /* GraphQL */ `
  query RosterChecklistVehicleTypesByVehicleType(
    $vehicleTypeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistVehicleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistVehicleTypesByVehicleType(
      vehicleTypeId: $vehicleTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vehicleTypeId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        VehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRosterChecklistUser = /* GraphQL */ `
  query GetRosterChecklistUser($id: ID!) {
    getRosterChecklistUser(id: $id) {
      id
      userId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const listRosterChecklistUsers = /* GraphQL */ `
  query ListRosterChecklistUsers(
    $filter: ModelRosterChecklistUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosterChecklistUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistUsersByGroup = /* GraphQL */ `
  query RosterChecklistUsersByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistUsersByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistUsersByRosterChecklist = /* GraphQL */ `
  query RosterChecklistUsersByRosterChecklist(
    $rosterChecklistId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistUsersByRosterChecklist(
      rosterChecklistId: $rosterChecklistId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistUsersByUser = /* GraphQL */ `
  query RosterChecklistUsersByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistUsersByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRosterChecklistTask = /* GraphQL */ `
  query GetRosterChecklistTask($id: ID!) {
    getRosterChecklistTask(id: $id) {
      id
      type
      taskId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      task {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listRosterChecklistTasks = /* GraphQL */ `
  query ListRosterChecklistTasks(
    $filter: ModelRosterChecklistTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosterChecklistTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        taskId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        task {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistTasksByGroup = /* GraphQL */ `
  query RosterChecklistTasksByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistTasksByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        taskId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        task {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistTasksByRosterChecklist = /* GraphQL */ `
  query RosterChecklistTasksByRosterChecklist(
    $rosterChecklistId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistTasksByRosterChecklist(
      rosterChecklistId: $rosterChecklistId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        taskId
        rosterChecklistId
        group
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        task {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRosterChecklistItem = /* GraphQL */ `
  query GetRosterChecklistItem($id: ID!) {
    getRosterChecklistItem(id: $id) {
      id
      typeId
      titleQuestion
      descriptionExplanation
      order
      group
      status
      rosterChecklistId
      requiredPhotoSpecifications
      photoSpecifications
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listRosterChecklistItems = /* GraphQL */ `
  query ListRosterChecklistItems(
    $filter: ModelRosterChecklistItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosterChecklistItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        requiredPhotoSpecifications
        photoSpecifications
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistItemsByGroup = /* GraphQL */ `
  query RosterChecklistItemsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistItemsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        requiredPhotoSpecifications
        photoSpecifications
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistItemsByRosterChecklist = /* GraphQL */ `
  query RosterChecklistItemsByRosterChecklist(
    $rosterChecklistId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistItemsByRosterChecklist(
      rosterChecklistId: $rosterChecklistId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        requiredPhotoSpecifications
        photoSpecifications
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      nextToken
    }
  }
`;
export const devicesByGroup = /* GraphQL */ `
  query DevicesByGroup(
    $group: String
    $deviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByGroup(
      group: $group
      deviceName: $deviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      nextToken
    }
  }
`;
export const devicesByStatus = /* GraphQL */ `
  query DevicesByStatus(
    $status: String
    $deviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByStatus(
      status: $status
      deviceName: $deviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      nextToken
    }
  }
`;
export const getDailyLog = /* GraphQL */ `
  query GetDailyLog($id: ID!) {
    getDailyLog(id: $id) {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listDailyLogs = /* GraphQL */ `
  query ListDailyLogs(
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const dailyLogsByDate = /* GraphQL */ `
  query DailyLogsByDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogsByDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const dailyLogsByVehicle = /* GraphQL */ `
  query DailyLogsByVehicle(
    $group: String
    $vehicleId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogsByVehicle(
      group: $group
      vehicleId: $vehicleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const dailyLogsByVehicleAndDate = /* GraphQL */ `
  query DailyLogsByVehicleAndDate(
    $vehicleId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogsByVehicleAndDate(
      vehicleId: $vehicleId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const dailyLogsByVehicleAndDailyRoster = /* GraphQL */ `
  query DailyLogsByVehicleAndDailyRoster(
    $vehicleId: String
    $dailyLogRosteredDayId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogsByVehicleAndDailyRoster(
      vehicleId: $vehicleId
      dailyLogRosteredDayId: $dailyLogRosteredDayId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getDailyLogHistory = /* GraphQL */ `
  query GetDailyLogHistory($id: ID!) {
    getDailyLogHistory(id: $id) {
      id
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listDailyLogHistorys = /* GraphQL */ `
  query ListDailyLogHistorys(
    $filter: ModelDailyLogHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyLogHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        group
        previousValue
        currentValue
        dailyLogId
        createdAt
        updatedAt
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const dailyLogHistoryByGroup = /* GraphQL */ `
  query DailyLogHistoryByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogHistoryByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        group
        previousValue
        currentValue
        dailyLogId
        createdAt
        updatedAt
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const dailyLogHistoryByDailyLog = /* GraphQL */ `
  query DailyLogHistoryByDailyLog(
    $group: String
    $dailyLogId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogHistoryByDailyLog(
      group: $group
      dailyLogId: $dailyLogId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        group
        previousValue
        currentValue
        dailyLogId
        createdAt
        updatedAt
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      aiSubmissionDateTime
      aiResponseDateTime
      aiResponse
      aiResponseStatus
      aiIssues
      aiResponseAcknowledgedDateTime
      aiResponseFeedback
      aiDeterminedOdometerReadingValue
      aiResponseAcknowledgedBy
      injury {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imagevehicleDamage {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      maintenance {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imageCounseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentRecords {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      infraction {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomLists {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const documentsByGroup = /* GraphQL */ `
  query DocumentsByGroup(
    $group: String
    $uploadDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByGroup(
      group: $group
      uploadDate: $uploadDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const documentsByType = /* GraphQL */ `
  query DocumentsByType(
    $type: String
    $uploadDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByType(
      type: $type
      uploadDate: $uploadDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getDocumentConnections = /* GraphQL */ `
  query GetDocumentConnections($id: ID!) {
    getDocumentConnections(id: $id) {
      id
      documentID
      group
      createdByDocument
      incidentID
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentDocument {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listDocumentConnectionss = /* GraphQL */ `
  query ListDocumentConnectionss(
    $filter: ModelDocumentConnectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentConnectionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentID
        group
        createdByDocument
        incidentID
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        incidentDocument {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const documentConnectionsByGroup = /* GraphQL */ `
  query DocumentConnectionsByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentConnectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentConnectionsByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentID
        group
        createdByDocument
        incidentID
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        incidentDocument {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getInfraction = /* GraphQL */ `
  query GetInfraction($id: ID!) {
    getInfraction(id: $id) {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listInfractions = /* GraphQL */ `
  query ListInfractions(
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInfractions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const infractionsByGroup = /* GraphQL */ `
  query InfractionsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const infractionsByStaff = /* GraphQL */ `
  query InfractionsByStaff(
    $staffId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByStaff(
      staffId: $staffId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const infractionsByGroupAndDate = /* GraphQL */ `
  query InfractionsByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const infractionsByStaffAndCreatedFromDate = /* GraphQL */ `
  query InfractionsByStaffAndCreatedFromDate(
    $staffId: String
    $createdFromDate: ModelInfractionByStaffAndCreatedFromDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByStaffAndCreatedFromDate(
      staffId: $staffId
      createdFromDate: $createdFromDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      createdAt
      group
      month
      year
      invoiceTotal
      variableTotal
      invoiceSubTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      paidByCustomerAmount
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const invoicesByGroup = /* GraphQL */ `
  query InvoicesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const invoicesByGroupAndYearAndMonth = /* GraphQL */ `
  query InvoicesByGroupAndYearAndMonth(
    $group: String
    $yearMonth: ModelInvoiceByGroupYearMonthCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByGroupAndYearAndMonth(
      group: $group
      yearMonth: $yearMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const invoicesByGroupAndStatus = /* GraphQL */ `
  query InvoicesByGroupAndStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByGroupAndStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const invoicesByStatus = /* GraphQL */ `
  query InvoicesByStatus(
    $status: String
    $yearMonth: ModelInvoiceByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByStatus(
      status: $status
      yearMonth: $yearMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getInvoiceLineItem = /* GraphQL */ `
  query GetInvoiceLineItem($id: ID!) {
    getInvoiceLineItem(id: $id) {
      id
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
    }
  }
`;
export const listInvoiceLineItems = /* GraphQL */ `
  query ListInvoiceLineItems(
    $filter: ModelInvoiceLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceLineItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        date
        month
        year
        day
        isTrial
        activeStaff
        standardCost
        standardCostExt
        bundleCost
        bundleCostExt
        performanceCost
        performanceCostExt
        rosteringCost
        rosteringCostExt
        staffCost
        staffCostExt
        vehiclesCost
        vehiclesCostExt
        messagingCost
        messagingCostExt
        updatedAt
        invoice {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const invoiceLineItemsByGroup = /* GraphQL */ `
  query InvoiceLineItemsByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceLineItemsByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        date
        month
        year
        day
        isTrial
        activeStaff
        standardCost
        standardCostExt
        bundleCost
        bundleCostExt
        performanceCost
        performanceCostExt
        rosteringCost
        rosteringCostExt
        staffCost
        staffCostExt
        vehiclesCost
        vehiclesCostExt
        messagingCost
        messagingCostExt
        updatedAt
        invoice {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getKudo = /* GraphQL */ `
  query GetKudo($id: ID!) {
    getKudo(id: $id) {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listKudos = /* GraphQL */ `
  query ListKudos(
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKudos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        kudoType
        notes
        date
        time
        staffId
        createdFrom
        createdAt
        updatedAt
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const kudosByGroup = /* GraphQL */ `
  query KudosByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    kudosByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        kudoType
        notes
        date
        time
        staffId
        createdFrom
        createdAt
        updatedAt
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const kudosByStaff = /* GraphQL */ `
  query KudosByStaff(
    $staffId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    kudosByStaff(
      staffId: $staffId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        kudoType
        notes
        date
        time
        staffId
        createdFrom
        createdAt
        updatedAt
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const kudosByGroupAndDate = /* GraphQL */ `
  query KudosByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    kudosByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        kudoType
        notes
        date
        time
        staffId
        createdFrom
        createdAt
        updatedAt
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const kudosByStaffAndCreatedFromDate = /* GraphQL */ `
  query KudosByStaffAndCreatedFromDate(
    $staffId: String
    $createdFromDate: ModelKudoByStaffAndCreatedFromDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    kudosByStaffAndCreatedFromDate(
      staffId: $staffId
      createdFromDate: $createdFromDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        kudoType
        notes
        date
        time
        staffId
        createdFrom
        createdAt
        updatedAt
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messagesByOwner = /* GraphQL */ `
  query MessagesByOwner(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messagesByGroup = /* GraphQL */ `
  query MessagesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messagesByStaffId = /* GraphQL */ `
  query MessagesByStaffId(
    $staffId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByStaffId(
      staffId: $staffId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messagesByGroupChannelType = /* GraphQL */ `
  query MessagesByGroupChannelType(
    $group: String
    $channelTypeCreatedAt: ModelMessageByGroupChannelTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroupChannelType(
      group: $group
      channelTypeCreatedAt: $channelTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messagesByGroupChannelTypeIsReadS = /* GraphQL */ `
  query MessagesByGroupChannelTypeIsReadS(
    $group: String
    $channelTypeIsReadSCreatedAt: ModelMessageByGroupChannelTypeIsReadSCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroupChannelTypeIsReadS(
      group: $group
      channelTypeIsReadSCreatedAt: $channelTypeIsReadSCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messagesByGroupAndMessageType = /* GraphQL */ `
  query MessagesByGroupAndMessageType(
    $group: String
    $messageTypeCreatedAt: ModelMessageByGroupAndMessageTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroupAndMessageType(
      group: $group
      messageTypeCreatedAt: $messageTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messagesByStaffAndMessageType = /* GraphQL */ `
  query MessagesByStaffAndMessageType(
    $staffId: ID
    $messageTypeCreatedAt: ModelMessageByStaffAndMessageTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByStaffAndMessageType(
      staffId: $staffId
      messageTypeCreatedAt: $messageTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messageByCarrierMessageId = /* GraphQL */ `
  query MessageByCarrierMessageId(
    $carrierMessageId: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByCarrierMessageId(
      carrierMessageId: $carrierMessageId
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messageByDestinationEmail = /* GraphQL */ `
  query MessageByDestinationEmail(
    $destinationEmail: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByDestinationEmail(
      destinationEmail: $destinationEmail
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const messagesByRosterChecklistSubject = /* GraphQL */ `
  query MessagesByRosterChecklistSubject(
    $rosterChecklistSubjectId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByRosterChecklistSubject(
      rosterChecklistSubjectId: $rosterChecklistSubjectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getResourceUsage = /* GraphQL */ `
  query GetResourceUsage($id: ID!) {
    getResourceUsage(id: $id) {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const listResourceUsages = /* GraphQL */ `
  query ListResourceUsages(
    $filter: ModelResourceUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceUsages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        resourceIdentifier
        resourceType
        inUseAsOfDateTime
        userInstanceId
        createdAt
        updatedAt
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const resourceUsageByGroup = /* GraphQL */ `
  query ResourceUsageByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelResourceUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceUsageByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        resourceIdentifier
        resourceType
        inUseAsOfDateTime
        userInstanceId
        createdAt
        updatedAt
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const resourceUsageByGroupAndResourceIdentifier = /* GraphQL */ `
  query ResourceUsageByGroupAndResourceIdentifier(
    $group: String
    $resourceIdentifier: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceUsageByGroupAndResourceIdentifier(
      group: $group
      resourceIdentifier: $resourceIdentifier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        resourceIdentifier
        resourceType
        inUseAsOfDateTime
        userInstanceId
        createdAt
        updatedAt
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const notesByGroup = /* GraphQL */ `
  query NotesByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const notesByGroupAndDate = /* GraphQL */ `
  query NotesByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getVehicleMention = /* GraphQL */ `
  query GetVehicleMention($id: ID!) {
    getVehicleMention(id: $id) {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listVehicleMentions = /* GraphQL */ `
  query ListVehicleMentions(
    $filter: ModelVehicleMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicleMentions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        date
        createdAt
        updatedAt
        note {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleMentionByGroup = /* GraphQL */ `
  query VehicleMentionByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMentionByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        createdAt
        updatedAt
        note {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleMentionsByDate = /* GraphQL */ `
  query VehicleMentionsByDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMentionsByDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        createdAt
        updatedAt
        note {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStaffMention = /* GraphQL */ `
  query GetStaffMention($id: ID!) {
    getStaffMention(id: $id) {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listStaffMentions = /* GraphQL */ `
  query ListStaffMentions(
    $filter: ModelStaffMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffMentions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        date
        createdAt
        updatedAt
        note {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffMentionByGroup = /* GraphQL */ `
  query StaffMentionByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelStaffMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffMentionByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        createdAt
        updatedAt
        note {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffMentionsByDate = /* GraphQL */ `
  query StaffMentionsByDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffMentionsByDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        createdAt
        updatedAt
        note {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        owner
        title
        createdAt
        description
        releaseNotes
        payload
        clickAction
        expirationTTL
        isReadS
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByOwner = /* GraphQL */ `
  query NotificationsByOwner(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        title
        createdAt
        description
        releaseNotes
        payload
        clickAction
        expirationTTL
        isReadS
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByGroup = /* GraphQL */ `
  query NotificationsByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        title
        createdAt
        description
        releaseNotes
        payload
        clickAction
        expirationTTL
        isReadS
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByOwnerAndIsReadS = /* GraphQL */ `
  query NotificationsByOwnerAndIsReadS(
    $owner: String
    $isReadS: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOwnerAndIsReadS(
      owner: $owner
      isReadS: $isReadS
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        title
        createdAt
        description
        releaseNotes
        payload
        clickAction
        expirationTTL
        isReadS
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyNotification = /* GraphQL */ `
  query GetCompanyNotification($id: ID!) {
    getCompanyNotification(id: $id) {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyNotifications = /* GraphQL */ `
  query ListCompanyNotifications(
    $filter: ModelCompanyNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        streamTable
        affectedTable
        owner
        message
        expirationTTL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyNotificationsByGroup = /* GraphQL */ `
  query CompanyNotificationsByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyNotificationsByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        streamTable
        affectedTable
        owner
        message
        expirationTTL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOnBoard = /* GraphQL */ `
  query GetOnBoard($id: ID!) {
    getOnBoard(id: $id) {
      id
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listOnBoards = /* GraphQL */ `
  query ListOnBoards(
    $filter: ModelOnBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        isComplete
        status
        dateComplete
        dateStart
        date3
        date4
        date5
        trainer
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const onBoardsByGroup = /* GraphQL */ `
  query OnBoardsByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOnBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onBoardsByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        isComplete
        status
        dateComplete
        dateStart
        date3
        date4
        date5
        trainer
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPhysical = /* GraphQL */ `
  query GetPhysical($id: ID!) {
    getPhysical(id: $id) {
      id
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listPhysicals = /* GraphQL */ `
  query ListPhysicals(
    $filter: ModelPhysicalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhysicals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        date
        expirationDate
        location
        results
        file
        fileName
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const physicalsByGroup = /* GraphQL */ `
  query PhysicalsByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhysicalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    physicalsByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        expirationDate
        location
        results
        file
        fileName
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPodQuality = /* GraphQL */ `
  query GetPodQuality($id: ID!) {
    getPodQuality(id: $id) {
      id
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listPodQualitys = /* GraphQL */ `
  query ListPodQualitys(
    $filter: ModelPodQualityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodQualitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        matched
        matchedS
        week
        year
        employeeName
        transporterId
        blurry
        explicit
        mailSlot
        noPackage
        other
        opportunities
        success
        bypass
        packageInHand
        notClearlyVisible
        packageTooClose
        personInPhoto
        photoTooDark
        takenFromCar
        grandTotal
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const podQualitysByGroup = /* GraphQL */ `
  query PodQualitysByGroup(
    $group: String
    $yearWeek: ModelPodQualityByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPodQualityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    podQualitysByGroup(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        week
        year
        employeeName
        transporterId
        blurry
        explicit
        mailSlot
        noPackage
        other
        opportunities
        success
        bypass
        packageInHand
        notClearlyVisible
        packageTooClose
        personInPhoto
        photoTooDark
        takenFromCar
        grandTotal
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const podQualityReportsByGroupUnmatched = /* GraphQL */ `
  query PodQualityReportsByGroupUnmatched(
    $group: String
    $matchedSYearWeek: ModelPodQualityByGroupUnmatchedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPodQualityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    podQualityReportsByGroupUnmatched(
      group: $group
      matchedSYearWeek: $matchedSYearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        week
        year
        employeeName
        transporterId
        blurry
        explicit
        mailSlot
        noPackage
        other
        opportunities
        success
        bypass
        packageInHand
        notClearlyVisible
        packageTooClose
        personInPhoto
        photoTooDark
        takenFromCar
        grandTotal
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const podQualityReportsByGroupAndTransporterId = /* GraphQL */ `
  query PodQualityReportsByGroupAndTransporterId(
    $group: String
    $transporterId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPodQualityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    podQualityReportsByGroupAndTransporterId(
      group: $group
      transporterId: $transporterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        week
        year
        employeeName
        transporterId
        blurry
        explicit
        mailSlot
        noPackage
        other
        opportunities
        success
        bypass
        packageInHand
        notClearlyVisible
        packageTooClose
        personInPhoto
        photoTooDark
        takenFromCar
        grandTotal
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReplaceByRoute = /* GraphQL */ `
  query GetReplaceByRoute($id: ID!) {
    getReplaceByRoute(id: $id) {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listReplaceByRoutes = /* GraphQL */ `
  query ListReplaceByRoutes(
    $filter: ModelReplaceByRouteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReplaceByRoutes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const replaceRoutesByGroup = /* GraphQL */ `
  query ReplaceRoutesByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReplaceByRouteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    replaceRoutesByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getRoute = /* GraphQL */ `
  query GetRoute($id: ID!) {
    getRoute(id: $id) {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRoutes = /* GraphQL */ `
  query ListRoutes(
    $filter: ModelRouteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoutes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const routesByGroup = /* GraphQL */ `
  query RoutesByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRouteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    routesByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getLabelType = /* GraphQL */ `
  query GetLabelType($id: ID!) {
    getLabelType(id: $id) {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listLabelTypes = /* GraphQL */ `
  query ListLabelTypes(
    $filter: ModelLabelTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabelTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const labelTypeByNameAndGroup = /* GraphQL */ `
  query LabelTypeByNameAndGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelTypeByNameAndGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const labelTypeByGroup = /* GraphQL */ `
  query LabelTypeByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelLabelTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelTypeByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLabelTypeList = /* GraphQL */ `
  query GetLabelTypeList($id: ID!) {
    getLabelTypeList(id: $id) {
      id
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const listLabelTypeLists = /* GraphQL */ `
  query ListLabelTypeLists(
    $filter: ModelLabelTypeListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabelTypeLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        createdAt
        updatedAt
        type {
          id
          group
          name
          createdAt
          updatedAt
        }
        label {
          id
          group
          name
          status
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getLabel = /* GraphQL */ `
  query GetLabel($id: ID!) {
    getLabel(id: $id) {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listLabels = /* GraphQL */ `
  query ListLabels(
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const labelsByGroupAndName = /* GraphQL */ `
  query LabelsByGroupAndName(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelsByGroupAndName(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const labelsByGroup = /* GraphQL */ `
  query LabelsByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelsByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLabelSubscription = /* GraphQL */ `
  query GetLabelSubscription($id: ID!) {
    getLabelSubscription(id: $id) {
      id
      group
      labelId
      staffId
      vehicleId
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listLabelSubscriptions = /* GraphQL */ `
  query ListLabelSubscriptions(
    $filter: ModelLabelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabelSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        labelId
        staffId
        vehicleId
        createdAt
        updatedAt
        label {
          id
          group
          name
          status
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const labelSubscriptionByLabelId = /* GraphQL */ `
  query LabelSubscriptionByLabelId(
    $labelId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLabelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelSubscriptionByLabelId(
      labelId: $labelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        labelId
        staffId
        vehicleId
        createdAt
        updatedAt
        label {
          id
          group
          name
          status
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const labelSubscriptionByStaffLabel = /* GraphQL */ `
  query LabelSubscriptionByStaffLabel(
    $staffId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLabelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelSubscriptionByStaffLabel(
      staffId: $staffId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        labelId
        staffId
        vehicleId
        createdAt
        updatedAt
        label {
          id
          group
          name
          status
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const labelSubscriptionByVehicleLabel = /* GraphQL */ `
  query LabelSubscriptionByVehicleLabel(
    $vehicleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLabelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelSubscriptionByVehicleLabel(
      vehicleId: $vehicleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        labelId
        staffId
        vehicleId
        createdAt
        updatedAt
        label {
          id
          group
          name
          status
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const labelSubscriptionByGroup = /* GraphQL */ `
  query LabelSubscriptionByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelLabelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelSubscriptionByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        labelId
        staffId
        vehicleId
        createdAt
        updatedAt
        label {
          id
          group
          name
          status
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listStaffs = /* GraphQL */ `
  query ListStaffs(
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const staffsByGroup = /* GraphQL */ `
  query StaffsByGroup(
    $group: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroup(
      group: $group
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const staffsByGroupStatus = /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const staffsByStatus = /* GraphQL */ `
  query StaffsByStatus(
    $status: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByStatus(
      status: $status
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const staffsByEmail = /* GraphQL */ `
  query StaffsByEmail(
    $email: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByEmail(
      email: $email
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const staffsByPhone = /* GraphQL */ `
  query StaffsByPhone(
    $phone: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByPhone(
      phone: $phone
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const staffsByTransporterId = /* GraphQL */ `
  query StaffsByTransporterId(
    $transporterId: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByTransporterId(
      transporterId: $transporterId
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getMessagePreferencesHistory = /* GraphQL */ `
  query GetMessagePreferencesHistory($id: ID!) {
    getMessagePreferencesHistory(id: $id) {
      id
      group
      messagePreferenceType
      description
      datetime
      messagePreferencesHistoryStaffId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listMessagePreferencesHistorys = /* GraphQL */ `
  query ListMessagePreferencesHistorys(
    $filter: ModelMessagePreferencesHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagePreferencesHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        messagePreferenceType
        description
        datetime
        messagePreferencesHistoryStaffId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const messagePreferencesHistoryByStaff = /* GraphQL */ `
  query MessagePreferencesHistoryByStaff(
    $messagePreferencesHistoryStaffId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessagePreferencesHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagePreferencesHistoryByStaff(
      messagePreferencesHistoryStaffId: $messagePreferencesHistoryStaffId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        messagePreferenceType
        description
        datetime
        messagePreferencesHistoryStaffId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStaffCxFeedback = /* GraphQL */ `
  query GetStaffCxFeedback($id: ID!) {
    getStaffCxFeedback(id: $id) {
      id
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listStaffCxFeedbacks = /* GraphQL */ `
  query ListStaffCxFeedbacks(
    $filter: ModelStaffCxFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffCxFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        week
        year
        name
        messageHasBeenSent
        transporterId
        positiveFeedback
        negativeFeedback
        deliveryWasGreat
        deliveryWasntGreat
        totalDeliveries
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        careForOthers
        mishandledPackage
        drivingUnsafely
        driverUnprofessional
        notDeliveredToPreferredLocation
        noFeedback
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        daTier
        cdfScore
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffCxFeedbacksByGroup = /* GraphQL */ `
  query StaffCxFeedbacksByGroup(
    $group: String
    $yearWeek: ModelStaffCxFeedbackByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffCxFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffCxFeedbacksByGroup(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        week
        year
        name
        messageHasBeenSent
        transporterId
        positiveFeedback
        negativeFeedback
        deliveryWasGreat
        deliveryWasntGreat
        totalDeliveries
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        careForOthers
        mishandledPackage
        drivingUnsafely
        driverUnprofessional
        notDeliveredToPreferredLocation
        noFeedback
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        daTier
        cdfScore
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const cxFeebackByGroupUnmatched = /* GraphQL */ `
  query CxFeebackByGroupUnmatched(
    $group: String
    $matchedSYearWeek: ModelStaffCxFeedbackByGroupUnmatchedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffCxFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cxFeebackByGroupUnmatched(
      group: $group
      matchedSYearWeek: $matchedSYearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        week
        year
        name
        messageHasBeenSent
        transporterId
        positiveFeedback
        negativeFeedback
        deliveryWasGreat
        deliveryWasntGreat
        totalDeliveries
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        careForOthers
        mishandledPackage
        drivingUnsafely
        driverUnprofessional
        notDeliveredToPreferredLocation
        noFeedback
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        daTier
        cdfScore
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const cxFeebackByGroupAndTransporterId = /* GraphQL */ `
  query CxFeebackByGroupAndTransporterId(
    $group: String
    $transporterId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffCxFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cxFeebackByGroupAndTransporterId(
      group: $group
      transporterId: $transporterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        week
        year
        name
        messageHasBeenSent
        transporterId
        positiveFeedback
        negativeFeedback
        deliveryWasGreat
        deliveryWasntGreat
        totalDeliveries
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        careForOthers
        mishandledPackage
        drivingUnsafely
        driverUnprofessional
        notDeliveredToPreferredLocation
        noFeedback
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        daTier
        cdfScore
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStaffMentor = /* GraphQL */ `
  query GetStaffMentor($id: ID!) {
    getStaffMentor(id: $id) {
      id
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listStaffMentors = /* GraphQL */ `
  query ListStaffMentors(
    $filter: ModelStaffMentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffMentors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        matched
        messageHasBeenSent
        date
        station
        trips
        geotabTrips
        miles
        time
        fico
        accel
        braking
        cornering
        speeding
        distraction
        seatbelt
        backUp
        sse
        mpg
        idling
        engineOff
        preDvcr
        postDvcr
        trainingAssigned
        trainingCompleted
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffMentorsByGroup = /* GraphQL */ `
  query StaffMentorsByGroup(
    $group: String
    $dateName: ModelStaffMentorByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffMentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffMentorsByGroup(
      group: $group
      dateName: $dateName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        matched
        messageHasBeenSent
        date
        station
        trips
        geotabTrips
        miles
        time
        fico
        accel
        braking
        cornering
        speeding
        distraction
        seatbelt
        backUp
        sse
        mpg
        idling
        engineOff
        preDvcr
        postDvcr
        trainingAssigned
        trainingCompleted
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStaffNetradyneAlert = /* GraphQL */ `
  query GetStaffNetradyneAlert($id: ID!) {
    getStaffNetradyneAlert(id: $id) {
      id
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listStaffNetradyneAlerts = /* GraphQL */ `
  query ListStaffNetradyneAlerts(
    $filter: ModelStaffNetradyneAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffNetradyneAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        staffId
        matched
        matchedS
        driverName
        driverId
        groupName
        vehicleNumber
        alertId
        timestamp
        alertType
        alertSeverity
        description
        alertVideoStatus
        duration
        startLatLong
        endLatLong
        sortKey
        date
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffNetradyneAlertsByGroup = /* GraphQL */ `
  query StaffNetradyneAlertsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffNetradyneAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffNetradyneAlertsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        staffId
        matched
        matchedS
        driverName
        driverId
        groupName
        vehicleNumber
        alertId
        timestamp
        alertType
        alertSeverity
        description
        alertVideoStatus
        duration
        startLatLong
        endLatLong
        sortKey
        date
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffNetradyneAlertsByStaff = /* GraphQL */ `
  query StaffNetradyneAlertsByStaff(
    $staffId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffNetradyneAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffNetradyneAlertsByStaff(
      staffId: $staffId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        staffId
        matched
        matchedS
        driverName
        driverId
        groupName
        vehicleNumber
        alertId
        timestamp
        alertType
        alertSeverity
        description
        alertVideoStatus
        duration
        startLatLong
        endLatLong
        sortKey
        date
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffNetradyneAlertsByAlertsByGroupInDateRange = /* GraphQL */ `
  query StaffNetradyneAlertsByAlertsByGroupInDateRange(
    $group: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffNetradyneAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffNetradyneAlertsByAlertsByGroupInDateRange(
      group: $group
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        staffId
        matched
        matchedS
        driverName
        driverId
        groupName
        vehicleNumber
        alertId
        timestamp
        alertType
        alertSeverity
        description
        alertVideoStatus
        duration
        startLatLong
        endLatLong
        sortKey
        date
        textractJobId
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStaffScoreCard = /* GraphQL */ `
  query GetStaffScoreCard($id: ID!) {
    getStaffScoreCard(id: $id) {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      psb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listStaffScoreCards = /* GraphQL */ `
  query ListStaffScoreCards(
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffScoreCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        name
        transporterId
        overallTier
        delivered
        keyFocusArea
        ficoScore
        seatbeltOffRate
        cdf
        cdfDpmo
        ced
        dcr
        dar
        dsb
        psb
        swcPod
        swcCc
        swcSc
        swcAd
        dnrs
        podOpps
        ccOpps
        speedingEventRate
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        harshBrakingRate
        harshCorneringRate
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffScoreCardsByGroup = /* GraphQL */ `
  query StaffScoreCardsByGroup(
    $group: String
    $yearWeek: ModelStaffScoreCardByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffScoreCardsByGroup(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        name
        transporterId
        overallTier
        delivered
        keyFocusArea
        ficoScore
        seatbeltOffRate
        cdf
        cdfDpmo
        ced
        dcr
        dar
        dsb
        psb
        swcPod
        swcCc
        swcSc
        swcAd
        dnrs
        podOpps
        ccOpps
        speedingEventRate
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        harshBrakingRate
        harshCorneringRate
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffScorecardsGroupUnmatched = /* GraphQL */ `
  query StaffScorecardsGroupUnmatched(
    $group: String
    $matchedSYearWeek: ModelStaffScoreCardByGroupUnmatchedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffScorecardsGroupUnmatched(
      group: $group
      matchedSYearWeek: $matchedSYearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        name
        transporterId
        overallTier
        delivered
        keyFocusArea
        ficoScore
        seatbeltOffRate
        cdf
        cdfDpmo
        ced
        dcr
        dar
        dsb
        psb
        swcPod
        swcCc
        swcSc
        swcAd
        dnrs
        podOpps
        ccOpps
        speedingEventRate
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        harshBrakingRate
        harshCorneringRate
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffScorecardsByGroupAndTransporterId = /* GraphQL */ `
  query StaffScorecardsByGroupAndTransporterId(
    $group: String
    $transporterId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffScorecardsByGroupAndTransporterId(
      group: $group
      transporterId: $transporterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        name
        transporterId
        overallTier
        delivered
        keyFocusArea
        ficoScore
        seatbeltOffRate
        cdf
        cdfDpmo
        ced
        dcr
        dar
        dsb
        psb
        swcPod
        swcCc
        swcSc
        swcAd
        dnrs
        podOpps
        ccOpps
        speedingEventRate
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        harshBrakingRate
        harshCorneringRate
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffScorecardsByGroupAndName = /* GraphQL */ `
  query StaffScorecardsByGroupAndName(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffScorecardsByGroupAndName(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        name
        transporterId
        overallTier
        delivered
        keyFocusArea
        ficoScore
        seatbeltOffRate
        cdf
        cdfDpmo
        ced
        dcr
        dar
        dsb
        psb
        swcPod
        swcCc
        swcSc
        swcAd
        dnrs
        podOpps
        ccOpps
        speedingEventRate
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        harshBrakingRate
        harshCorneringRate
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffScorecardsByGroupMatchedAndName = /* GraphQL */ `
  query StaffScorecardsByGroupMatchedAndName(
    $group: String
    $matchedSYearWeekName: ModelStaffScoreCardByGroupMatchedNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffScorecardsByGroupMatchedAndName(
      group: $group
      matchedSYearWeekName: $matchedSYearWeekName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        name
        transporterId
        overallTier
        delivered
        keyFocusArea
        ficoScore
        seatbeltOffRate
        cdf
        cdfDpmo
        ced
        dcr
        dar
        dsb
        psb
        swcPod
        swcCc
        swcSc
        swcAd
        dnrs
        podOpps
        ccOpps
        speedingEventRate
        distractionsRate
        followingDistanceRate
        signSignalViolationsRate
        harshBrakingRate
        harshCorneringRate
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStaffStatus = /* GraphQL */ `
  query GetStaffStatus($id: ID!) {
    getStaffStatus(id: $id) {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listStaffStatuss = /* GraphQL */ `
  query ListStaffStatuss(
    $filter: ModelStaffStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        reason
        date
        previousStatus
        currentStatus
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffStatussByGroup = /* GraphQL */ `
  query StaffStatussByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffStatussByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        reason
        date
        previousStatus
        currentStatus
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRouteStatus = /* GraphQL */ `
  query GetRouteStatus($id: ID!) {
    getRouteStatus(id: $id) {
      id
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      associate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      previousStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      currentStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listRouteStatuss = /* GraphQL */ `
  query ListRouteStatuss(
    $filter: ModelRouteStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRouteStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        associateType
        reason
        date
        createdAt
        updatedAt
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        associate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        previousStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        currentStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const routeStatussByGroup = /* GraphQL */ `
  query RouteStatussByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRouteStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    routeStatussByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        associateType
        reason
        date
        createdAt
        updatedAt
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        associate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        previousStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        currentStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRosterChecklistSubject = /* GraphQL */ `
  query GetRosterChecklistSubject($id: ID!) {
    getRosterChecklistSubject(id: $id) {
      id
      notesDate
      group
      inactive
      staffId
      userId
      rosterChecklistId
      routeId
      replaceByRouteId
      dailyRosterId
      simpleItemsCompleted
      vehicleItemsCompleted
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      sentMessages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubjectItems {
        items {
          id
          group
          rosterChecklistSubjectId
          rosterChecklistItemId
          dailyLogId
          odometerReadingId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const listRosterChecklistSubjects = /* GraphQL */ `
  query ListRosterChecklistSubjects(
    $filter: ModelRosterChecklistSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosterChecklistSubjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistSubjectByGroup = /* GraphQL */ `
  query RosterChecklistSubjectByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistSubjectByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistSubjectByNotesDate = /* GraphQL */ `
  query RosterChecklistSubjectByNotesDate(
    $notesDate: String
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistSubjectByNotesDate(
      notesDate: $notesDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistSubjectByRoute = /* GraphQL */ `
  query RosterChecklistSubjectByRoute(
    $routeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistSubjectByRoute(
      routeId: $routeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistSubjectByReplaceByRoute = /* GraphQL */ `
  query RosterChecklistSubjectByReplaceByRoute(
    $replaceByRouteId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistSubjectByReplaceByRoute(
      replaceByRouteId: $replaceByRouteId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistSubjectByDailyRoster = /* GraphQL */ `
  query RosterChecklistSubjectByDailyRoster(
    $dailyRosterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistSubjectByDailyRoster(
      dailyRosterId: $dailyRosterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRosterChecklistSubjectItem = /* GraphQL */ `
  query GetRosterChecklistSubjectItem($id: ID!) {
    getRosterChecklistSubjectItem(id: $id) {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        requiredPhotoSpecifications
        photoSpecifications
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listRosterChecklistSubjectItems = /* GraphQL */ `
  query ListRosterChecklistSubjectItems(
    $filter: ModelRosterChecklistSubjectItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRosterChecklistSubjectItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        rosterChecklistSubjectId
        rosterChecklistItemId
        dailyLogId
        odometerReadingId
        value
        createdAt
        updatedAt
        odometerReading {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        rosterChecklistItem {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          requiredPhotoSpecifications
          photoSpecifications
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistSubjectItemByGroup = /* GraphQL */ `
  query RosterChecklistSubjectItemByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistSubjectItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RosterChecklistSubjectItemByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        rosterChecklistSubjectId
        rosterChecklistItemId
        dailyLogId
        odometerReadingId
        value
        createdAt
        updatedAt
        odometerReading {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        rosterChecklistItem {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          requiredPhotoSpecifications
          photoSpecifications
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const rosterChecklistSubjectItemByRosterChecklistSubject = /* GraphQL */ `
  query RosterChecklistSubjectItemByRosterChecklistSubject(
    $rosterChecklistSubjectId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistSubjectItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RosterChecklistSubjectItemByRosterChecklistSubject(
      rosterChecklistSubjectId: $rosterChecklistSubjectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        rosterChecklistSubjectId
        rosterChecklistItemId
        dailyLogId
        odometerReadingId
        value
        createdAt
        updatedAt
        odometerReading {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        rosterChecklistItem {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          requiredPhotoSpecifications
          photoSpecifications
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigneeAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      assigner {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      assignee {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        owner
        creator
        group
        executionARN
        description
        date
        status
        taskName
        isDateReminder
        isNotifyOnCreation
        reminderDate
        updatedAt
        assigneeAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        assigner {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        assignee {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tasksByOwner = /* GraphQL */ `
  query TasksByOwner(
    $owner: String
    $statusDate: ModelTaskByOwnerCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByOwner(
      owner: $owner
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        owner
        creator
        group
        executionARN
        description
        date
        status
        taskName
        isDateReminder
        isNotifyOnCreation
        reminderDate
        updatedAt
        assigneeAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        assigner {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        assignee {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tasksByCreator = /* GraphQL */ `
  query TasksByCreator(
    $creator: String
    $statusDate: ModelTaskByCreatorCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByCreator(
      creator: $creator
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        owner
        creator
        group
        executionARN
        description
        date
        status
        taskName
        isDateReminder
        isNotifyOnCreation
        reminderDate
        updatedAt
        assigneeAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        assigner {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        assignee {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tasksByGroup = /* GraphQL */ `
  query TasksByGroup(
    $group: String
    $statusDate: ModelTaskByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByGroup(
      group: $group
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        owner
        creator
        group
        executionARN
        description
        date
        status
        taskName
        isDateReminder
        isNotifyOnCreation
        reminderDate
        updatedAt
        assigneeAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        assigner {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        assignee {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tasksByGroupAndStatus = /* GraphQL */ `
  query TasksByGroupAndStatus(
    $group: String
    $statusId: ModelTaskByGroupStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByGroupAndStatus(
      group: $group
      statusId: $statusId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        owner
        creator
        group
        executionARN
        description
        date
        status
        taskName
        isDateReminder
        isNotifyOnCreation
        reminderDate
        updatedAt
        assigneeAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        assigner {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        assignee {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      cards {
        items {
          id
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
        }
        nextToken
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          pickupSuccessBehaviors
          pickupSuccessBehaviorsText
          createdAt
          updatedAt
        }
        nextToken
      }
      textractjobs {
        items {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      waves {
        items {
          id
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
        }
        nextToken
      }
      recurringMessage {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      parentAccount {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      valueLists {
        items {
          id
          group
          key
          createdAt
          updatedAt
        }
        nextToken
      }
      premiumStatusHistory {
        items {
          id
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      messageServiceProviderHistory {
        items {
          id
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tenantByOriginationNumber = /* GraphQL */ `
  query TenantByOriginationNumber(
    $originationNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantByOriginationNumber(
      originationNumber: $originationNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tenantsByAccountType = /* GraphQL */ `
  query TenantsByAccountType(
    $accountType: TenantAccountType
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByAccountType(
      accountType: $accountType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tenantsByTrialExpDate = /* GraphQL */ `
  query TenantsByTrialExpDate(
    $trialExpDate: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByTrialExpDate(
      trialExpDate: $trialExpDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCoachingHistory = /* GraphQL */ `
  query GetCoachingHistory($id: ID!) {
    getCoachingHistory(id: $id) {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
    }
  }
`;
export const listCoachingHistorys = /* GraphQL */ `
  query ListCoachingHistorys(
    $filter: ModelCoachingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachingHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        importId
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coachingHistoryByGroup = /* GraphQL */ `
  query CoachingHistoryByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoachingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coachingHistoryByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        importId
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coachingHistoryByimportId = /* GraphQL */ `
  query CoachingHistoryByimportId(
    $group: String
    $importId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoachingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coachingHistoryByimportId(
      group: $group
      importId: $importId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        importId
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoachingRecords = /* GraphQL */ `
  query GetCoachingRecords($id: ID!) {
    getCoachingRecords(id: $id) {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
    }
  }
`;
export const listCoachingRecordss = /* GraphQL */ `
  query ListCoachingRecordss(
    $filter: ModelCoachingRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachingRecordss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        importId
        type
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coachingRecordsByGroup = /* GraphQL */ `
  query CoachingRecordsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoachingRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coachingRecordsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        importId
        type
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coachingRecordsByimportId = /* GraphQL */ `
  query CoachingRecordsByimportId(
    $group: String
    $importId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoachingRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coachingRecordsByimportId(
      group: $group
      importId: $importId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        importId
        type
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomLists = /* GraphQL */ `
  query GetCustomLists($id: ID!) {
    getCustomLists(id: $id) {
      id
      group
      type
      enabledRecordTemplates
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCustomListss = /* GraphQL */ `
  query ListCustomListss(
    $filter: ModelCustomListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomListss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const customListsByGroup = /* GraphQL */ `
  query CustomListsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customListsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const customListsByType = /* GraphQL */ `
  query CustomListsByType(
    $group: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customListsByType(
      group: $group
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOptionsCustomLists = /* GraphQL */ `
  query GetOptionsCustomLists($id: ID!) {
    getOptionsCustomLists(id: $id) {
      id
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      recordTemplateJson
      statusType
      driverReportSetting
      key
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      issues {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRouteParkingSpace {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeParkingSpace {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routes {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelperStatus {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      previousRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      currentRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      customLists {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      associates {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicles {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      parkingSpace {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      companies {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const listOptionsCustomListss = /* GraphQL */ `
  query ListOptionsCustomListss(
    $filter: ModelOptionsCustomListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptionsCustomListss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      nextToken
    }
  }
`;
export const optionsCustomListsByGroup = /* GraphQL */ `
  query OptionsCustomListsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptionsCustomListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    optionsCustomListsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      nextToken
    }
  }
`;
export const getOptionsCustomListsStaff = /* GraphQL */ `
  query GetOptionsCustomListsStaff($id: ID!) {
    getOptionsCustomListsStaff(id: $id) {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const listOptionsCustomListsStaffs = /* GraphQL */ `
  query ListOptionsCustomListsStaffs(
    $filter: ModelOptionsCustomListsStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptionsCustomListsStaffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const optionsCustomListsStaffByGroup = /* GraphQL */ `
  query OptionsCustomListsStaffByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptionsCustomListsStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OptionsCustomListsStaffByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getUniform = /* GraphQL */ `
  query GetUniform($id: ID!) {
    getUniform(id: $id) {
      id
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      uniformType {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      size {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listUniforms = /* GraphQL */ `
  query ListUniforms(
    $filter: ModelUniformFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUniforms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        qty
        issueDate
        returnedDate
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        uniformType {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        size {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const uniformsByGroup = /* GraphQL */ `
  query UniformsByGroup(
    $group: String
    $issueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUniformFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uniformsByGroup(
      group: $group
      issueDate: $issueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        qty
        issueDate
        returnedDate
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        uniformType {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        size {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByPhone = /* GraphQL */ `
  query UsersByPhone(
    $phone: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByTenant = /* GraphQL */ `
  query UsersByTenant(
    $userTenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTenant(
      userTenantId: $userTenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserNotification = /* GraphQL */ `
  query GetUserNotification($id: ID!) {
    getUserNotification(id: $id) {
      id
      group
      title
      description
      expirationTTL
      notificationType
      isRead
      payload
      userId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        title
        description
        expirationTTL
        notificationType
        isRead
        payload
        userId
        createdAt
        updatedAt
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userNotificationsByUserIdAndIsRead = /* GraphQL */ `
  query UserNotificationsByUserIdAndIsRead(
    $userId: ID
    $isRead: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotificationsByUserIdAndIsRead(
      userId: $userId
      isRead: $isRead
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        title
        description
        expirationTTL
        notificationType
        isRead
        payload
        userId
        createdAt
        updatedAt
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSubscribedDevices = /* GraphQL */ `
  query GetSubscribedDevices($id: ID!) {
    getSubscribedDevices(id: $id) {
      id
      group
      subscriptionData
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listSubscribedDevicess = /* GraphQL */ `
  query ListSubscribedDevicess(
    $filter: ModelSubscribedDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribedDevicess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        subscriptionData
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subscribedDevicesByUserId = /* GraphQL */ `
  query SubscribedDevicesByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubscribedDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscribedDevicesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        subscriptionData
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getValueList = /* GraphQL */ `
  query GetValueList($id: ID!) {
    getValueList(id: $id) {
      id
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      items {
        items {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listValueLists = /* GraphQL */ `
  query ListValueLists(
    $filter: ModelValueListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValueLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        items {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const valueListsByGroup = /* GraphQL */ `
  query ValueListsByGroup(
    $group: String
    $key: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelValueListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    valueListsByGroup(
      group: $group
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        items {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const valueListsByKey = /* GraphQL */ `
  query ValueListsByKey(
    $key: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelValueListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    valueListsByKey(
      key: $key
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        items {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getValueListItem = /* GraphQL */ `
  query GetValueListItem($id: ID!) {
    getValueListItem(id: $id) {
      id
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      uniformSizes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      valueList {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const listValueListItems = /* GraphQL */ `
  query ListValueListItems(
    $filter: ModelValueListItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValueListItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const valueListItemsByGroup = /* GraphQL */ `
  query ValueListItemsByGroup(
    $group: String
    $value: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelValueListItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    valueListItemsByGroup(
      group: $group
      value: $value
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const vehiclesByGroup = /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const vehiclesByStatus = /* GraphQL */ `
  query VehiclesByStatus(
    $status: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByStatus(
      status: $status
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const vehiclesByGroupAndLicensePlateExp = /* GraphQL */ `
  query VehiclesByGroupAndLicensePlateExp(
    $group: String
    $licensePlateExp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroupAndLicensePlateExp(
      group: $group
      licensePlateExp: $licensePlateExp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const vehiclesByGroupAndLastOdometerReadingDate = /* GraphQL */ `
  query VehiclesByGroupAndLastOdometerReadingDate(
    $group: String
    $lastOdometerReadingDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroupAndLastOdometerReadingDate(
      group: $group
      lastOdometerReadingDate: $lastOdometerReadingDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const vehiclesByVin = /* GraphQL */ `
  query VehiclesByVin(
    $vin: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByVin(
      vin: $vin
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      nextToken
      scannedCount
    }
  }
`;
export const getVehicleHistory = /* GraphQL */ `
  query GetVehicleHistory($id: ID!) {
    getVehicleHistory(id: $id) {
      id
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      vehicleId
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listVehicleHistorys = /* GraphQL */ `
  query ListVehicleHistorys(
    $filter: ModelVehicleHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicleHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        changeType
        date
        changes
        statusChangeReason
        tenantId
        userId
        userFirstName
        userLastName
        userEmail
        vehicleId
        createdAt
        updatedAt
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleHistoryByGroup = /* GraphQL */ `
  query VehicleHistoryByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleHistoryByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        changeType
        date
        changes
        statusChangeReason
        tenantId
        userId
        userFirstName
        userLastName
        userEmail
        vehicleId
        createdAt
        updatedAt
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVehicleMaintenance = /* GraphQL */ `
  query GetVehicleMaintenance($id: ID!) {
    getVehicleMaintenance(id: $id) {
      id
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listVehicleMaintenances = /* GraphQL */ `
  query ListVehicleMaintenances(
    $filter: ModelVehicleMaintenanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicleMaintenances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dateScheduled
        dateComleted
        description
        receipt
        odometer
        createdAt
        updatedAt
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVehicleMaintenanceReminder = /* GraphQL */ `
  query GetVehicleMaintenanceReminder($id: ID!) {
    getVehicleMaintenanceReminder(id: $id) {
      id
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listVehicleMaintenanceReminders = /* GraphQL */ `
  query ListVehicleMaintenanceReminders(
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicleMaintenanceReminders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        services
        status
        vehicleId
        dueBySort
        dateCompleted
        userId
        notes
        createdAt
        updatedAt
        completedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleMaintenanceRemindersByGroup = /* GraphQL */ `
  query VehicleMaintenanceRemindersByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMaintenanceRemindersByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        services
        status
        vehicleId
        dueBySort
        dateCompleted
        userId
        notes
        createdAt
        updatedAt
        completedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleMaintenanceRemindersByVehicle = /* GraphQL */ `
  query VehicleMaintenanceRemindersByVehicle(
    $vehicleId: String
    $dueBySort: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMaintenanceRemindersByVehicle(
      vehicleId: $vehicleId
      dueBySort: $dueBySort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        services
        status
        vehicleId
        dueBySort
        dateCompleted
        userId
        notes
        createdAt
        updatedAt
        completedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleMaintanceReminderByVehicleAndStatus = /* GraphQL */ `
  query VehicleMaintanceReminderByVehicleAndStatus(
    $vehicleId: String
    $statusDueBySort: ModelVehicleMaintenanceReminderByVehicleByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMaintanceReminderByVehicleAndStatus(
      vehicleId: $vehicleId
      statusDueBySort: $statusDueBySort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        services
        status
        vehicleId
        dueBySort
        dateCompleted
        userId
        notes
        createdAt
        updatedAt
        completedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleMaintanceReminderByGroupAndStatus = /* GraphQL */ `
  query VehicleMaintanceReminderByGroupAndStatus(
    $group: String
    $statusDueBySort: ModelVehicleMaintenanceReminderByGroupByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMaintanceReminderByGroupAndStatus(
      group: $group
      statusDueBySort: $statusDueBySort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        services
        status
        vehicleId
        dueBySort
        dateCompleted
        userId
        notes
        createdAt
        updatedAt
        completedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVehicleStatus = /* GraphQL */ `
  query GetVehicleStatus($id: ID!) {
    getVehicleStatus(id: $id) {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const listVehicleStatuss = /* GraphQL */ `
  query ListVehicleStatuss(
    $filter: ModelVehicleStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicleStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        reason
        date
        previousStatus
        currentStatus
        createdAt
        updatedAt
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleStatusByGroup = /* GraphQL */ `
  query VehicleStatusByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleStatusByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        reason
        date
        previousStatus
        currentStatus
        createdAt
        updatedAt
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMessageReadStatus = /* GraphQL */ `
  query GetMessageReadStatus($id: ID!) {
    getMessageReadStatus(id: $id) {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listMessageReadStatuss = /* GraphQL */ `
  query ListMessageReadStatuss(
    $filter: ModelMessageReadStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageReadStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        userID
        staffID
        readUpToDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageReadStatusByGroup = /* GraphQL */ `
  query MessageReadStatusByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessageReadStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageReadStatusByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        userID
        staffID
        readUpToDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageReadStatusByUserAndStaff = /* GraphQL */ `
  query MessageReadStatusByUserAndStaff(
    $group: String
    $staffIDUserID: ModelMessageReadStatusByUserAndStaffCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageReadStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageReadStatusByUserAndStaff(
      group: $group
      staffIDUserID: $staffIDUserID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        userID
        staffID
        readUpToDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageReadStatusbyGroupAndUser = /* GraphQL */ `
  query MessageReadStatusbyGroupAndUser(
    $group: String
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageReadStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageReadStatusbyGroupAndUser(
      group: $group
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        userID
        staffID
        readUpToDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPremiumStatusHistory = /* GraphQL */ `
  query GetPremiumStatusHistory($id: ID!) {
    getPremiumStatusHistory(id: $id) {
      id
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const listPremiumStatusHistorys = /* GraphQL */ `
  query ListPremiumStatusHistorys(
    $filter: ModelPremiumStatusHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPremiumStatusHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        accountPremiumStatus
        changeNotes
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const premiumStatusHistoryByGroup = /* GraphQL */ `
  query PremiumStatusHistoryByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPremiumStatusHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    premiumStatusHistoryByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        accountPremiumStatus
        changeNotes
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMessageServiceProvider = /* GraphQL */ `
  query GetMessageServiceProvider($id: ID!) {
    getMessageServiceProvider(id: $id) {
      id
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const listMessageServiceProviders = /* GraphQL */ `
  query ListMessageServiceProviders(
    $filter: ModelMessageServiceProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageServiceProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        previousMessageServicerProvider
        currentMessageServicerProvider
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const messageServiceProvidersByGroup = /* GraphQL */ `
  query MessageServiceProvidersByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageServiceProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageServiceProvidersByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        previousMessageServicerProvider
        currentMessageServicerProvider
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTelnyx = /* GraphQL */ `
  query GetTelnyx($id: ID!) {
    getTelnyx(id: $id) {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
    }
  }
`;
export const listTelnyxs = /* GraphQL */ `
  query ListTelnyxs(
    $filter: ModelTelnyxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTelnyxs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        phoneNumber
        telnyxId
        status
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const telnyxsByGroup = /* GraphQL */ `
  query TelnyxsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTelnyxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    telnyxsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        phoneNumber
        telnyxId
        status
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const telnyxsByPhoneNumber = /* GraphQL */ `
  query TelnyxsByPhoneNumber(
    $phoneNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelTelnyxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    telnyxsByPhoneNumber(
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        phoneNumber
        telnyxId
        status
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuditLog = /* GraphQL */ `
  query GetAuditLog($id: ID!) {
    getAuditLog(id: $id) {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
    }
  }
`;
export const listAuditLogs = /* GraphQL */ `
  query ListAuditLogs(
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        mutatedDataDiff
        createdAt
        ipAddress
        ttl
        pageUrl
        logRocketSessionUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const logsByGroupUserAndCreatedAt = /* GraphQL */ `
  query LogsByGroupUserAndCreatedAt(
    $group: String
    $emailCreatedAt: ModelAuditLogByGroupUserAndCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByGroupUserAndCreatedAt(
      group: $group
      emailCreatedAt: $emailCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        mutatedDataDiff
        createdAt
        ipAddress
        ttl
        pageUrl
        logRocketSessionUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const logsByTenantIDAndMutationName = /* GraphQL */ `
  query LogsByTenantIDAndMutationName(
    $tenantID: String
    $mutationName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByTenantIDAndMutationName(
      tenantID: $tenantID
      mutationName: $mutationName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        mutatedDataDiff
        createdAt
        ipAddress
        ttl
        pageUrl
        logRocketSessionUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const logsByGroupUserMutationAndCreatedAt = /* GraphQL */ `
  query LogsByGroupUserMutationAndCreatedAt(
    $group: String
    $emailMutationNameCreatedAt: ModelAuditLogByGroupUserMutationAndCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByGroupUserMutationAndCreatedAt(
      group: $group
      emailMutationNameCreatedAt: $emailMutationNameCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        mutatedDataDiff
        createdAt
        ipAddress
        ttl
        pageUrl
        logRocketSessionUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const logsByMutatedRecordIdAndCreatedAt = /* GraphQL */ `
  query LogsByMutatedRecordIdAndCreatedAt(
    $mutatedRecordId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByMutatedRecordIdAndCreatedAt(
      mutatedRecordId: $mutatedRecordId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        group
        email
        userID
        cognitoSub
        firstName
        lastName
        tenantName
        mutationName
        mutationNameText
        mutatedRecordId
        mutatedData
        mutatedDataDiff
        createdAt
        ipAddress
        ttl
        pageUrl
        logRocketSessionUrl
        userType
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProperParkingSequenceSummary = /* GraphQL */ `
  query GetProperParkingSequenceSummary($id: ID!) {
    getProperParkingSequenceSummary(id: $id) {
      id
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProperParkingSequenceSummarys = /* GraphQL */ `
  query ListProperParkingSequenceSummarys(
    $filter: ModelProperParkingSequenceSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProperParkingSequenceSummarys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ppsSummaryByGroupAndWeek = /* GraphQL */ `
  query PpsSummaryByGroupAndWeek(
    $group: String
    $week: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProperParkingSequenceSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ppsSummaryByGroupAndWeek(
      group: $group
      week: $week
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ppsSummaryByGroupAndYearWeek = /* GraphQL */ `
  query PpsSummaryByGroupAndYearWeek(
    $group: String
    $yearWeek: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProperParkingSequenceSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ppsSummaryByGroupAndYearWeek(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProperParkingSequence = /* GraphQL */ `
  query GetProperParkingSequence($id: ID!) {
    getProperParkingSequence(id: $id) {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const listProperParkingSequences = /* GraphQL */ `
  query ListProperParkingSequences(
    $filter: ModelProperParkingSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProperParkingSequences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        date
        employeeName
        transporterId
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffPpsByGroup = /* GraphQL */ `
  query StaffPpsByGroup(
    $group: String
    $yearWeek: ModelProperParkingSequenceByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProperParkingSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffPpsByGroup(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        date
        employeeName
        transporterId
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffPpsGroupUnmatched = /* GraphQL */ `
  query StaffPpsGroupUnmatched(
    $group: String
    $matchedSYearWeek: ModelProperParkingSequenceByGroupUnmatchedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProperParkingSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffPpsGroupUnmatched(
      group: $group
      matchedSYearWeek: $matchedSYearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        date
        employeeName
        transporterId
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const staffPpsByGroupAndTransporterId = /* GraphQL */ `
  query StaffPpsByGroupAndTransporterId(
    $group: String
    $transporterId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProperParkingSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffPpsByGroupAndTransporterId(
      group: $group
      transporterId: $transporterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        matched
        matchedS
        messageHasBeenSent
        week
        year
        date
        employeeName
        transporterId
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
