/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://hajf8t2dmg.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "ForceLogoutAndRefresh",
            "endpoint": "https://qcuh0ssal9.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "ManageNotificationPreferences",
            "endpoint": "https://uf3ba09305.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "ProcessPerformanceData",
            "endpoint": "https://2bvocgnb4c.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "ScheduleNotifications",
            "endpoint": "https://apjghfx974.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "SendInternalMessage",
            "endpoint": "https://29lyezwloi.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "Signup",
            "endpoint": "https://1s4k6niqn5.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "bandwidth",
            "endpoint": "https://zewqb9mv58.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "csvDataExport",
            "endpoint": "https://c3j1a0wok7.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "documensoApiPoc",
            "endpoint": "https://tbygxia4cl.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "downloadFiles",
            "endpoint": "https://0z518lypqf.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "generateCounselingFilesApi",
            "endpoint": "https://s9oqlfuk92.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "handleTelnyxResponse",
            "endpoint": "https://m9twz7mio3.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "heraPublicApi",
            "endpoint": "https://4q1khop5e5.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "intercomUpdateCompanies",
            "endpoint": "https://pt2clxoep9.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "invoice",
            "endpoint": "https://mre5wvquck.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "rds",
            "endpoint": "https://bj6yzvll0h.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "s3downloadlink",
            "endpoint": "https://qho4gfrnv6.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "sendMessage",
            "endpoint": "https://ew6rncpj64.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "sendMessages",
            "endpoint": "https://ubrpqv8lke.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "sendPendingCoachingMessage",
            "endpoint": "https://exg9o4rkb8.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "stripeCharge",
            "endpoint": "https://9a1afa5jv3.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "stripeSetup",
            "endpoint": "https://two5jdpotl.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "tenant",
            "endpoint": "https://ggysljqvqa.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "trackAuditLog",
            "endpoint": "https://2ts6qgv9c2.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        },
        {
            "name": "zoho",
            "endpoint": "https://z5alzvk542.execute-api.us-east-2.amazonaws.com/devqa",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://iv5bvba3drevrjbdbwd6d75xay.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:098f8dfe-9d02-4049-ad8a-31a84d7ca5ee",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_hpvOiHUgf",
    "aws_user_pools_web_client_id": "7lru0a0mq4cje6dncqaf6j5hm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hera2009fb1ba5504c8da9ba5f1fe34db738124403-devqa",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
