import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=f90fe972&scoped=true&"
import script from "./Notifications.vue?vue&type=script&lang=js&"
export * from "./Notifications.vue?vue&type=script&lang=js&"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=f90fe972&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f90fe972",
  null
  
)

export default component.exports