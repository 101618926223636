<template>
    <el-tooltip
        class="item"
        effect="dark"
        placement="top-start"
        v-if="tooltipHasVisible"
        popper-class="z-50 cell-custom-tooltip break-all"
    >
        <template slot="content">
            <slot name="tooltip" :expandedValue="value" :contractedValue="contractedValue">
                {{ value | text}}
            </slot>
        </template>
        <slot :textValue="value">
            <span v-if="kind && (kind === 'float' || kind === 'percent')">
                {{ value | float({kind}) }}
            </span>
            
            <span v-else>{{ value | text }}</span>
        </slot>
    </el-tooltip>
    <span v-else>
        <slot :textValue="value">
            <span v-if="kind && (kind === 'float' || kind === 'percent')">
                {{ value | float({kind}) }}
            </span>

            <span v-else>
                {{ value | text }}
            </span>
        </slot>
    </span>
</template>
<script>
export default {
    model:{
        prop: 'value',
        event: 'input'
    },
    props: [
        'value',
        'maxLength',
        'forceVisible',
        'kind',
    ],
    data: ()=>({
        safeMaxLength: 0,
        contractedValue: "",
        tooltipHasVisible: false
    }),
    async mounted(){

        let maxLength
        if(this.maxLength  instanceof Promise ){
            maxLength = await this.maxLength || 20
        }else{
            maxLength = this.maxLength || 20

        }
        this.safeMaxLength = maxLength
        const length = this.value?.length || 0

        if(this.forceVisible && length <= this.safeMaxLength ){

            this.contractedValue = this.value
        }else{
            this.contractedValue = this.value?.substr?.(0, this.safeMaxLength - 1)?.concat("…")
        }

        if(this.forceVisible)
            this.tooltipHasVisible = true
        else if(this.value == null)
            this.tooltipHasVisible = false
        else if (length > this.safeMaxLength) {
            this.tooltipHasVisible = true
        }else{
            this.tooltipHasVisible = false
        }
    }
}
</script>
<style lang="scss">
.cell-custom-tooltip.el-tooltip__popper{
    max-width: 100%;
    max-height: 100%;
    ::-webkit-scrollbar {
        width: 14px;
    }
    ::-webkit-scrollbar-track {
        @apply bg-gray-700;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb {
        @apply bg-gray-100;
        border-radius: 3px;
    }
    > :not(.popper__arrow), &:not(:has(:not(.popper__arrow))) {
        overflow: auto;
        max-width: calc(27vw + 8rem);
        max-height: calc(25vh + 7rem);
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}
.ex1{
    > div{
        background: blue;
    }
}
</style>
<style>
@supports not selector(::-webkit-scrollbar) {
    .cell-custom-tooltip.el-tooltip__popper {
        scrollbar-color: theme("colors.gray.100") theme("colors.gray.700");
    }
}
</style>