import { mixinMethods } from '@/main'
import store from '@/store'
import EventBus from '@/eventBus'
import { initializeSubscription, disconnectSubscription } from '../helper'
import { onCreateUserNotificationByUserId } from '@/store/subscriptions'
import { userNotificationsByUserIdAndIsRead } from '@/store/queries'
import { 
    HERA_MESSENGER_NEW_MESSAGE,
    DAILY_ROSTER_IMPORT_COMPLETE,
    DAILY_PERFORMANCE_DATA_IMPORT_COMPLETE,
    WEEKLY_PERFORMANCE_DATA_IMPORT_COMPLETE,
    TASK_REMINDER,
    VEHICLE_MAINTENANCE_REMINDER,
    COUNSELING_SIGNED_BY_ASSOCIATE,
    HIDDEN
} from '@/utilities/constants/notificationTypes'
import { checkPushSubscription, subscribeToPushNotifications } from '@/views/UserSettings/pushNotification'

"use strict";
let subscriptionUserNotification = null

const loadUnreadUserNotifications = async function(){
    if(!store.state.userInfo?.id) return
    const input = { 
        userId: store.state.userInfo.id,
        isRead: { eq: "false" },
        sortDirection: 'DESC',
    }
    const unreadUserNotifications = await mixinMethods.gLoadListAll(userNotificationsByUserIdAndIsRead, input, "userNotificationsByUserIdAndIsRead");
    store.commit('setUserNotifications', unreadUserNotifications)
}

export const userNotificationSubscribe = function(instance){
    const queries = {
        onCreateUserNotificationByUserId
    }
    if(!store.state.userInfo?.id) return
    const input = {
        userId: store.state.userInfo.id
    }
    initializeSubscription(subscriptionUserNotification, queries, input, "subscriptionStore/handleUserNotification", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionUserNotification = subscriptions['userNotification'] = {
        loadList: loadUnreadUserNotifications,
        subscribe: userNotificationSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: userNotificationEventCallback,
        subscription
    }
}


export const userNotificationEventCallback = async function(subscriptionState, { data: userNotification, eventName }){
    const eventNames = {
        'onCreate': async () => {
            let showToast = true
            //check user permission
            const receiveNotificationInApp = store.state.userInfo.receiveNotificationInApp?.includes(userNotification.notificationType)
            if(receiveNotificationInApp && 'Notification' in window && Notification.permission === 'granted' && userNotification.notificationType != HIDDEN){

                showToast = false
                const check = await checkPushSubscription()
                if(!check){
                    showToast = true
                    await subscribeToPushNotifications()
                }
                processUserNotification(userNotification, showToast)
            }else{
                //show toast notification
                showToast = true
                processUserNotification(userNotification, showToast)
            }

            if(userNotification.notificationType != HIDDEN){
                store.commit('addUserNotification', userNotification)
            }
        },

        'onUpdate': () => {
           
        },

        'onDelete': () => {
           
        }
    }
    await eventNames[eventName]()
    //console.log({eventName, userNotification})
}



const processUserNotification = async function (notification, showToast) {
    const notificationPayload = notification.payload?.trim().startsWith('{') ? JSON.parse(notification.payload) : {}
    if(notification.title === "User Permissions Updated" || notification.title == 'DEACTIVATE USER' || 
        notification.title == 'Outstanding Invoices Paid'){
        await logout(notification)
    }
    else if(notification.title == 'JOB COMPLETE'){
        //notificationPayload.notificationId = notification.id
        importJobComplete(notificationPayload, notification.title, notificationPayload.name)
    }
    else if(notification.title.toLowerCase().includes('import complete')){
        const params = notificationPayload.params
        params.notification = notification
        importJobComplete(params, 'IMPORT RESULTS', notificationPayload.name)
    }
    else if(notification.title.includes('Error importing your weekly performance data') || notification.title.includes('Warning')){
        EventBus.$emit('weekly-performance-fails', notification)
    }
    else if(notification.title.includes('does not have a phone number')){
        EventBus.$emit('missing-phone-number', notification)
    }
    else if(notification.title.includes('Error importing your Amazon Spreadsheet')){
        EventBus.$emit('mark-amazon-import-error', notificationPayload)
    } 
    else if(notification.description.includes('Your messages have been successfully queued for delivery to the chosen Associates')){
        EventBus.$emit('sent-messages-successfully', notification, showToast)
    } 
}

const importJobComplete = function (payload, type, importName = 'WeeklyDataImport'){
    const events = {
        'JOB COMPLETE':{
            'AmazonImport': 'mark-amazon-import-job-complete',
            'CustomCSVImport': 'mark-custom-csv-job-complete',
            'DailyDataImport': 'mark-daily-job-complete',
            'WeeklyDataImport': 'mark-job-complete'
        },
        'IMPORT RESULTS':{
            'AmazonImport': 'load-amazon-import-results',
            'CustomCSVImport': 'load-custom-csv-import-results',
            'DailyDataImport': 'load-daily-import-results',
            'WeeklyDataImport': 'load-import-results'
        }
    }
    EventBus.$emit(events[type][importName], payload)
}

const logout = async function (params) {
    try{
        console.log("LOGOUT")
        store.commit('setSessionInfo', null)
        store.commit('setUserInfo', null)
        document.location.href="/"
        location.reload()
    }catch(e){
        console.error('Logout Error', { e })
    }
}