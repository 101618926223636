import {
    onCreateRosterChecklistSubjectItemByGroup,
    // onUpdateRosterChecklistSubjectItemByGroup,
    // onDeleteRosterChecklistSubjectItemByGroup
} from '../../subscriptions';
import store from '@/store'
import { initializeSubscription, disconnectSubscription } from '../helper'

import EventBus from '@/eventBus'

"use strict";
let subscriptionRosterChecklistSubjectItems = null

export const rosterChecklistSubjectItemSubscribe = function(instance){
    const queries = {
        onCreateRosterChecklistSubjectItemByGroup,
        // onUpdateRosterChecklistSubjectItemByGroup,
        // onDeleteRosterChecklistSubjectItemByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionRosterChecklistSubjectItems, queries, input, "subscriptionStore/handleRosterChecklistSubjectItem", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionRosterChecklistSubjectItems = subscriptions['rosterChecklistSubjectItem'] = {
        loadList: null,
        subscribe: rosterChecklistSubjectItemSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: rosterChecklistSubjectItemEventCallback,
        subscription
    }
}

export const rosterChecklistSubjectItemEventCallback = async function(state, { data: element, eventName }){
    const eventNames = {
        'onCreate': () => {
            EventBus.$emit('update-roster-checklist-counter', element)
        }
    }
    eventNames[eventName]()
}