<template>
  <div v-loading="loading">
    <TableCard tablecard-class="rounded-lg">
      <template v-slot:table-filters>
        <div>
          <container-filter inTable>
            <template v-slot:title>
              <div class="text-center">
                <span class="uppercase">Custom Lists</span>
              </div>
            </template>
            <template v-slot:filter-count>
              <counter-filter :counter="getResumeFilters" />
            </template>
            <template v-slot:filters>
              <MultiSelectFilter
                :type="categoryFilterType"
                title="List category"
                filterAllTitle="List Categories"
                :options="optionsListCategory"
                :selected="filterOptionsListCategory"
                @update-filters="updateFilters"
              />
            </template>
          </container-filter>
        </div>
      </template>

      <template v-slot:table-searchbox>
        <search-box inTable @search-data="handleSearch"></search-box>
      </template>

      <custom-table
        inTable
        v-if="dataReady"
        :columns="cols"
        :data="filteredData"
        :totalRecords="tableDataRecords"
        emptyText="No Custom Lists"
        footerTable="Custom Lists"
        @click-menu="handleCommand"
        :childs="{ children: 'records' }"
        :optionsRows="optionsContext"
        :optionsSubRows="optionsContext"
      >
        <template #options="row">
          <ul>
            <li v-for="(option, index) in row.options" :key="option.id" class="float-left mr-1 break-all">
              {{ `${(index==0) ? "" : "•"} ${option.option}` }}
            </li>
          </ul>
        </template>
      </custom-table>
    </TableCard>
    
    <DrawerCustomList 
      v-loading="loadingDataCustomList"
      :drawerVisibility="drawerVisibility"
      :titleDrawer="titleDrawer"
      :tableDataSelected="tableDataSelected"
      @hideDrawer="hideDrawer"
      @reloadCustomList="reloadCustomList"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TabCard from "@/components/TableCard/TabCard";
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import SearchBox from "@/components/TableCard/SearchBox";
import MultiSelectFilter from "@/components/MultiSelectFilter";
import CounterFilter from "@/components/CounterFilters";
import DrawerCustomList from './components/DrawerCustomList'
import { multipleDefaultFilter } from "@/components/filtersFunctions";
import { API } from 'aws-amplify'

export default {
  components: {
    TableCard,
    CustomTable,
    ContainerFilter,
    SearchBox,
    TabCard,
    MultiSelectFilter,
    CounterFilter,
    DrawerCustomList
  },
  data() {
    return {
      categoryFilterType: "category",
      titleDrawer: "Custom List Option",
      tableDataSelected: {},
      optionSelected: [],
      dataReady: true,
      search: '',
      cols: [
        {
          name: "List Category",
          col: "listCategory",
          fixed: false,
          sortable: true,
          sortMethod: undefined,
          width: "100"
        },
        {
          name: "List Name",
          col: "listName",
          fixed: false,
          sortable: true,
          sortMethod: undefined,
          width: "100"
        },
        {
          name: "List Display",
          col: "listDisplay",
          fixed: false,
          sortable: true,
          sortMethod: undefined,
          width: "100"
        },
        {
          name: "Options",
          col: "options",
          fixed: false,
          sortable: true,
          sortMethod: undefined,
          width: "200"
        },
      ],
      optionsListCategory: [
        "Associates",
        "Coaching",
        "Daily Rostering",
        "Devices",
        "Vehicles",
        "Performance & Coaching",
        "Vehicle Management",
        "Roster Checklist"
      ],
      optionsContext: [
        {
          label: "Edit this Custom List",
          action: "editCustomList",
        },
      ],
      drawerVisibility: false,
      loading: false,
      loadingDataCustomList: false,
    };
  },
  async mounted() {
    try {
      this.loading = true
      await this.loadCustomList()
    } catch (e) {
      this.printUserError(e)
      this.displayUserError(e)
    } finally {
      this.loading = false
    }
  },
  computed: {
    ...mapState(["userInfo", "customList"]),
    filterOptionsListCategory: {
      get(){
        const query = this.$route.query[this.categoryFilterType]
        const filtersList = this.optionsListCategory
        let defaultFilter = [
          "Associates",
          "Coaching",
          "Daily Rostering",
          "Devices",
          "Vehicles",
          "Performance & Coaching",
          "Vehicle Management"
        ]
        return multipleDefaultFilter(query, defaultFilter, filtersList)
      },
      set(items){},
    },
    filteredData() {
      return this.filterArray(this.tableDataFiltered, this.search);
    },
    getResumeFilters() {
      return this.filterOptionsListCategory.length === this.optionsListCategory.length ? 0 : 1;
    },
    tableData() {
      return this.customList.filter(item => this.filterOptionsListCategory.includes(item.listCategory));
    },
    tableDataRecords() {
      return this.customList.length;
    },
    tableDataFiltered() {
      if (this.filterOptionsListCategory) {
        return this.tableData.filter(item => this.filterOptionsListCategory.includes(item.listCategory))
      }
      return this.tableData
    }
  },
  methods: {
    async handleCommand({ action, row }) {
      if (action === "editCustomList") {
        this.tableDataSelected = JSON.parse(JSON.stringify(row));
        this.titleDrawer = "Custom List Options: " + row.listName
        this.drawerVisibility = true;
        this.loadingDataCustomList = true
        this.tableDataSelected.options = await this.loadDataOptionsCustomList(row)
      }
    },

    handleSearch(word) {
      this.search = word
    },

    async hideDrawer() {
      this.drawerVisibility = false;
    },
    async reloadCustomList() {
        this.reloadTableDataSelected()
    },
    reloadTableDataSelected() {
      const customListSelected = this.tableData.find(obj => obj.id === this.tableDataSelected.id)
      this.tableDataSelected = JSON.parse(JSON.stringify(customListSelected))
    },
    updateFilters(e) {
      const { type, filters } = e;
      this.filterOptionsListCategory = filters
    },
    async loadDataOptionsCustomList(row) {
      const {group, type} = row

      try {
          const apiName = "heraPublicApi"
          const path = "/query/option-custom-list"
          const { records } = await API.post(apiName, path, 
            { 
              body: { group, type}
            }
          )

          /*  HERA-5272 This is a code snippet to count the number of checklists
              and checklist templates where the vehicle type customlist has been used,
              this may be improved or removed in the AWS GraphQl v1 legacy latest version */
          if(type === 'vehicle-type' ){

            let apiName2 = 'heraPublicApi';
            let path2 = '/executeMutation';
            let post = {
                body: {
                    "type": 'custom-list-query',
                    "operation": 'get-vehicle-types-used-for',
                    "group": group
                }
            }
  
            const res = await safeFunction(API.post)(apiName2, path2, post)

            records.forEach(record =>{
              const counterVT = res[record.id]
              const { checklistTemplates = 0, checklist = 0 } = counterVT || {}
              record.checklistTemplates = checklistTemplates
              record.checklist = checklist

            })

          }
          return records
      } catch (e) {
        console.error("Error: ", e);
        this.displayUserError(e);
        return []
      } finally {
        this.loadingDataCustomList = false
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-personalized {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .container-filter,
  .maintenance-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
.el-drawer {
  width: 35% !important;
}

@media (max-width: 1900px) {
  .el-drawer {
    width: 40% !important;
  }
}

@media (max-width: 1500px) {
  .el-drawer {
    width: 50% !important;
  }
}

@media (max-width: 1200px) {
  .el-drawer {
    width: 60% !important;
  }
}

@media (max-width: 1000px) {
  .el-drawer {
    width: 80% !important;
  }
}

@media (max-width: 750px) {
  .el-drawer {
    width: 90% !important;
  }
}
</style>
