import {mixinMethods} from '@/main';
import { getOptionsCustomList } from './queries';

async function existOptionCustomListInDb(id) {
  if (!id) return false;
  const {data: {getOptionsCustomLists: optionCustomList}} = await mixinMethods.api(getOptionsCustomList, {id});
  return !!optionCustomList;
}

/**
 * Verifies if a record with the specified ID exists in the DB, throw error if does not exists
 * @param {String|Array} id - ID or list of IDs to fetch from OptionCustomLists
 * @throws {Error} if some option does not exists throws an error
 */
export async function validateOptionCustomListsExist(id) {
  if (!id) return;
  const customError = new Error('Custom List options were updated by another user. Please copy any changes and refresh the page.');
  try {
    if (typeof id === 'string') {
      const exists =  !!(await existOptionCustomListInDb(id));
      if (!exists) throw customError;
    }
    if (Array.isArray(id)) {
      const promises = id.map(async (id) => await existOptionCustomListInDb(id));
      const results = await Promise.all(promises);
      const exists = results.every(exists => exists === true);
      if (!exists) throw customError;
    }
  }
  catch (error) {
    throw error;
  }
}

export function mapPhotoLogOptions (options) {
  return options.map(option => {
    return {
      id: option.id,
      option: option.option
    }
  })
}
