/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCard = /* GraphQL */ `
  mutation CreateCard(
    $input: CreateCardInput!
    $condition: ModelCardConditionInput
  ) {
    createCard(input: $input, condition: $condition) {
      id
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateCard = /* GraphQL */ `
  mutation UpdateCard(
    $input: UpdateCardInput!
    $condition: ModelCardConditionInput
  ) {
    updateCard(input: $input, condition: $condition) {
      id
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteCard = /* GraphQL */ `
  mutation DeleteCard(
    $input: DeleteCardInput!
    $condition: ModelCardConditionInput
  ) {
    deleteCard(input: $input, condition: $condition) {
      id
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const createCompanyScoreCard = /* GraphQL */ `
  mutation CreateCompanyScoreCard(
    $input: CreateCompanyScoreCardInput!
    $condition: ModelCompanyScoreCardConditionInput
  ) {
    createCompanyScoreCard(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      pickupSuccessBehaviors
      pickupSuccessBehaviorsText
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyScoreCard = /* GraphQL */ `
  mutation UpdateCompanyScoreCard(
    $input: UpdateCompanyScoreCardInput!
    $condition: ModelCompanyScoreCardConditionInput
  ) {
    updateCompanyScoreCard(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      pickupSuccessBehaviors
      pickupSuccessBehaviorsText
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyScoreCard = /* GraphQL */ `
  mutation DeleteCompanyScoreCard(
    $input: DeleteCompanyScoreCardInput!
    $condition: ModelCompanyScoreCardConditionInput
  ) {
    deleteCompanyScoreCard(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      pickupSuccessBehaviors
      pickupSuccessBehaviorsText
      createdAt
      updatedAt
    }
  }
`;
export const createCounselingTemplate = /* GraphQL */ `
  mutation CreateCounselingTemplate(
    $input: CreateCounselingTemplateInput!
    $condition: ModelCounselingTemplateConditionInput
  ) {
    createCounselingTemplate(input: $input, condition: $condition) {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
    }
  }
`;
export const updateCounselingTemplate = /* GraphQL */ `
  mutation UpdateCounselingTemplate(
    $input: UpdateCounselingTemplateInput!
    $condition: ModelCounselingTemplateConditionInput
  ) {
    updateCounselingTemplate(input: $input, condition: $condition) {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
    }
  }
`;
export const deleteCounselingTemplate = /* GraphQL */ `
  mutation DeleteCounselingTemplate(
    $input: DeleteCounselingTemplateInput!
    $condition: ModelCounselingTemplateConditionInput
  ) {
    deleteCounselingTemplate(input: $input, condition: $condition) {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
    }
  }
`;
export const createMessageTemplate = /* GraphQL */ `
  mutation CreateMessageTemplate(
    $input: CreateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    createMessageTemplate(input: $input, condition: $condition) {
      id
      group
      name
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageTemplate = /* GraphQL */ `
  mutation UpdateMessageTemplate(
    $input: UpdateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    updateMessageTemplate(input: $input, condition: $condition) {
      id
      group
      name
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageTemplate = /* GraphQL */ `
  mutation DeleteMessageTemplate(
    $input: DeleteMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    deleteMessageTemplate(input: $input, condition: $condition) {
      id
      group
      name
      message
      createdAt
      updatedAt
    }
  }
`;
export const createRecurringMessages = /* GraphQL */ `
  mutation CreateRecurringMessages(
    $input: CreateRecurringMessagesInput!
    $condition: ModelRecurringMessagesConditionInput
  ) {
    createRecurringMessages(input: $input, condition: $condition) {
      id
      group
      messageName
      messageBody
      sendTime
      channelType
      senderId
      senderName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateRecurringMessages = /* GraphQL */ `
  mutation UpdateRecurringMessages(
    $input: UpdateRecurringMessagesInput!
    $condition: ModelRecurringMessagesConditionInput
  ) {
    updateRecurringMessages(input: $input, condition: $condition) {
      id
      group
      messageName
      messageBody
      sendTime
      channelType
      senderId
      senderName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteRecurringMessages = /* GraphQL */ `
  mutation DeleteRecurringMessages(
    $input: DeleteRecurringMessagesInput!
    $condition: ModelRecurringMessagesConditionInput
  ) {
    deleteRecurringMessages(input: $input, condition: $condition) {
      id
      group
      messageName
      messageBody
      sendTime
      channelType
      senderId
      senderName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createDrugTest = /* GraphQL */ `
  mutation CreateDrugTest(
    $input: CreateDrugTestInput!
    $condition: ModelDrugTestConditionInput
  ) {
    createDrugTest(input: $input, condition: $condition) {
      id
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateDrugTest = /* GraphQL */ `
  mutation UpdateDrugTest(
    $input: UpdateDrugTestInput!
    $condition: ModelDrugTestConditionInput
  ) {
    updateDrugTest(input: $input, condition: $condition) {
      id
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteDrugTest = /* GraphQL */ `
  mutation DeleteDrugTest(
    $input: DeleteDrugTestInput!
    $condition: ModelDrugTestConditionInput
  ) {
    deleteDrugTest(input: $input, condition: $condition) {
      id
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createInjury = /* GraphQL */ `
  mutation CreateInjury(
    $input: CreateInjuryInput!
    $condition: ModelInjuryConditionInput
  ) {
    createInjury(input: $input, condition: $condition) {
      id
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      completedByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateInjury = /* GraphQL */ `
  mutation UpdateInjury(
    $input: UpdateInjuryInput!
    $condition: ModelInjuryConditionInput
  ) {
    updateInjury(input: $input, condition: $condition) {
      id
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      completedByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteInjury = /* GraphQL */ `
  mutation DeleteInjury(
    $input: DeleteInjuryInput!
    $condition: ModelInjuryConditionInput
  ) {
    deleteInjury(input: $input, condition: $condition) {
      id
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      completedByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createPendingMessage = /* GraphQL */ `
  mutation CreatePendingMessage(
    $input: CreatePendingMessageInput!
    $condition: ModelPendingMessageConditionInput
  ) {
    createPendingMessage(input: $input, condition: $condition) {
      id
      group
      createdAt
      staffId
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const updatePendingMessage = /* GraphQL */ `
  mutation UpdatePendingMessage(
    $input: UpdatePendingMessageInput!
    $condition: ModelPendingMessageConditionInput
  ) {
    updatePendingMessage(input: $input, condition: $condition) {
      id
      group
      createdAt
      staffId
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const deletePendingMessage = /* GraphQL */ `
  mutation DeletePendingMessage(
    $input: DeletePendingMessageInput!
    $condition: ModelPendingMessageConditionInput
  ) {
    deletePendingMessage(input: $input, condition: $condition) {
      id
      group
      createdAt
      staffId
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const createReplacedByStandbyByRoute = /* GraphQL */ `
  mutation CreateReplacedByStandbyByRoute(
    $input: CreateReplacedByStandbyByRouteInput!
    $condition: ModelReplacedByStandbyByRouteConditionInput
  ) {
    createReplacedByStandbyByRoute(input: $input, condition: $condition) {
      id
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateReplacedByStandbyByRoute = /* GraphQL */ `
  mutation UpdateReplacedByStandbyByRoute(
    $input: UpdateReplacedByStandbyByRouteInput!
    $condition: ModelReplacedByStandbyByRouteConditionInput
  ) {
    updateReplacedByStandbyByRoute(input: $input, condition: $condition) {
      id
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteReplacedByStandbyByRoute = /* GraphQL */ `
  mutation DeleteReplacedByStandbyByRoute(
    $input: DeleteReplacedByStandbyByRouteInput!
    $condition: ModelReplacedByStandbyByRouteConditionInput
  ) {
    deleteReplacedByStandbyByRoute(input: $input, condition: $condition) {
      id
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createSystem = /* GraphQL */ `
  mutation CreateSystem(
    $input: CreateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    createSystem(input: $input, condition: $condition) {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
    }
  }
`;
export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem(
    $input: UpdateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    updateSystem(input: $input, condition: $condition) {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystem = /* GraphQL */ `
  mutation DeleteSystem(
    $input: DeleteSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    deleteSystem(input: $input, condition: $condition) {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
    }
  }
`;
export const createDailyRostersColumns = /* GraphQL */ `
  mutation CreateDailyRostersColumns(
    $input: CreateDailyRostersColumnsInput!
    $condition: ModelDailyRostersColumnsConditionInput
  ) {
    createDailyRostersColumns(input: $input, condition: $condition) {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyRostersColumns = /* GraphQL */ `
  mutation UpdateDailyRostersColumns(
    $input: UpdateDailyRostersColumnsInput!
    $condition: ModelDailyRostersColumnsConditionInput
  ) {
    updateDailyRostersColumns(input: $input, condition: $condition) {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyRostersColumns = /* GraphQL */ `
  mutation DeleteDailyRostersColumns(
    $input: DeleteDailyRostersColumnsInput!
    $condition: ModelDailyRostersColumnsConditionInput
  ) {
    deleteDailyRostersColumns(input: $input, condition: $condition) {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
    }
  }
`;
export const createTextractJob = /* GraphQL */ `
  mutation CreateTextractJob(
    $input: CreateTextractJobInput!
    $condition: ModelTextractJobConditionInput
  ) {
    createTextractJob(input: $input, condition: $condition) {
      id
      group
      owner
      userId
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      cxFeedbackSummary {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      properParkingSequenceSummary {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTextractJob = /* GraphQL */ `
  mutation UpdateTextractJob(
    $input: UpdateTextractJobInput!
    $condition: ModelTextractJobConditionInput
  ) {
    updateTextractJob(input: $input, condition: $condition) {
      id
      group
      owner
      userId
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      cxFeedbackSummary {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      properParkingSequenceSummary {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTextractJob = /* GraphQL */ `
  mutation DeleteTextractJob(
    $input: DeleteTextractJobInput!
    $condition: ModelTextractJobConditionInput
  ) {
    deleteTextractJob(input: $input, condition: $condition) {
      id
      group
      owner
      userId
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      cxFeedbackSummary {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      properParkingSequenceSummary {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPodQualitySummary = /* GraphQL */ `
  mutation CreatePodQualitySummary(
    $input: CreatePodQualitySummaryInput!
    $condition: ModelPodQualitySummaryConditionInput
  ) {
    createPodQualitySummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePodQualitySummary = /* GraphQL */ `
  mutation UpdatePodQualitySummary(
    $input: UpdatePodQualitySummaryInput!
    $condition: ModelPodQualitySummaryConditionInput
  ) {
    updatePodQualitySummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePodQualitySummary = /* GraphQL */ `
  mutation DeletePodQualitySummary(
    $input: DeletePodQualitySummaryInput!
    $condition: ModelPodQualitySummaryConditionInput
  ) {
    deletePodQualitySummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCxFeedbackSummary = /* GraphQL */ `
  mutation CreateCxFeedbackSummary(
    $input: CreateCxFeedbackSummaryInput!
    $condition: ModelCxFeedbackSummaryConditionInput
  ) {
    createCxFeedbackSummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCxFeedbackSummary = /* GraphQL */ `
  mutation UpdateCxFeedbackSummary(
    $input: UpdateCxFeedbackSummaryInput!
    $condition: ModelCxFeedbackSummaryConditionInput
  ) {
    updateCxFeedbackSummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCxFeedbackSummary = /* GraphQL */ `
  mutation DeleteCxFeedbackSummary(
    $input: DeleteCxFeedbackSummaryInput!
    $condition: ModelCxFeedbackSummaryConditionInput
  ) {
    deleteCxFeedbackSummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWave = /* GraphQL */ `
  mutation CreateWave(
    $input: CreateWaveInput!
    $condition: ModelWaveConditionInput
  ) {
    createWave(input: $input, condition: $condition) {
      id
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateWave = /* GraphQL */ `
  mutation UpdateWave(
    $input: UpdateWaveInput!
    $condition: ModelWaveConditionInput
  ) {
    updateWave(input: $input, condition: $condition) {
      id
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteWave = /* GraphQL */ `
  mutation DeleteWave(
    $input: DeleteWaveInput!
    $condition: ModelWaveConditionInput
  ) {
    deleteWave(input: $input, condition: $condition) {
      id
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const createRouteStaffHelper = /* GraphQL */ `
  mutation CreateRouteStaffHelper(
    $input: CreateRouteStaffHelperInput!
    $condition: ModelRouteStaffHelperConditionInput
  ) {
    createRouteStaffHelper(input: $input, condition: $condition) {
      id
      group
      routeId
      staffId
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateRouteStaffHelper = /* GraphQL */ `
  mutation UpdateRouteStaffHelper(
    $input: UpdateRouteStaffHelperInput!
    $condition: ModelRouteStaffHelperConditionInput
  ) {
    updateRouteStaffHelper(input: $input, condition: $condition) {
      id
      group
      routeId
      staffId
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteRouteStaffHelper = /* GraphQL */ `
  mutation DeleteRouteStaffHelper(
    $input: DeleteRouteStaffHelperInput!
    $condition: ModelRouteStaffHelperConditionInput
  ) {
    deleteRouteStaffHelper(input: $input, condition: $condition) {
      id
      group
      routeId
      staffId
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createStripeQueue = /* GraphQL */ `
  mutation CreateStripeQueue(
    $input: CreateStripeQueueInput!
    $condition: ModelStripeQueueConditionInput
  ) {
    createStripeQueue(input: $input, condition: $condition) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateStripeQueue = /* GraphQL */ `
  mutation UpdateStripeQueue(
    $input: UpdateStripeQueueInput!
    $condition: ModelStripeQueueConditionInput
  ) {
    updateStripeQueue(input: $input, condition: $condition) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteStripeQueue = /* GraphQL */ `
  mutation DeleteStripeQueue(
    $input: DeleteStripeQueueInput!
    $condition: ModelStripeQueueConditionInput
  ) {
    deleteStripeQueue(input: $input, condition: $condition) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAuditLogArchive = /* GraphQL */ `
  mutation CreateAuditLogArchive(
    $input: CreateAuditLogArchiveInput!
    $condition: ModelAuditLogArchiveConditionInput
  ) {
    createAuditLogArchive(input: $input, condition: $condition) {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      createdAt
      auditLogCreatedAt
      ipAddress
      pageUrl
      userType
      updatedAt
    }
  }
`;
export const updateAuditLogArchive = /* GraphQL */ `
  mutation UpdateAuditLogArchive(
    $input: UpdateAuditLogArchiveInput!
    $condition: ModelAuditLogArchiveConditionInput
  ) {
    updateAuditLogArchive(input: $input, condition: $condition) {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      createdAt
      auditLogCreatedAt
      ipAddress
      pageUrl
      userType
      updatedAt
    }
  }
`;
export const deleteAuditLogArchive = /* GraphQL */ `
  mutation DeleteAuditLogArchive(
    $input: DeleteAuditLogArchiveInput!
    $condition: ModelAuditLogArchiveConditionInput
  ) {
    deleteAuditLogArchive(input: $input, condition: $condition) {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      createdAt
      auditLogCreatedAt
      ipAddress
      pageUrl
      userType
      updatedAt
    }
  }
`;
export const createNickname = /* GraphQL */ `
  mutation CreateNickname(
    $input: CreateNicknameInput!
    $condition: ModelNicknameConditionInput
  ) {
    createNickname(input: $input, condition: $condition) {
      id
      name
      nicknames
      createdAt
      updatedAt
    }
  }
`;
export const updateNickname = /* GraphQL */ `
  mutation UpdateNickname(
    $input: UpdateNicknameInput!
    $condition: ModelNicknameConditionInput
  ) {
    updateNickname(input: $input, condition: $condition) {
      id
      name
      nicknames
      createdAt
      updatedAt
    }
  }
`;
export const deleteNickname = /* GraphQL */ `
  mutation DeleteNickname(
    $input: DeleteNicknameInput!
    $condition: ModelNicknameConditionInput
  ) {
    deleteNickname(input: $input, condition: $condition) {
      id
      name
      nicknames
      createdAt
      updatedAt
    }
  }
`;
export const createAccident = /* GraphQL */ `
  mutation CreateAccident(
    $input: CreateAccidentInput!
    $condition: ModelAccidentConditionInput
  ) {
    createAccident(input: $input, condition: $condition) {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateAccident = /* GraphQL */ `
  mutation UpdateAccident(
    $input: UpdateAccidentInput!
    $condition: ModelAccidentConditionInput
  ) {
    updateAccident(input: $input, condition: $condition) {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteAccident = /* GraphQL */ `
  mutation DeleteAccident(
    $input: DeleteAccidentInput!
    $condition: ModelAccidentConditionInput
  ) {
    deleteAccident(input: $input, condition: $condition) {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      message {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      message {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      message {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createEocScore = /* GraphQL */ `
  mutation CreateEocScore(
    $input: CreateEocScoreInput!
    $condition: ModelEocScoreConditionInput
  ) {
    createEocScore(input: $input, condition: $condition) {
      id
      group
      date
      level
      average
      averageDailyCompliance
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateEocScore = /* GraphQL */ `
  mutation UpdateEocScore(
    $input: UpdateEocScoreInput!
    $condition: ModelEocScoreConditionInput
  ) {
    updateEocScore(input: $input, condition: $condition) {
      id
      group
      date
      level
      average
      averageDailyCompliance
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteEocScore = /* GraphQL */ `
  mutation DeleteEocScore(
    $input: DeleteEocScoreInput!
    $condition: ModelEocScoreConditionInput
  ) {
    deleteEocScore(input: $input, condition: $condition) {
      id
      group
      date
      level
      average
      averageDailyCompliance
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createCounseling = /* GraphQL */ `
  mutation CreateCounseling(
    $input: CreateCounselingInput!
    $condition: ModelCounselingConditionInput
  ) {
    createCounseling(input: $input, condition: $condition) {
      id
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      counselingTypeId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      severity {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateCounseling = /* GraphQL */ `
  mutation UpdateCounseling(
    $input: UpdateCounselingInput!
    $condition: ModelCounselingConditionInput
  ) {
    updateCounseling(input: $input, condition: $condition) {
      id
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      counselingTypeId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      severity {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteCounseling = /* GraphQL */ `
  mutation DeleteCounseling(
    $input: DeleteCounselingInput!
    $condition: ModelCounselingConditionInput
  ) {
    deleteCounseling(input: $input, condition: $condition) {
      id
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      counselingTypeId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      severity {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createShortenUrl = /* GraphQL */ `
  mutation CreateShortenUrl(
    $input: CreateShortenUrlInput!
    $condition: ModelShortenUrlConditionInput
  ) {
    createShortenUrl(input: $input, condition: $condition) {
      id
      group
      type
      shortenId
      expirationTTL
      urlToken
      attachmentId
      rosterChecklistSubjectId
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateShortenUrl = /* GraphQL */ `
  mutation UpdateShortenUrl(
    $input: UpdateShortenUrlInput!
    $condition: ModelShortenUrlConditionInput
  ) {
    updateShortenUrl(input: $input, condition: $condition) {
      id
      group
      type
      shortenId
      expirationTTL
      urlToken
      attachmentId
      rosterChecklistSubjectId
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteShortenUrl = /* GraphQL */ `
  mutation DeleteShortenUrl(
    $input: DeleteShortenUrlInput!
    $condition: ModelShortenUrlConditionInput
  ) {
    deleteShortenUrl(input: $input, condition: $condition) {
      id
      group
      type
      shortenId
      expirationTTL
      urlToken
      attachmentId
      rosterChecklistSubjectId
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createDailyRoster = /* GraphQL */ `
  mutation CreateDailyRoster(
    $input: CreateDailyRosterInput!
    $condition: ModelDailyRosterConditionInput
  ) {
    createDailyRoster(input: $input, condition: $condition) {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      notes {
        items {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateDailyRoster = /* GraphQL */ `
  mutation UpdateDailyRoster(
    $input: UpdateDailyRosterInput!
    $condition: ModelDailyRosterConditionInput
  ) {
    updateDailyRoster(input: $input, condition: $condition) {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      notes {
        items {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteDailyRoster = /* GraphQL */ `
  mutation DeleteDailyRoster(
    $input: DeleteDailyRosterInput!
    $condition: ModelDailyRosterConditionInput
  ) {
    deleteDailyRoster(input: $input, condition: $condition) {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      notes {
        items {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const createSchedulerTask = /* GraphQL */ `
  mutation CreateSchedulerTask(
    $input: CreateSchedulerTaskInput!
    $condition: ModelSchedulerTaskConditionInput
  ) {
    createSchedulerTask(input: $input, condition: $condition) {
      id
      type
      apiEndpoint
      apiStage
      apiPath
      lambdaName
      group
      taskPayload
      scheduledDate
      executionId
      initiated
      createdAt
      updatedAt
    }
  }
`;
export const updateSchedulerTask = /* GraphQL */ `
  mutation UpdateSchedulerTask(
    $input: UpdateSchedulerTaskInput!
    $condition: ModelSchedulerTaskConditionInput
  ) {
    updateSchedulerTask(input: $input, condition: $condition) {
      id
      type
      apiEndpoint
      apiStage
      apiPath
      lambdaName
      group
      taskPayload
      scheduledDate
      executionId
      initiated
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchedulerTask = /* GraphQL */ `
  mutation DeleteSchedulerTask(
    $input: DeleteSchedulerTaskInput!
    $condition: ModelSchedulerTaskConditionInput
  ) {
    deleteSchedulerTask(input: $input, condition: $condition) {
      id
      type
      apiEndpoint
      apiStage
      apiPath
      lambdaName
      group
      taskPayload
      scheduledDate
      executionId
      initiated
      createdAt
      updatedAt
    }
  }
`;
export const createRosterChecklist = /* GraphQL */ `
  mutation CreateRosterChecklist(
    $input: CreateRosterChecklistInput!
    $condition: ModelRosterChecklistConditionInput
  ) {
    createRosterChecklist(input: $input, condition: $condition) {
      id
      name
      completionFrequencyId
      assignedToId
      group
      tenantId
      sendTime
      totalSimpleItems
      totalVehicleItems
      status
      createdAt
      updatedAt
      vehicleTypes {
        items {
          id
          vehicleTypeId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedUsers {
        items {
          id
          userId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      checklistTasks {
        items {
          id
          type
          taskId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistItems {
        items {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          requiredPhotoSpecifications
          photoSpecifications
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      completionFrequency {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      assignedTo {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateRosterChecklist = /* GraphQL */ `
  mutation UpdateRosterChecklist(
    $input: UpdateRosterChecklistInput!
    $condition: ModelRosterChecklistConditionInput
  ) {
    updateRosterChecklist(input: $input, condition: $condition) {
      id
      name
      completionFrequencyId
      assignedToId
      group
      tenantId
      sendTime
      totalSimpleItems
      totalVehicleItems
      status
      createdAt
      updatedAt
      vehicleTypes {
        items {
          id
          vehicleTypeId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedUsers {
        items {
          id
          userId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      checklistTasks {
        items {
          id
          type
          taskId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistItems {
        items {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          requiredPhotoSpecifications
          photoSpecifications
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      completionFrequency {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      assignedTo {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteRosterChecklist = /* GraphQL */ `
  mutation DeleteRosterChecklist(
    $input: DeleteRosterChecklistInput!
    $condition: ModelRosterChecklistConditionInput
  ) {
    deleteRosterChecklist(input: $input, condition: $condition) {
      id
      name
      completionFrequencyId
      assignedToId
      group
      tenantId
      sendTime
      totalSimpleItems
      totalVehicleItems
      status
      createdAt
      updatedAt
      vehicleTypes {
        items {
          id
          vehicleTypeId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedUsers {
        items {
          id
          userId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      checklistTasks {
        items {
          id
          type
          taskId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistItems {
        items {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          requiredPhotoSpecifications
          photoSpecifications
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      completionFrequency {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      assignedTo {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createRosterChecklistVehicleType = /* GraphQL */ `
  mutation CreateRosterChecklistVehicleType(
    $input: CreateRosterChecklistVehicleTypeInput!
    $condition: ModelRosterChecklistVehicleTypeConditionInput
  ) {
    createRosterChecklistVehicleType(input: $input, condition: $condition) {
      id
      vehicleTypeId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      VehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateRosterChecklistVehicleType = /* GraphQL */ `
  mutation UpdateRosterChecklistVehicleType(
    $input: UpdateRosterChecklistVehicleTypeInput!
    $condition: ModelRosterChecklistVehicleTypeConditionInput
  ) {
    updateRosterChecklistVehicleType(input: $input, condition: $condition) {
      id
      vehicleTypeId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      VehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteRosterChecklistVehicleType = /* GraphQL */ `
  mutation DeleteRosterChecklistVehicleType(
    $input: DeleteRosterChecklistVehicleTypeInput!
    $condition: ModelRosterChecklistVehicleTypeConditionInput
  ) {
    deleteRosterChecklistVehicleType(input: $input, condition: $condition) {
      id
      vehicleTypeId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      VehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createRosterChecklistUser = /* GraphQL */ `
  mutation CreateRosterChecklistUser(
    $input: CreateRosterChecklistUserInput!
    $condition: ModelRosterChecklistUserConditionInput
  ) {
    createRosterChecklistUser(input: $input, condition: $condition) {
      id
      userId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateRosterChecklistUser = /* GraphQL */ `
  mutation UpdateRosterChecklistUser(
    $input: UpdateRosterChecklistUserInput!
    $condition: ModelRosterChecklistUserConditionInput
  ) {
    updateRosterChecklistUser(input: $input, condition: $condition) {
      id
      userId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteRosterChecklistUser = /* GraphQL */ `
  mutation DeleteRosterChecklistUser(
    $input: DeleteRosterChecklistUserInput!
    $condition: ModelRosterChecklistUserConditionInput
  ) {
    deleteRosterChecklistUser(input: $input, condition: $condition) {
      id
      userId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createRosterChecklistTask = /* GraphQL */ `
  mutation CreateRosterChecklistTask(
    $input: CreateRosterChecklistTaskInput!
    $condition: ModelRosterChecklistTaskConditionInput
  ) {
    createRosterChecklistTask(input: $input, condition: $condition) {
      id
      type
      taskId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      task {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateRosterChecklistTask = /* GraphQL */ `
  mutation UpdateRosterChecklistTask(
    $input: UpdateRosterChecklistTaskInput!
    $condition: ModelRosterChecklistTaskConditionInput
  ) {
    updateRosterChecklistTask(input: $input, condition: $condition) {
      id
      type
      taskId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      task {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteRosterChecklistTask = /* GraphQL */ `
  mutation DeleteRosterChecklistTask(
    $input: DeleteRosterChecklistTaskInput!
    $condition: ModelRosterChecklistTaskConditionInput
  ) {
    deleteRosterChecklistTask(input: $input, condition: $condition) {
      id
      type
      taskId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      task {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createRosterChecklistItem = /* GraphQL */ `
  mutation CreateRosterChecklistItem(
    $input: CreateRosterChecklistItemInput!
    $condition: ModelRosterChecklistItemConditionInput
  ) {
    createRosterChecklistItem(input: $input, condition: $condition) {
      id
      typeId
      titleQuestion
      descriptionExplanation
      order
      group
      status
      rosterChecklistId
      requiredPhotoSpecifications
      photoSpecifications
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateRosterChecklistItem = /* GraphQL */ `
  mutation UpdateRosterChecklistItem(
    $input: UpdateRosterChecklistItemInput!
    $condition: ModelRosterChecklistItemConditionInput
  ) {
    updateRosterChecklistItem(input: $input, condition: $condition) {
      id
      typeId
      titleQuestion
      descriptionExplanation
      order
      group
      status
      rosterChecklistId
      requiredPhotoSpecifications
      photoSpecifications
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteRosterChecklistItem = /* GraphQL */ `
  mutation DeleteRosterChecklistItem(
    $input: DeleteRosterChecklistItemInput!
    $condition: ModelRosterChecklistItemConditionInput
  ) {
    deleteRosterChecklistItem(input: $input, condition: $condition) {
      id
      typeId
      titleQuestion
      descriptionExplanation
      order
      group
      status
      rosterChecklistId
      requiredPhotoSpecifications
      photoSpecifications
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const createDailyLog = /* GraphQL */ `
  mutation CreateDailyLog(
    $input: CreateDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    createDailyLog(input: $input, condition: $condition) {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateDailyLog = /* GraphQL */ `
  mutation UpdateDailyLog(
    $input: UpdateDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    updateDailyLog(input: $input, condition: $condition) {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteDailyLog = /* GraphQL */ `
  mutation DeleteDailyLog(
    $input: DeleteDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    deleteDailyLog(input: $input, condition: $condition) {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createDailyLogHistory = /* GraphQL */ `
  mutation CreateDailyLogHistory(
    $input: CreateDailyLogHistoryInput!
    $condition: ModelDailyLogHistoryConditionInput
  ) {
    createDailyLogHistory(input: $input, condition: $condition) {
      id
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateDailyLogHistory = /* GraphQL */ `
  mutation UpdateDailyLogHistory(
    $input: UpdateDailyLogHistoryInput!
    $condition: ModelDailyLogHistoryConditionInput
  ) {
    updateDailyLogHistory(input: $input, condition: $condition) {
      id
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteDailyLogHistory = /* GraphQL */ `
  mutation DeleteDailyLogHistory(
    $input: DeleteDailyLogHistoryInput!
    $condition: ModelDailyLogHistoryConditionInput
  ) {
    deleteDailyLogHistory(input: $input, condition: $condition) {
      id
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      aiSubmissionDateTime
      aiResponseDateTime
      aiResponse
      aiResponseStatus
      aiIssues
      aiResponseAcknowledgedDateTime
      aiResponseFeedback
      aiDeterminedOdometerReadingValue
      aiResponseAcknowledgedBy
      injury {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imagevehicleDamage {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      maintenance {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imageCounseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentRecords {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      infraction {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomLists {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      aiSubmissionDateTime
      aiResponseDateTime
      aiResponse
      aiResponseStatus
      aiIssues
      aiResponseAcknowledgedDateTime
      aiResponseFeedback
      aiDeterminedOdometerReadingValue
      aiResponseAcknowledgedBy
      injury {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imagevehicleDamage {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      maintenance {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imageCounseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentRecords {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      infraction {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomLists {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      aiSubmissionDateTime
      aiResponseDateTime
      aiResponse
      aiResponseStatus
      aiIssues
      aiResponseAcknowledgedDateTime
      aiResponseFeedback
      aiDeterminedOdometerReadingValue
      aiResponseAcknowledgedBy
      injury {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imagevehicleDamage {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      maintenance {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imageCounseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentRecords {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      infraction {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomLists {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createDocumentConnections = /* GraphQL */ `
  mutation CreateDocumentConnections(
    $input: CreateDocumentConnectionsInput!
    $condition: ModelDocumentConnectionsConditionInput
  ) {
    createDocumentConnections(input: $input, condition: $condition) {
      id
      documentID
      group
      createdByDocument
      incidentID
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentDocument {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateDocumentConnections = /* GraphQL */ `
  mutation UpdateDocumentConnections(
    $input: UpdateDocumentConnectionsInput!
    $condition: ModelDocumentConnectionsConditionInput
  ) {
    updateDocumentConnections(input: $input, condition: $condition) {
      id
      documentID
      group
      createdByDocument
      incidentID
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentDocument {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteDocumentConnections = /* GraphQL */ `
  mutation DeleteDocumentConnections(
    $input: DeleteDocumentConnectionsInput!
    $condition: ModelDocumentConnectionsConditionInput
  ) {
    deleteDocumentConnections(input: $input, condition: $condition) {
      id
      documentID
      group
      createdByDocument
      incidentID
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentDocument {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createInfraction = /* GraphQL */ `
  mutation CreateInfraction(
    $input: CreateInfractionInput!
    $condition: ModelInfractionConditionInput
  ) {
    createInfraction(input: $input, condition: $condition) {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateInfraction = /* GraphQL */ `
  mutation UpdateInfraction(
    $input: UpdateInfractionInput!
    $condition: ModelInfractionConditionInput
  ) {
    updateInfraction(input: $input, condition: $condition) {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteInfraction = /* GraphQL */ `
  mutation DeleteInfraction(
    $input: DeleteInfractionInput!
    $condition: ModelInfractionConditionInput
  ) {
    deleteInfraction(input: $input, condition: $condition) {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      createdAt
      group
      month
      year
      invoiceTotal
      variableTotal
      invoiceSubTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      paidByCustomerAmount
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      createdAt
      group
      month
      year
      invoiceTotal
      variableTotal
      invoiceSubTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      paidByCustomerAmount
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      createdAt
      group
      month
      year
      invoiceTotal
      variableTotal
      invoiceSubTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      paidByCustomerAmount
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const createInvoiceLineItem = /* GraphQL */ `
  mutation CreateInvoiceLineItem(
    $input: CreateInvoiceLineItemInput!
    $condition: ModelInvoiceLineItemConditionInput
  ) {
    createInvoiceLineItem(input: $input, condition: $condition) {
      id
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
    }
  }
`;
export const updateInvoiceLineItem = /* GraphQL */ `
  mutation UpdateInvoiceLineItem(
    $input: UpdateInvoiceLineItemInput!
    $condition: ModelInvoiceLineItemConditionInput
  ) {
    updateInvoiceLineItem(input: $input, condition: $condition) {
      id
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
    }
  }
`;
export const deleteInvoiceLineItem = /* GraphQL */ `
  mutation DeleteInvoiceLineItem(
    $input: DeleteInvoiceLineItemInput!
    $condition: ModelInvoiceLineItemConditionInput
  ) {
    deleteInvoiceLineItem(input: $input, condition: $condition) {
      id
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        invoiceSubTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        paidByCustomerAmount
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
    }
  }
`;
export const createKudo = /* GraphQL */ `
  mutation CreateKudo(
    $input: CreateKudoInput!
    $condition: ModelKudoConditionInput
  ) {
    createKudo(input: $input, condition: $condition) {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateKudo = /* GraphQL */ `
  mutation UpdateKudo(
    $input: UpdateKudoInput!
    $condition: ModelKudoConditionInput
  ) {
    updateKudo(input: $input, condition: $condition) {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteKudo = /* GraphQL */ `
  mutation DeleteKudo(
    $input: DeleteKudoInput!
    $condition: ModelKudoConditionInput
  ) {
    deleteKudo(input: $input, condition: $condition) {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createResourceUsage = /* GraphQL */ `
  mutation CreateResourceUsage(
    $input: CreateResourceUsageInput!
    $condition: ModelResourceUsageConditionInput
  ) {
    createResourceUsage(input: $input, condition: $condition) {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateResourceUsage = /* GraphQL */ `
  mutation UpdateResourceUsage(
    $input: UpdateResourceUsageInput!
    $condition: ModelResourceUsageConditionInput
  ) {
    updateResourceUsage(input: $input, condition: $condition) {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteResourceUsage = /* GraphQL */ `
  mutation DeleteResourceUsage(
    $input: DeleteResourceUsageInput!
    $condition: ModelResourceUsageConditionInput
  ) {
    deleteResourceUsage(input: $input, condition: $condition) {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createVehicleMention = /* GraphQL */ `
  mutation CreateVehicleMention(
    $input: CreateVehicleMentionInput!
    $condition: ModelVehicleMentionConditionInput
  ) {
    createVehicleMention(input: $input, condition: $condition) {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateVehicleMention = /* GraphQL */ `
  mutation UpdateVehicleMention(
    $input: UpdateVehicleMentionInput!
    $condition: ModelVehicleMentionConditionInput
  ) {
    updateVehicleMention(input: $input, condition: $condition) {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteVehicleMention = /* GraphQL */ `
  mutation DeleteVehicleMention(
    $input: DeleteVehicleMentionInput!
    $condition: ModelVehicleMentionConditionInput
  ) {
    deleteVehicleMention(input: $input, condition: $condition) {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createStaffMention = /* GraphQL */ `
  mutation CreateStaffMention(
    $input: CreateStaffMentionInput!
    $condition: ModelStaffMentionConditionInput
  ) {
    createStaffMention(input: $input, condition: $condition) {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateStaffMention = /* GraphQL */ `
  mutation UpdateStaffMention(
    $input: UpdateStaffMentionInput!
    $condition: ModelStaffMentionConditionInput
  ) {
    updateStaffMention(input: $input, condition: $condition) {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteStaffMention = /* GraphQL */ `
  mutation DeleteStaffMention(
    $input: DeleteStaffMentionInput!
    $condition: ModelStaffMentionConditionInput
  ) {
    deleteStaffMention(input: $input, condition: $condition) {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
    }
  }
`;
export const createCompanyNotification = /* GraphQL */ `
  mutation CreateCompanyNotification(
    $input: CreateCompanyNotificationInput!
    $condition: ModelCompanyNotificationConditionInput
  ) {
    createCompanyNotification(input: $input, condition: $condition) {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyNotification = /* GraphQL */ `
  mutation UpdateCompanyNotification(
    $input: UpdateCompanyNotificationInput!
    $condition: ModelCompanyNotificationConditionInput
  ) {
    updateCompanyNotification(input: $input, condition: $condition) {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyNotification = /* GraphQL */ `
  mutation DeleteCompanyNotification(
    $input: DeleteCompanyNotificationInput!
    $condition: ModelCompanyNotificationConditionInput
  ) {
    deleteCompanyNotification(input: $input, condition: $condition) {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
    }
  }
`;
export const createOnBoard = /* GraphQL */ `
  mutation CreateOnBoard(
    $input: CreateOnBoardInput!
    $condition: ModelOnBoardConditionInput
  ) {
    createOnBoard(input: $input, condition: $condition) {
      id
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateOnBoard = /* GraphQL */ `
  mutation UpdateOnBoard(
    $input: UpdateOnBoardInput!
    $condition: ModelOnBoardConditionInput
  ) {
    updateOnBoard(input: $input, condition: $condition) {
      id
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteOnBoard = /* GraphQL */ `
  mutation DeleteOnBoard(
    $input: DeleteOnBoardInput!
    $condition: ModelOnBoardConditionInput
  ) {
    deleteOnBoard(input: $input, condition: $condition) {
      id
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createPhysical = /* GraphQL */ `
  mutation CreatePhysical(
    $input: CreatePhysicalInput!
    $condition: ModelPhysicalConditionInput
  ) {
    createPhysical(input: $input, condition: $condition) {
      id
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updatePhysical = /* GraphQL */ `
  mutation UpdatePhysical(
    $input: UpdatePhysicalInput!
    $condition: ModelPhysicalConditionInput
  ) {
    updatePhysical(input: $input, condition: $condition) {
      id
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deletePhysical = /* GraphQL */ `
  mutation DeletePhysical(
    $input: DeletePhysicalInput!
    $condition: ModelPhysicalConditionInput
  ) {
    deletePhysical(input: $input, condition: $condition) {
      id
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createPodQuality = /* GraphQL */ `
  mutation CreatePodQuality(
    $input: CreatePodQualityInput!
    $condition: ModelPodQualityConditionInput
  ) {
    createPodQuality(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updatePodQuality = /* GraphQL */ `
  mutation UpdatePodQuality(
    $input: UpdatePodQualityInput!
    $condition: ModelPodQualityConditionInput
  ) {
    updatePodQuality(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deletePodQuality = /* GraphQL */ `
  mutation DeletePodQuality(
    $input: DeletePodQualityInput!
    $condition: ModelPodQualityConditionInput
  ) {
    deletePodQuality(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createReplaceByRoute = /* GraphQL */ `
  mutation CreateReplaceByRoute(
    $input: CreateReplaceByRouteInput!
    $condition: ModelReplaceByRouteConditionInput
  ) {
    createReplaceByRoute(input: $input, condition: $condition) {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateReplaceByRoute = /* GraphQL */ `
  mutation UpdateReplaceByRoute(
    $input: UpdateReplaceByRouteInput!
    $condition: ModelReplaceByRouteConditionInput
  ) {
    updateReplaceByRoute(input: $input, condition: $condition) {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteReplaceByRoute = /* GraphQL */ `
  mutation DeleteReplaceByRoute(
    $input: DeleteReplaceByRouteInput!
    $condition: ModelReplaceByRouteConditionInput
  ) {
    deleteReplaceByRoute(input: $input, condition: $condition) {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createRoute = /* GraphQL */ `
  mutation CreateRoute(
    $input: CreateRouteInput!
    $condition: ModelRouteConditionInput
  ) {
    createRoute(input: $input, condition: $condition) {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateRoute = /* GraphQL */ `
  mutation UpdateRoute(
    $input: UpdateRouteInput!
    $condition: ModelRouteConditionInput
  ) {
    updateRoute(input: $input, condition: $condition) {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteRoute = /* GraphQL */ `
  mutation DeleteRoute(
    $input: DeleteRouteInput!
    $condition: ModelRouteConditionInput
  ) {
    deleteRoute(input: $input, condition: $condition) {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createLabelType = /* GraphQL */ `
  mutation CreateLabelType(
    $input: CreateLabelTypeInput!
    $condition: ModelLabelTypeConditionInput
  ) {
    createLabelType(input: $input, condition: $condition) {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateLabelType = /* GraphQL */ `
  mutation UpdateLabelType(
    $input: UpdateLabelTypeInput!
    $condition: ModelLabelTypeConditionInput
  ) {
    updateLabelType(input: $input, condition: $condition) {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteLabelType = /* GraphQL */ `
  mutation DeleteLabelType(
    $input: DeleteLabelTypeInput!
    $condition: ModelLabelTypeConditionInput
  ) {
    deleteLabelType(input: $input, condition: $condition) {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createLabelTypeList = /* GraphQL */ `
  mutation CreateLabelTypeList(
    $input: CreateLabelTypeListInput!
    $condition: ModelLabelTypeListConditionInput
  ) {
    createLabelTypeList(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const updateLabelTypeList = /* GraphQL */ `
  mutation UpdateLabelTypeList(
    $input: UpdateLabelTypeListInput!
    $condition: ModelLabelTypeListConditionInput
  ) {
    updateLabelTypeList(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const deleteLabelTypeList = /* GraphQL */ `
  mutation DeleteLabelTypeList(
    $input: DeleteLabelTypeListInput!
    $condition: ModelLabelTypeListConditionInput
  ) {
    deleteLabelTypeList(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const createLabel = /* GraphQL */ `
  mutation CreateLabel(
    $input: CreateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    createLabel(input: $input, condition: $condition) {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateLabel = /* GraphQL */ `
  mutation UpdateLabel(
    $input: UpdateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    updateLabel(input: $input, condition: $condition) {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteLabel = /* GraphQL */ `
  mutation DeleteLabel(
    $input: DeleteLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    deleteLabel(input: $input, condition: $condition) {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createLabelSubscription = /* GraphQL */ `
  mutation CreateLabelSubscription(
    $input: CreateLabelSubscriptionInput!
    $condition: ModelLabelSubscriptionConditionInput
  ) {
    createLabelSubscription(input: $input, condition: $condition) {
      id
      group
      labelId
      staffId
      vehicleId
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateLabelSubscription = /* GraphQL */ `
  mutation UpdateLabelSubscription(
    $input: UpdateLabelSubscriptionInput!
    $condition: ModelLabelSubscriptionConditionInput
  ) {
    updateLabelSubscription(input: $input, condition: $condition) {
      id
      group
      labelId
      staffId
      vehicleId
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteLabelSubscription = /* GraphQL */ `
  mutation DeleteLabelSubscription(
    $input: DeleteLabelSubscriptionInput!
    $condition: ModelLabelSubscriptionConditionInput
  ) {
    deleteLabelSubscription(input: $input, condition: $condition) {
      id
      group
      labelId
      staffId
      vehicleId
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $input: CreateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    createStaff(input: $input, condition: $condition) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteStaff = /* GraphQL */ `
  mutation DeleteStaff(
    $input: DeleteStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    deleteStaff(input: $input, condition: $condition) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          psb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createMessagePreferencesHistory = /* GraphQL */ `
  mutation CreateMessagePreferencesHistory(
    $input: CreateMessagePreferencesHistoryInput!
    $condition: ModelMessagePreferencesHistoryConditionInput
  ) {
    createMessagePreferencesHistory(input: $input, condition: $condition) {
      id
      group
      messagePreferenceType
      description
      datetime
      messagePreferencesHistoryStaffId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateMessagePreferencesHistory = /* GraphQL */ `
  mutation UpdateMessagePreferencesHistory(
    $input: UpdateMessagePreferencesHistoryInput!
    $condition: ModelMessagePreferencesHistoryConditionInput
  ) {
    updateMessagePreferencesHistory(input: $input, condition: $condition) {
      id
      group
      messagePreferenceType
      description
      datetime
      messagePreferencesHistoryStaffId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteMessagePreferencesHistory = /* GraphQL */ `
  mutation DeleteMessagePreferencesHistory(
    $input: DeleteMessagePreferencesHistoryInput!
    $condition: ModelMessagePreferencesHistoryConditionInput
  ) {
    deleteMessagePreferencesHistory(input: $input, condition: $condition) {
      id
      group
      messagePreferenceType
      description
      datetime
      messagePreferencesHistoryStaffId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createStaffCxFeedback = /* GraphQL */ `
  mutation CreateStaffCxFeedback(
    $input: CreateStaffCxFeedbackInput!
    $condition: ModelStaffCxFeedbackConditionInput
  ) {
    createStaffCxFeedback(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateStaffCxFeedback = /* GraphQL */ `
  mutation UpdateStaffCxFeedback(
    $input: UpdateStaffCxFeedbackInput!
    $condition: ModelStaffCxFeedbackConditionInput
  ) {
    updateStaffCxFeedback(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteStaffCxFeedback = /* GraphQL */ `
  mutation DeleteStaffCxFeedback(
    $input: DeleteStaffCxFeedbackInput!
    $condition: ModelStaffCxFeedbackConditionInput
  ) {
    deleteStaffCxFeedback(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createStaffMentor = /* GraphQL */ `
  mutation CreateStaffMentor(
    $input: CreateStaffMentorInput!
    $condition: ModelStaffMentorConditionInput
  ) {
    createStaffMentor(input: $input, condition: $condition) {
      id
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateStaffMentor = /* GraphQL */ `
  mutation UpdateStaffMentor(
    $input: UpdateStaffMentorInput!
    $condition: ModelStaffMentorConditionInput
  ) {
    updateStaffMentor(input: $input, condition: $condition) {
      id
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteStaffMentor = /* GraphQL */ `
  mutation DeleteStaffMentor(
    $input: DeleteStaffMentorInput!
    $condition: ModelStaffMentorConditionInput
  ) {
    deleteStaffMentor(input: $input, condition: $condition) {
      id
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createStaffNetradyneAlert = /* GraphQL */ `
  mutation CreateStaffNetradyneAlert(
    $input: CreateStaffNetradyneAlertInput!
    $condition: ModelStaffNetradyneAlertConditionInput
  ) {
    createStaffNetradyneAlert(input: $input, condition: $condition) {
      id
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateStaffNetradyneAlert = /* GraphQL */ `
  mutation UpdateStaffNetradyneAlert(
    $input: UpdateStaffNetradyneAlertInput!
    $condition: ModelStaffNetradyneAlertConditionInput
  ) {
    updateStaffNetradyneAlert(input: $input, condition: $condition) {
      id
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteStaffNetradyneAlert = /* GraphQL */ `
  mutation DeleteStaffNetradyneAlert(
    $input: DeleteStaffNetradyneAlertInput!
    $condition: ModelStaffNetradyneAlertConditionInput
  ) {
    deleteStaffNetradyneAlert(input: $input, condition: $condition) {
      id
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createStaffScoreCard = /* GraphQL */ `
  mutation CreateStaffScoreCard(
    $input: CreateStaffScoreCardInput!
    $condition: ModelStaffScoreCardConditionInput
  ) {
    createStaffScoreCard(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      psb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateStaffScoreCard = /* GraphQL */ `
  mutation UpdateStaffScoreCard(
    $input: UpdateStaffScoreCardInput!
    $condition: ModelStaffScoreCardConditionInput
  ) {
    updateStaffScoreCard(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      psb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteStaffScoreCard = /* GraphQL */ `
  mutation DeleteStaffScoreCard(
    $input: DeleteStaffScoreCardInput!
    $condition: ModelStaffScoreCardConditionInput
  ) {
    deleteStaffScoreCard(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      psb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createStaffStatus = /* GraphQL */ `
  mutation CreateStaffStatus(
    $input: CreateStaffStatusInput!
    $condition: ModelStaffStatusConditionInput
  ) {
    createStaffStatus(input: $input, condition: $condition) {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateStaffStatus = /* GraphQL */ `
  mutation UpdateStaffStatus(
    $input: UpdateStaffStatusInput!
    $condition: ModelStaffStatusConditionInput
  ) {
    updateStaffStatus(input: $input, condition: $condition) {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteStaffStatus = /* GraphQL */ `
  mutation DeleteStaffStatus(
    $input: DeleteStaffStatusInput!
    $condition: ModelStaffStatusConditionInput
  ) {
    deleteStaffStatus(input: $input, condition: $condition) {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createRouteStatus = /* GraphQL */ `
  mutation CreateRouteStatus(
    $input: CreateRouteStatusInput!
    $condition: ModelRouteStatusConditionInput
  ) {
    createRouteStatus(input: $input, condition: $condition) {
      id
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      associate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      previousStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      currentStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateRouteStatus = /* GraphQL */ `
  mutation UpdateRouteStatus(
    $input: UpdateRouteStatusInput!
    $condition: ModelRouteStatusConditionInput
  ) {
    updateRouteStatus(input: $input, condition: $condition) {
      id
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      associate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      previousStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      currentStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteRouteStatus = /* GraphQL */ `
  mutation DeleteRouteStatus(
    $input: DeleteRouteStatusInput!
    $condition: ModelRouteStatusConditionInput
  ) {
    deleteRouteStatus(input: $input, condition: $condition) {
      id
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      associate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      previousStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      currentStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createRosterChecklistSubject = /* GraphQL */ `
  mutation CreateRosterChecklistSubject(
    $input: CreateRosterChecklistSubjectInput!
    $condition: ModelRosterChecklistSubjectConditionInput
  ) {
    createRosterChecklistSubject(input: $input, condition: $condition) {
      id
      notesDate
      group
      inactive
      staffId
      userId
      rosterChecklistId
      routeId
      replaceByRouteId
      dailyRosterId
      simpleItemsCompleted
      vehicleItemsCompleted
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      sentMessages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubjectItems {
        items {
          id
          group
          rosterChecklistSubjectId
          rosterChecklistItemId
          dailyLogId
          odometerReadingId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateRosterChecklistSubject = /* GraphQL */ `
  mutation UpdateRosterChecklistSubject(
    $input: UpdateRosterChecklistSubjectInput!
    $condition: ModelRosterChecklistSubjectConditionInput
  ) {
    updateRosterChecklistSubject(input: $input, condition: $condition) {
      id
      notesDate
      group
      inactive
      staffId
      userId
      rosterChecklistId
      routeId
      replaceByRouteId
      dailyRosterId
      simpleItemsCompleted
      vehicleItemsCompleted
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      sentMessages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubjectItems {
        items {
          id
          group
          rosterChecklistSubjectId
          rosterChecklistItemId
          dailyLogId
          odometerReadingId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteRosterChecklistSubject = /* GraphQL */ `
  mutation DeleteRosterChecklistSubject(
    $input: DeleteRosterChecklistSubjectInput!
    $condition: ModelRosterChecklistSubjectConditionInput
  ) {
    deleteRosterChecklistSubject(input: $input, condition: $condition) {
      id
      notesDate
      group
      inactive
      staffId
      userId
      rosterChecklistId
      routeId
      replaceByRouteId
      dailyRosterId
      simpleItemsCompleted
      vehicleItemsCompleted
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
      }
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      sentMessages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubjectItems {
        items {
          id
          group
          rosterChecklistSubjectId
          rosterChecklistItemId
          dailyLogId
          odometerReadingId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createRosterChecklistSubjectItem = /* GraphQL */ `
  mutation CreateRosterChecklistSubjectItem(
    $input: CreateRosterChecklistSubjectItemInput!
    $condition: ModelRosterChecklistSubjectItemConditionInput
  ) {
    createRosterChecklistSubjectItem(input: $input, condition: $condition) {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        requiredPhotoSpecifications
        photoSpecifications
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateRosterChecklistSubjectItem = /* GraphQL */ `
  mutation UpdateRosterChecklistSubjectItem(
    $input: UpdateRosterChecklistSubjectItemInput!
    $condition: ModelRosterChecklistSubjectItemConditionInput
  ) {
    updateRosterChecklistSubjectItem(input: $input, condition: $condition) {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        requiredPhotoSpecifications
        photoSpecifications
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteRosterChecklistSubjectItem = /* GraphQL */ `
  mutation DeleteRosterChecklistSubjectItem(
    $input: DeleteRosterChecklistSubjectItemInput!
    $condition: ModelRosterChecklistSubjectItemConditionInput
  ) {
    deleteRosterChecklistSubjectItem(input: $input, condition: $condition) {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        requiredPhotoSpecifications
        photoSpecifications
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigneeAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      assigner {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      assignee {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigneeAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      assigner {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      assignee {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigneeAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      assigner {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      assignee {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      cards {
        items {
          id
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
        }
        nextToken
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          pickupSuccessBehaviors
          pickupSuccessBehaviorsText
          createdAt
          updatedAt
        }
        nextToken
      }
      textractjobs {
        items {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      waves {
        items {
          id
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
        }
        nextToken
      }
      recurringMessage {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      parentAccount {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      valueLists {
        items {
          id
          group
          key
          createdAt
          updatedAt
        }
        nextToken
      }
      premiumStatusHistory {
        items {
          id
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      messageServiceProviderHistory {
        items {
          id
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      cards {
        items {
          id
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
        }
        nextToken
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          pickupSuccessBehaviors
          pickupSuccessBehaviorsText
          createdAt
          updatedAt
        }
        nextToken
      }
      textractjobs {
        items {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      waves {
        items {
          id
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
        }
        nextToken
      }
      recurringMessage {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      parentAccount {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      valueLists {
        items {
          id
          group
          key
          createdAt
          updatedAt
        }
        nextToken
      }
      premiumStatusHistory {
        items {
          id
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      messageServiceProviderHistory {
        items {
          id
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      cards {
        items {
          id
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
        }
        nextToken
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          pickupSuccessBehaviors
          pickupSuccessBehaviorsText
          createdAt
          updatedAt
        }
        nextToken
      }
      textractjobs {
        items {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      waves {
        items {
          id
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
        }
        nextToken
      }
      recurringMessage {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          variableTotal
          invoiceSubTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          paidByCustomerAmount
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      parentAccount {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      valueLists {
        items {
          id
          group
          key
          createdAt
          updatedAt
        }
        nextToken
      }
      premiumStatusHistory {
        items {
          id
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      messageServiceProviderHistory {
        items {
          id
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCoachingHistory = /* GraphQL */ `
  mutation CreateCoachingHistory(
    $input: CreateCoachingHistoryInput!
    $condition: ModelCoachingHistoryConditionInput
  ) {
    createCoachingHistory(input: $input, condition: $condition) {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
    }
  }
`;
export const updateCoachingHistory = /* GraphQL */ `
  mutation UpdateCoachingHistory(
    $input: UpdateCoachingHistoryInput!
    $condition: ModelCoachingHistoryConditionInput
  ) {
    updateCoachingHistory(input: $input, condition: $condition) {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoachingHistory = /* GraphQL */ `
  mutation DeleteCoachingHistory(
    $input: DeleteCoachingHistoryInput!
    $condition: ModelCoachingHistoryConditionInput
  ) {
    deleteCoachingHistory(input: $input, condition: $condition) {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
    }
  }
`;
export const createCoachingRecords = /* GraphQL */ `
  mutation CreateCoachingRecords(
    $input: CreateCoachingRecordsInput!
    $condition: ModelCoachingRecordsConditionInput
  ) {
    createCoachingRecords(input: $input, condition: $condition) {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
    }
  }
`;
export const updateCoachingRecords = /* GraphQL */ `
  mutation UpdateCoachingRecords(
    $input: UpdateCoachingRecordsInput!
    $condition: ModelCoachingRecordsConditionInput
  ) {
    updateCoachingRecords(input: $input, condition: $condition) {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoachingRecords = /* GraphQL */ `
  mutation DeleteCoachingRecords(
    $input: DeleteCoachingRecordsInput!
    $condition: ModelCoachingRecordsConditionInput
  ) {
    deleteCoachingRecords(input: $input, condition: $condition) {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
    }
  }
`;
export const createCustomLists = /* GraphQL */ `
  mutation CreateCustomLists(
    $input: CreateCustomListsInput!
    $condition: ModelCustomListsConditionInput
  ) {
    createCustomLists(input: $input, condition: $condition) {
      id
      group
      type
      enabledRecordTemplates
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCustomLists = /* GraphQL */ `
  mutation UpdateCustomLists(
    $input: UpdateCustomListsInput!
    $condition: ModelCustomListsConditionInput
  ) {
    updateCustomLists(input: $input, condition: $condition) {
      id
      group
      type
      enabledRecordTemplates
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCustomLists = /* GraphQL */ `
  mutation DeleteCustomLists(
    $input: DeleteCustomListsInput!
    $condition: ModelCustomListsConditionInput
  ) {
    deleteCustomLists(input: $input, condition: $condition) {
      id
      group
      type
      enabledRecordTemplates
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createOptionsCustomLists = /* GraphQL */ `
  mutation CreateOptionsCustomLists(
    $input: CreateOptionsCustomListsInput!
    $condition: ModelOptionsCustomListsConditionInput
  ) {
    createOptionsCustomLists(input: $input, condition: $condition) {
      id
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      recordTemplateJson
      statusType
      driverReportSetting
      key
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      issues {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRouteParkingSpace {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeParkingSpace {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routes {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelperStatus {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      previousRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      currentRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      customLists {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      associates {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicles {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      parkingSpace {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      companies {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const updateOptionsCustomLists = /* GraphQL */ `
  mutation UpdateOptionsCustomLists(
    $input: UpdateOptionsCustomListsInput!
    $condition: ModelOptionsCustomListsConditionInput
  ) {
    updateOptionsCustomLists(input: $input, condition: $condition) {
      id
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      recordTemplateJson
      statusType
      driverReportSetting
      key
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      issues {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRouteParkingSpace {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeParkingSpace {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routes {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelperStatus {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      previousRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      currentRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      customLists {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      associates {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicles {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      parkingSpace {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      companies {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const deleteOptionsCustomLists = /* GraphQL */ `
  mutation DeleteOptionsCustomLists(
    $input: DeleteOptionsCustomListsInput!
    $condition: ModelOptionsCustomListsConditionInput
  ) {
    deleteOptionsCustomLists(input: $input, condition: $condition) {
      id
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      recordTemplateJson
      statusType
      driverReportSetting
      key
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      issues {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRouteParkingSpace {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeParkingSpace {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routes {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelperStatus {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      previousRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      currentRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      customLists {
        id
        group
        type
        enabledRecordTemplates
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      associates {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicles {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      parkingSpace {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      companies {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const createOptionsCustomListsStaff = /* GraphQL */ `
  mutation CreateOptionsCustomListsStaff(
    $input: CreateOptionsCustomListsStaffInput!
    $condition: ModelOptionsCustomListsStaffConditionInput
  ) {
    createOptionsCustomListsStaff(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const updateOptionsCustomListsStaff = /* GraphQL */ `
  mutation UpdateOptionsCustomListsStaff(
    $input: UpdateOptionsCustomListsStaffInput!
    $condition: ModelOptionsCustomListsStaffConditionInput
  ) {
    updateOptionsCustomListsStaff(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const deleteOptionsCustomListsStaff = /* GraphQL */ `
  mutation DeleteOptionsCustomListsStaff(
    $input: DeleteOptionsCustomListsStaffInput!
    $condition: ModelOptionsCustomListsStaffConditionInput
  ) {
    deleteOptionsCustomListsStaff(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const createUniform = /* GraphQL */ `
  mutation CreateUniform(
    $input: CreateUniformInput!
    $condition: ModelUniformConditionInput
  ) {
    createUniform(input: $input, condition: $condition) {
      id
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      uniformType {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      size {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateUniform = /* GraphQL */ `
  mutation UpdateUniform(
    $input: UpdateUniformInput!
    $condition: ModelUniformConditionInput
  ) {
    updateUniform(input: $input, condition: $condition) {
      id
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      uniformType {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      size {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteUniform = /* GraphQL */ `
  mutation DeleteUniform(
    $input: DeleteUniformInput!
    $condition: ModelUniformConditionInput
  ) {
    deleteUniform(input: $input, condition: $condition) {
      id
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      uniformType {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      size {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        counselingTypeId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification(
    $input: CreateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    createUserNotification(input: $input, condition: $condition) {
      id
      group
      title
      description
      expirationTTL
      notificationType
      isRead
      payload
      userId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification(
    $input: UpdateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    updateUserNotification(input: $input, condition: $condition) {
      id
      group
      title
      description
      expirationTTL
      notificationType
      isRead
      payload
      userId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification(
    $input: DeleteUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    deleteUserNotification(input: $input, condition: $condition) {
      id
      group
      title
      description
      expirationTTL
      notificationType
      isRead
      payload
      userId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createSubscribedDevices = /* GraphQL */ `
  mutation CreateSubscribedDevices(
    $input: CreateSubscribedDevicesInput!
    $condition: ModelSubscribedDevicesConditionInput
  ) {
    createSubscribedDevices(input: $input, condition: $condition) {
      id
      group
      subscriptionData
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateSubscribedDevices = /* GraphQL */ `
  mutation UpdateSubscribedDevices(
    $input: UpdateSubscribedDevicesInput!
    $condition: ModelSubscribedDevicesConditionInput
  ) {
    updateSubscribedDevices(input: $input, condition: $condition) {
      id
      group
      subscriptionData
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubscribedDevices = /* GraphQL */ `
  mutation DeleteSubscribedDevices(
    $input: DeleteSubscribedDevicesInput!
    $condition: ModelSubscribedDevicesConditionInput
  ) {
    deleteSubscribedDevices(input: $input, condition: $condition) {
      id
      group
      subscriptionData
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createValueList = /* GraphQL */ `
  mutation CreateValueList(
    $input: CreateValueListInput!
    $condition: ModelValueListConditionInput
  ) {
    createValueList(input: $input, condition: $condition) {
      id
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      items {
        items {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateValueList = /* GraphQL */ `
  mutation UpdateValueList(
    $input: UpdateValueListInput!
    $condition: ModelValueListConditionInput
  ) {
    updateValueList(input: $input, condition: $condition) {
      id
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      items {
        items {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteValueList = /* GraphQL */ `
  mutation DeleteValueList(
    $input: DeleteValueListInput!
    $condition: ModelValueListConditionInput
  ) {
    deleteValueList(input: $input, condition: $condition) {
      id
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      items {
        items {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createValueListItem = /* GraphQL */ `
  mutation CreateValueListItem(
    $input: CreateValueListItemInput!
    $condition: ModelValueListItemConditionInput
  ) {
    createValueListItem(input: $input, condition: $condition) {
      id
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      uniformSizes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      valueList {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const updateValueListItem = /* GraphQL */ `
  mutation UpdateValueListItem(
    $input: UpdateValueListItemInput!
    $condition: ModelValueListItemConditionInput
  ) {
    updateValueListItem(input: $input, condition: $condition) {
      id
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      uniformSizes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      valueList {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const deleteValueListItem = /* GraphQL */ `
  mutation DeleteValueListItem(
    $input: DeleteValueListItemInput!
    $condition: ModelValueListItemConditionInput
  ) {
    deleteValueListItem(input: $input, condition: $condition) {
      id
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      uniformSizes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      valueList {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle(
    $input: CreateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    createVehicle(input: $input, condition: $condition) {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle(
    $input: UpdateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    updateVehicle(input: $input, condition: $condition) {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle(
    $input: DeleteVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    deleteVehicle(input: $input, condition: $condition) {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        recordTemplateJson
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          enabledRecordTemplates
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createVehicleHistory = /* GraphQL */ `
  mutation CreateVehicleHistory(
    $input: CreateVehicleHistoryInput!
    $condition: ModelVehicleHistoryConditionInput
  ) {
    createVehicleHistory(input: $input, condition: $condition) {
      id
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      vehicleId
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateVehicleHistory = /* GraphQL */ `
  mutation UpdateVehicleHistory(
    $input: UpdateVehicleHistoryInput!
    $condition: ModelVehicleHistoryConditionInput
  ) {
    updateVehicleHistory(input: $input, condition: $condition) {
      id
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      vehicleId
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteVehicleHistory = /* GraphQL */ `
  mutation DeleteVehicleHistory(
    $input: DeleteVehicleHistoryInput!
    $condition: ModelVehicleHistoryConditionInput
  ) {
    deleteVehicleHistory(input: $input, condition: $condition) {
      id
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      vehicleId
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createVehicleMaintenance = /* GraphQL */ `
  mutation CreateVehicleMaintenance(
    $input: CreateVehicleMaintenanceInput!
    $condition: ModelVehicleMaintenanceConditionInput
  ) {
    createVehicleMaintenance(input: $input, condition: $condition) {
      id
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateVehicleMaintenance = /* GraphQL */ `
  mutation UpdateVehicleMaintenance(
    $input: UpdateVehicleMaintenanceInput!
    $condition: ModelVehicleMaintenanceConditionInput
  ) {
    updateVehicleMaintenance(input: $input, condition: $condition) {
      id
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteVehicleMaintenance = /* GraphQL */ `
  mutation DeleteVehicleMaintenance(
    $input: DeleteVehicleMaintenanceInput!
    $condition: ModelVehicleMaintenanceConditionInput
  ) {
    deleteVehicleMaintenance(input: $input, condition: $condition) {
      id
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createVehicleMaintenanceReminder = /* GraphQL */ `
  mutation CreateVehicleMaintenanceReminder(
    $input: CreateVehicleMaintenanceReminderInput!
    $condition: ModelVehicleMaintenanceReminderConditionInput
  ) {
    createVehicleMaintenanceReminder(input: $input, condition: $condition) {
      id
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateVehicleMaintenanceReminder = /* GraphQL */ `
  mutation UpdateVehicleMaintenanceReminder(
    $input: UpdateVehicleMaintenanceReminderInput!
    $condition: ModelVehicleMaintenanceReminderConditionInput
  ) {
    updateVehicleMaintenanceReminder(input: $input, condition: $condition) {
      id
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteVehicleMaintenanceReminder = /* GraphQL */ `
  mutation DeleteVehicleMaintenanceReminder(
    $input: DeleteVehicleMaintenanceReminderInput!
    $condition: ModelVehicleMaintenanceReminderConditionInput
  ) {
    deleteVehicleMaintenanceReminder(input: $input, condition: $condition) {
      id
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createVehicleStatus = /* GraphQL */ `
  mutation CreateVehicleStatus(
    $input: CreateVehicleStatusInput!
    $condition: ModelVehicleStatusConditionInput
  ) {
    createVehicleStatus(input: $input, condition: $condition) {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateVehicleStatus = /* GraphQL */ `
  mutation UpdateVehicleStatus(
    $input: UpdateVehicleStatusInput!
    $condition: ModelVehicleStatusConditionInput
  ) {
    updateVehicleStatus(input: $input, condition: $condition) {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteVehicleStatus = /* GraphQL */ `
  mutation DeleteVehicleStatus(
    $input: DeleteVehicleStatusInput!
    $condition: ModelVehicleStatusConditionInput
  ) {
    deleteVehicleStatus(input: $input, condition: $condition) {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const createRouteStaffRescuer = /* GraphQL */ `
  mutation CreateRouteStaffRescuer(
    $input: CreateRouteStaffRescuerInput!
    $condition: ModelRouteStaffRescuerConditionInput
  ) {
    createRouteStaffRescuer(input: $input, condition: $condition) {
      id
      group
      routeId
      staffId
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateRouteStaffRescuer = /* GraphQL */ `
  mutation UpdateRouteStaffRescuer(
    $input: UpdateRouteStaffRescuerInput!
    $condition: ModelRouteStaffRescuerConditionInput
  ) {
    updateRouteStaffRescuer(input: $input, condition: $condition) {
      id
      group
      routeId
      staffId
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteRouteStaffRescuer = /* GraphQL */ `
  mutation DeleteRouteStaffRescuer(
    $input: DeleteRouteStaffRescuerInput!
    $condition: ModelRouteStaffRescuerConditionInput
  ) {
    deleteRouteStaffRescuer(input: $input, condition: $condition) {
      id
      group
      routeId
      staffId
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          recordTemplateJson
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const createMessageReader = /* GraphQL */ `
  mutation CreateMessageReader(
    $input: CreateMessageReaderInput!
    $condition: ModelMessageReaderConditionInput
  ) {
    createMessageReader(input: $input, condition: $condition) {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      readBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const updateMessageReader = /* GraphQL */ `
  mutation UpdateMessageReader(
    $input: UpdateMessageReaderInput!
    $condition: ModelMessageReaderConditionInput
  ) {
    updateMessageReader(input: $input, condition: $condition) {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      readBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const deleteMessageReader = /* GraphQL */ `
  mutation DeleteMessageReader(
    $input: DeleteMessageReaderInput!
    $condition: ModelMessageReaderConditionInput
  ) {
    deleteMessageReader(input: $input, condition: $condition) {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      readBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          counselingTypeId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const createMessageReadStatus = /* GraphQL */ `
  mutation CreateMessageReadStatus(
    $input: CreateMessageReadStatusInput!
    $condition: ModelMessageReadStatusConditionInput
  ) {
    createMessageReadStatus(input: $input, condition: $condition) {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageReadStatus = /* GraphQL */ `
  mutation UpdateMessageReadStatus(
    $input: UpdateMessageReadStatusInput!
    $condition: ModelMessageReadStatusConditionInput
  ) {
    updateMessageReadStatus(input: $input, condition: $condition) {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageReadStatus = /* GraphQL */ `
  mutation DeleteMessageReadStatus(
    $input: DeleteMessageReadStatusInput!
    $condition: ModelMessageReadStatusConditionInput
  ) {
    deleteMessageReadStatus(input: $input, condition: $condition) {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createPremiumStatusHistory = /* GraphQL */ `
  mutation CreatePremiumStatusHistory(
    $input: CreatePremiumStatusHistoryInput!
    $condition: ModelPremiumStatusHistoryConditionInput
  ) {
    createPremiumStatusHistory(input: $input, condition: $condition) {
      id
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const updatePremiumStatusHistory = /* GraphQL */ `
  mutation UpdatePremiumStatusHistory(
    $input: UpdatePremiumStatusHistoryInput!
    $condition: ModelPremiumStatusHistoryConditionInput
  ) {
    updatePremiumStatusHistory(input: $input, condition: $condition) {
      id
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const deletePremiumStatusHistory = /* GraphQL */ `
  mutation DeletePremiumStatusHistory(
    $input: DeletePremiumStatusHistoryInput!
    $condition: ModelPremiumStatusHistoryConditionInput
  ) {
    deletePremiumStatusHistory(input: $input, condition: $condition) {
      id
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const createMessageServiceProvider = /* GraphQL */ `
  mutation CreateMessageServiceProvider(
    $input: CreateMessageServiceProviderInput!
    $condition: ModelMessageServiceProviderConditionInput
  ) {
    createMessageServiceProvider(input: $input, condition: $condition) {
      id
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const updateMessageServiceProvider = /* GraphQL */ `
  mutation UpdateMessageServiceProvider(
    $input: UpdateMessageServiceProviderInput!
    $condition: ModelMessageServiceProviderConditionInput
  ) {
    updateMessageServiceProvider(input: $input, condition: $condition) {
      id
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const deleteMessageServiceProvider = /* GraphQL */ `
  mutation DeleteMessageServiceProvider(
    $input: DeleteMessageServiceProviderInput!
    $condition: ModelMessageServiceProviderConditionInput
  ) {
    deleteMessageServiceProvider(input: $input, condition: $condition) {
      id
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        flatMonthlyBillingAmount
        flatMonthlyBillingLabel
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const createTelnyx = /* GraphQL */ `
  mutation CreateTelnyx(
    $input: CreateTelnyxInput!
    $condition: ModelTelnyxConditionInput
  ) {
    createTelnyx(input: $input, condition: $condition) {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateTelnyx = /* GraphQL */ `
  mutation UpdateTelnyx(
    $input: UpdateTelnyxInput!
    $condition: ModelTelnyxConditionInput
  ) {
    updateTelnyx(input: $input, condition: $condition) {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteTelnyx = /* GraphQL */ `
  mutation DeleteTelnyx(
    $input: DeleteTelnyxInput!
    $condition: ModelTelnyxConditionInput
  ) {
    deleteTelnyx(input: $input, condition: $condition) {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
    }
  }
`;
export const createAuditLog = /* GraphQL */ `
  mutation CreateAuditLog(
    $input: CreateAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    createAuditLog(input: $input, condition: $condition) {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
    }
  }
`;
export const updateAuditLog = /* GraphQL */ `
  mutation UpdateAuditLog(
    $input: UpdateAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    updateAuditLog(input: $input, condition: $condition) {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
    }
  }
`;
export const deleteAuditLog = /* GraphQL */ `
  mutation DeleteAuditLog(
    $input: DeleteAuditLogInput!
    $condition: ModelAuditLogConditionInput
  ) {
    deleteAuditLog(input: $input, condition: $condition) {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
    }
  }
`;
export const createProperParkingSequenceSummary = /* GraphQL */ `
  mutation CreateProperParkingSequenceSummary(
    $input: CreateProperParkingSequenceSummaryInput!
    $condition: ModelProperParkingSequenceSummaryConditionInput
  ) {
    createProperParkingSequenceSummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProperParkingSequenceSummary = /* GraphQL */ `
  mutation UpdateProperParkingSequenceSummary(
    $input: UpdateProperParkingSequenceSummaryInput!
    $condition: ModelProperParkingSequenceSummaryConditionInput
  ) {
    updateProperParkingSequenceSummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProperParkingSequenceSummary = /* GraphQL */ `
  mutation DeleteProperParkingSequenceSummary(
    $input: DeleteProperParkingSequenceSummaryInput!
    $condition: ModelProperParkingSequenceSummaryConditionInput
  ) {
    deleteProperParkingSequenceSummary(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          flatMonthlyBillingAmount
          flatMonthlyBillingLabel
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProperParkingSequence = /* GraphQL */ `
  mutation CreateProperParkingSequence(
    $input: CreateProperParkingSequenceInput!
    $condition: ModelProperParkingSequenceConditionInput
  ) {
    createProperParkingSequence(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const updateProperParkingSequence = /* GraphQL */ `
  mutation UpdateProperParkingSequence(
    $input: UpdateProperParkingSequenceInput!
    $condition: ModelProperParkingSequenceConditionInput
  ) {
    updateProperParkingSequence(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const deleteProperParkingSequence = /* GraphQL */ `
  mutation DeleteProperParkingSequence(
    $input: DeleteProperParkingSequenceInput!
    $condition: ModelProperParkingSequenceConditionInput
  ) {
    deleteProperParkingSequence(input: $input, condition: $condition) {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
