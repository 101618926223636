import {
    onCreateReplaceByRouteByGroup,
    onUpdateReplaceByRouteByGroup,
    onDeleteReplaceByRouteByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription,
    updateElement,
    updateRenderKey
} from '../helper'
import EventBus from '@/eventBus'

"use strict";
let subscriptionReplaceByRoute = null

export const replaceByRouteSubscribe = function(instance){
    const queries = {
        onCreateReplaceByRouteByGroup,
        onUpdateReplaceByRouteByGroup,
        onDeleteReplaceByRouteByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionReplaceByRoute, queries, input, "subscriptionStore/handleReplaceByRoute", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionReplaceByRoute = subscriptions['replaceByRoute'] = {
        loadList: null,
        subscribe: replaceByRouteSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: replaceByRouteEventCallback,
        subscription
    }
}

export const replaceByRouteEventCallback = function(subscriptionState, {data: replaceByRoute, eventName}){
    if(!replaceByRoute?.id) return
    const matchDr = subscriptionState.dailyRosterCache.find(dr => dr.id === replaceByRoute.dailyRoster?.id)
    if(!matchDr) return
    const routes = matchDr.route.items
    if(!routes) return
    const matchR = routes.find(r => r.id === replaceByRoute.route?.id)
    if(!matchR) return
    if(!matchR.replaceByRoute?.items){
        matchR.replaceByRoute.items = []
    }

    //temporarily disable subscription updates when editing Replace By
    if(store.getters.getUpdatingDailyRosterRoute && eventName === 'onUpdate'){
        if(!subscriptionState.temporalReplaceByRouteId[replaceByRoute.route.id]){
            subscriptionState.temporalReplaceByRouteId[replaceByRoute.route.id] = []
        }
        subscriptionState.temporalReplaceByRouteId[replaceByRoute.route.id].push(replaceByRoute)
        return
    }
    const replaceByRoutes = matchR.replaceByRoute.items


    let eventBusPayload = {
        target: "table-data-routes",
        dailyRoster: matchDr,
        route: matchR,
        eventName
    }

    switch(eventName){
        case 'onCreate':{
            replaceByRoutes.push(replaceByRoute)
            eventBusPayload.updateRosterChecklistCell = true
            break
        }
        case 'onUpdate':{
            const matchRBy = replaceByRoutes.find(rb => rb.id === replaceByRoute.id)
            
            const oldStaffId = matchRBy?.staff?.id
            const newStaffId = replaceByRoute?.staff?.id
            const oldVehicleId = matchRBy?.vehicle?.id
            const newVehicleId = replaceByRoute?.vehicle?.id

            updateElement(matchRBy, replaceByRoute, true)

            if(oldStaffId != newStaffId || !oldVehicleId != !newVehicleId){
                eventBusPayload.updateRosterChecklistCell = true
            }
            break
        }
        case 'onDelete':{
            const index = replaceByRoutes.findIndex(rb => rb.id === replaceByRoute.id)
            if(index > -1){
                const deleted = replaceByRoutes.splice(index, 1)
            }
            eventBusPayload.updateRosterChecklistCell = true
            break
        }
    }
    updateRenderKey(routes)
    EventBus.$emit('update-route-render', eventBusPayload)
}