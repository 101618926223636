import { API } from "aws-amplify"
import store from '@/store'
import { Notification as notify } from 'element-ui'
const PUBLIC_VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY

export async function subscribeToPushNotifications() {
    try {
        // Wait for the service worker to be ready
        const registration = await navigator.serviceWorker.ready

        // Request notification permission from the user
        //'Notification' is https://developer.mozilla.org/en-US/docs/Web/API/Notification
        const permission = await Notification.requestPermission()
        //console.log({permission})
        if (permission === 'granted') {
            // Subscribe to push notifications
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
            });

            //console.log('Push Subscription:', subscription)
            sendSubscription(subscription)
        }else if(permission === 'denied'){
            notify({
                title: 'Warning', 
                message: 'Notifications are blocked. Reset browser permissions to receive push notifications', 
                type: 'warning'
            })
        }
    } catch (error) {
        console.error('Error during subscription:', error)
    }
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
    const rawData = window.atob(base64)
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)))
}

function sendSubscription(subscription) {
    let apiName = "heraPublicApi"
    let path = "/executeMutation"
    let post = {
        body: {
            type: "subscribe-push-notifications",
            group: store.state.userInfo.tenant.group,
            userId:  store.state.userInfo.id,
            subscriptionData: JSON.stringify(subscription),
            items: {},
        },
    }
    safeFunction(API.post)(apiName, path, post)
}

export async function checkPushSubscription() {
    const registration = await navigator.serviceWorker.ready
    const subscription = await registration.pushManager.getSubscription()
    return subscription // Returns the subscription or null
}