export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $nextToken: String
  ) {
    listUsers(filter: $filter, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const rosterChecklistsByGroup = /* GraphQL */ `
  query RosterChecklistsByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistsByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sendTime
        status
        completionFrequencyId
        completionFrequency{
          id
          key
        }
        vehicleTypes{
            items{
                id
                vehicleTypeId
            }
        }
        assignedToId
        assignedUsers{
          items{
            id
            userId
          }
        }
        checklistTasks{
          items{
            id
            type
            taskId
          }
        }
        rosterChecklistItems {
          nextToken
          items {
            order
            rosterChecklistId
            titleQuestion
            typeId
            id
            status
            descriptionExplanation
            requiredPhotoSpecifications
            photoSpecifications
          }
        }
      }
      nextToken
    }
  }
`;

export const rosterChecklistUsersByUser = /* GraphQL */ `
  query RosterChecklistUsersByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRosterChecklistUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rosterChecklistUsersByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        updatedAt
        rosterChecklistId
        userId
        rosterChecklist {
          assignedToId
          completionFrequencyId
          createdAt
          group
          id
          name
          updatedAt
          vehicleTypes{
              items{
                  id
                  vehicleTypeId
              }
          }
          assignedTo {
            value
            id
            key
          }
          checklistTasks {
            items {
              task {
                id
                value
                key
              }
            }
          }
          completionFrequency {
            value
            id
            key
          }
        }
      }
      nextToken
    }
  }
`;

export const getRosterChecklist = /* GraphQL */ `
  query GetRosterChecklist($id: ID!) {
    getRosterChecklist(id: $id) {
      id
      name
      completionFrequencyId
      assignedToId
      status
      vehicleTypes{
        items{
          id
          vehicleTypeId
        }
      }
      sendTime
      createdAt
      updatedAt
      assignedUsers{
        items{
          id
          userId
        }
      }
      checklistTasks{
        items{
          id
          type
          taskId
        }
      }
      rosterChecklistItems {
        items{
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          status
        }
      }
    }
  }
`;

export const listRoutes = /* GraphQL */ `
query ListRoutes(
  $filter: ModelRouteFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoutes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      staff {
        id
        firstName
        email
        lastName
      }
      routeStaffId
    }
    nextToken
  }
}
`;

export const dailyRostersByGroupAndNotesDate = /* GraphQL */ `
  query DailyRostersByGroupAndNotesDate(
    $group: String
    $notesDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyRostersByGroupAndNotesDate(
      group: $group
      notesDate: $notesDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        notesDate
        route {
            items {
              id
              routeStaffId
              staff {
                  id
              }
              replaceByRoute {
                items {
                  id
                  staff {
                      id
                  } 
                  status
                } 
              }
              helper{
                id
              }
              rescuers{
                items{
                  id
                  staffId
                }
              }
              status
              vehicle {
                id
              }
            }
        }
      }
      nextToken
    }
  }
`;

export const staffRosterChecklistByNotesDate = /* GraphQL */ `
  query StaffRosterChecklistByNotesDate(
    $notesDate: String
    $sortDirection: ModelSortDirection
    $filter: ModelStaffRosterChecklistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffRosterChecklistByNotesDate(
      notesDate: $notesDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        staffId
        rosterChecklistId
        notesDate
        id
        group
      }
      nextToken
    }
  }
`;