import { mixinMethods } from '@/main'
import store from '@/store'
import EventBus from '@/eventBus'
import { notificationsByOwnerAndIsReadS } from '@/graphql/queries'
import {onCreateNotification, onUpdateNotification} from '@/graphql/subscriptions';
import {updateNotification} from '@/store/mutations'
import { initializeSubscription, disconnectSubscription } from '../helper'

"use strict";
let subscriptionNotification = null

export const loadUnreadNotifications = async function(){
    if(!store.state.userInfo?.cognitoSub) return
    const input = { 
        owner: store.state.userInfo.cognitoSub,
        isReadS: { eq: "false" },
        sortDirection: 'DESC',
    }
    const unreadNotifications = await mixinMethods.gLoadListAll(notificationsByOwnerAndIsReadS, input, "notificationsByOwnerAndIsReadS");
    store.commit('setNotifications', unreadNotifications)
}

export const notificationSubscribe = function(instance){
    const queries = {
        onCreateNotification, 
        onUpdateNotification
    }
    if(!store.state.userInfo?.cognitoSub) return
    const input = {
        owner: store.state.userInfo.cognitoSub
    }
    initializeSubscription(subscriptionNotification, queries, input, "subscriptionStore/handleNotification", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionNotification = subscriptions['notification'] = {
        loadList: loadUnreadNotifications,
        subscribe: notificationSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: notificationEventCallback,
        subscription
    }
}

const loadNewVersion = async function(payload){
    //Check to see if we already have the latest version
    if(notification.description === store.state.version ){
        console.log("Already updated")
        return
    }

    //See if we need to logout
    if(payload.clickAction == 'LOGOUT'){
        try{
            console.log("LOGOUT")
            store.commit('setSessionInfo', null)
            store.commit('setUserInfo', null)
            document.location.href="/";
            location.reload();
        }catch(e){
            console.error('Logout Error', { e, payload });
        }
    }else{
        //Reload page
        location.reload();
    }
}

const importJobComplete = function (payload, type, importName = 'WeeklyDataImport'){
    const events = {
        'JOB COMPLETE':{
            'AmazonImport': 'mark-amazon-import-job-complete',
            'CustomCSVImport': 'mark-custom-csv-job-complete',
            'DailyDataImport': 'mark-daily-job-complete',
            'WeeklyDataImport': 'mark-job-complete'
        },
        'IMPORT RESULTS':{
            'AmazonImport': 'load-amazon-import-results',
            'CustomCSVImport': 'load-custom-csv-import-results',
            'DailyDataImport': 'load-daily-import-results',
            'WeeklyDataImport': 'load-import-results'
        }
    }
    EventBus.$emit(events[type][importName], payload)
}

export const notificationEventCallback = async function(subscriptionState, { data: notification, eventName }){
    const notificationPayload = JSON.parse(notification.payload)
    switch(eventName){
        case 'onCreate':{
            const pathname = window.location.pathname;
            if ( pathname === "/performance-and-coaching/weekly-performance-data-import" ) {
                try {
                    notification.isReadS = "true"
                    let input = {
                        id: notification.id,
                        isReadS: "true"
                    }
                    await mixinMethods.api(updateNotification, {input})
                } catch (e) {
                    console.error('Update Notification Error', { e, payload })
                }
            }
            if(notification.title == 'NEW VERSION' || notification.title == 'DEACTIVATE USER' || notification.title == 'Outstanding Invoices Paid'){
                await loadNewVersion(notification)
                return
            }
            else if(notification.title == 'JOB COMPLETE'){
                notificationPayload.notificationId = notification.id
                importJobComplete(notificationPayload, notification.title, notificationPayload.name)
                return
            }
            else if(notification.title.toLowerCase().includes('import complete')){
                const params = notificationPayload.params
                params.notification = notification
                importJobComplete(params, 'IMPORT RESULTS', notificationPayload.name)
            }
            else if(notification.title.includes('Error importing your weekly performance data') || notification.title.includes('Warning')){
                EventBus.$emit('weekly-performance-fails', notification)
            }
            else if(notification.title.includes('does not have a phone number')){
                EventBus.$emit('missing-phone-number', notification)
            }
            else if(notification.title.includes('Error importing your Amazon Spreadsheet')){
                EventBus.$emit('mark-amazon-import-error', notification)
            } 
            else if(notification.description.includes('Your messages have been successfully queued for delivery to the chosen Associates') || notification.description.includes('Your Vehicle Photo Log Associate Link')){
                EventBus.$emit('sent-messages-successfully', notification)
            } else if(notification.description.includes('All Associates have been successfully deleted')){
                EventBus.$emit('remove-all-associates', notification)
            } else if(notification.description.includes('There are currently no Associates to delete')){
                EventBus.$emit('no-associates-to-remove', notification)
            } else if(notification.description.includes('All Custom Lists were created successfully')){
                EventBus.$emit('customlists-created-successfully', notification)
            } else if(notification.description.includes('The Custom List was successfully updated')){
                EventBus.$emit('customlists-updates-successfully', notification)
            } else if(notification.description.includes('An error occurred while creating Custom Lists')){
                EventBus.$emit('customlists-created-error', notification)
            } else if(notification.description.includes('An error occurred while updating the Custom List')){
                EventBus.$emit('customlists-updates-error', notification)
            } else if(notification.description.includes('There were some errors when creating some options')){
                EventBus.$emit('customlists-created-error', notification)
            } else if(notification.description.includes('overridden your editing of the')){
                EventBus.$emit('override-resource-usage', notification)
            } else if(notification.description.includes('An error occurred while updating Note')){
                EventBus.$emit('note-updates-error', notification)
            } else if(notification.description.includes('Counselings have been successfully')){
                EventBus.$emit('counseling-table-updates', notification)
            } else if(notification.description.includes('An error occurred while updating Counseling')){
                EventBus.$emit('counseling-table-updates-error', notification)
            } else if(notification.description.includes('An error occurred while deleting Counseling')){
                EventBus.$emit('counseling-table-updates-error', notification)
            } else if(notification.description.includes('An error occurred while updating Tenant')){
                EventBus.$emit('tenant-table-updates-error', notification)
            } else if(notification.description.includes('Tenants have been successfully')){
                EventBus.$emit('tenant-table-updates', notification)
            }
            store.commit('addNotification', notification)
            break
        }
    }
}