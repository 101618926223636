export const createRosterChecklist = /* GraphQL */ `
  mutation CreateRosterChecklist(
    $input: CreateRosterChecklistInput!
    $condition: ModelRosterChecklistConditionInput
  ) {
    createRosterChecklist(input: $input, condition: $condition) {
      id
      name
      completionFrequencyId
      vehicleTypes
      assignedToId
      status
      assignedUsers{
        items{
          id
          userId
        }
      }
      checklistTasks{
        items{
          id
          taskId
        }
      }
      rosterChecklistItems {
        items {
          id
        }
        nextToken
      }
    }
  }
`;

export const updateRosterChecklist = /* GraphQL */ `
  mutation UpdateRosterChecklist(
    $input: UpdateRosterChecklistInput!
    $condition: ModelRosterChecklistConditionInput
  ) {
    updateRosterChecklist(input: $input, condition: $condition) {
      id
      name
      completionFrequencyId
      vehicleTypes
      assignedToId
      status
      createdAt
      updatedAt
    }
  }
`;

export const deleteRosterChecklist = /* GraphQL */ `
  mutation DeleteRosterChecklist(
    $input: DeleteRosterChecklistInput!
    $condition: ModelRosterChecklistConditionInput
  ) {
    deleteRosterChecklist(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createRosterChecklistItem = /* GraphQL */ `
  mutation CreateRosterChecklistItem(
    $input: CreateRosterChecklistItemInput!
    $condition: ModelRosterChecklistItemConditionInput
  ) {
    createRosterChecklistItem(input: $input, condition: $condition) {
      descriptionExplanation
      id
      order
      rosterChecklistId
      titleQuestion
      status
      typeId
      requiredPhotoSpecifications
      photoSpecifications
    }
  }
`;

export const updateRosterChecklistItem = /* GraphQL */ `
  mutation UpdateRosterChecklistItem(
    $input: UpdateRosterChecklistItemInput!
    $condition: ModelRosterChecklistItemConditionInput
  ) {
    updateRosterChecklistItem(input: $input, condition: $condition) {
      descriptionExplanation
      id
      order
      rosterChecklistId
      titleQuestion
      status
      typeId
      requiredPhotoSpecifications
      photoSpecifications
    }
  }
`;

export const deleteRosterChecklistItem = /* GraphQL */ `
  mutation DeleteRosterChecklistItem(
    $input: DeleteRosterChecklistItemInput!
    $condition: ModelRosterChecklistItemConditionInput
  ) {
    deleteRosterChecklistItem(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStaffRosterChecklist = /* GraphQL */ `
  mutation CreateStaffRosterChecklist(
    $input: CreateStaffRosterChecklistInput!
    $condition: ModelStaffRosterChecklistConditionInput
  ) {
    createStaffRosterChecklist(input: $input, condition: $condition) {
      id
      staffId
      rosterChecklistId
      notesDate
    }
  }
`;