<template>
    <div>
        <el-breadcrumb separator-class="uil uil-angle-right-b m-breadcrum">
            <el-breadcrumb-item :to="{ name: 'Dashboard' }"><i class="uil uil-home"></i></el-breadcrumb-item>
            <el-breadcrumb-item to="/settings">Company Settings</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'SettingsRosterChecklistsIndex' }">Roster Checklist Templates</el-breadcrumb-item>
            <el-breadcrumb-item v-if="breadcrumbChild">
                <CellToolTip :value.sync="breadcrumbChild" :maxLength="58">
                    <span class="break-all" slot-scope="{textValue}">{{ textValue | text }}</span>
                </CellToolTip>
            </el-breadcrumb-item>
        </el-breadcrumb>

        <router-view></router-view>
    </div>
</template>
<script>
import EventBus from '@/eventBus'
import CellToolTip from '@/components/CellToolTip'
export default{
    components:{
        CellToolTip
    },
    data(){
        return {
            breadcrumbChild: null
        }
    },
    created(){
        EventBus.$on('change-roster-checklist-breadcrumb-child', (breadcrumbChild) => this.breadcrumbChild = breadcrumbChild)
    },
    beforeDestroy(){
        EventBus.$off('change-roster-checklist-breadcrumb-child')
    },
}
</script>