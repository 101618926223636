<template>
  <div>
    <TableCard>
      <template v-slot:table-filters>
        <ContainerFilter inTable>
          <template v-slot:title>
            MESSAGE LOGS
          </template>
          <template v-slot:filter-count>
            <counter-filter :counter="getResumeFilters" />
          </template>
          <template v-slot:filters>                 
            <MultiSelectFilter
              v-if="showAssociateStatusFilter"
              type="status"
              :title="`${$t('label.associate')} Status`"
              :filterAllTitle="`${$t('label.associate')} Statuses`"
              :options="optionsStatus"
              :selected="filterOptionsStatus"
              @update-filters="updateFilters"
            />
            <MultiSelectFilter
              type="sent"
              title="Sent From"
              filterAllTitle="Sent From Locations"
              :options="sentFromOptions"
              :selected="sentFromOptionsSelected"
              @update-filters="updateFilters"
            />
            <MultiSelectFilter
              type="text"
              title="Text Status"
              filterAllTitle="Text Statuses"
              :options="textStatus"
              :selected="textStatusSelected"
              @update-filters="updateFilters"
            />
            <MultiSelectFilter
              type="email"
              title="Email Status"
              filterAllTitle="Email Statuses"
              :options="emailStatus"
              :selected="emailStatusSelected"
              @update-filters="updateFilters"
            />
            <SingleSelectFilter
              type="dateSent"
              title="Date Sent"
              :options="datesOptions"
              :currentFilter="dateSelected"
              @update-options="updateOptions"
            />
          </template>
        </ContainerFilter>
      </template>
      <template v-slot:table-searchbox>
        <SearchBox inTable @search-data="handleSearchLogs" />
      </template>
      <CustomTable
        inTable
        ref="tableLogs"
        :columns="columns"
        :data="filteredData"
        v-loading="loading"
        :optionsRows="[]"
        :filtersTotal="getResumeFilters"
        :total-records="allRecordsCount"
        :totalFilteredRecords="totalRecords"
        empty-text="No Messages Logs"
        footer-table=""
        withTableMenu
        @export-data="onExportData"
        showDefaultExportOptions
      >
        <template #channelType="row">
          <el-tooltip popper-class="custom-tooltip-higher-z-index" class="item" effect="dark" placement="top-start">
            <div slot="content" class="custom-tooltip-content-overflow">
              {{ row.channelType === 'RESPONSE' ? 'Incoming' : 'Outgoing' }}
            </div>
            <span class="table-icon-status-message">
              <i class="uil uil-comment-alt-download" v-if="row.channelType === 'RESPONSE'"></i>
              <i class="uil uil-comment-alt-upload" v-else></i>
            </span>
          </el-tooltip>
        </template>
        <template #createdAt="row">
          <p class="text-black">{{date_timezone(row.createdAt,'M/D/YYYY')}}</p>
          <p class="text-gray-600 text-xs">{{date_timezone(row.createdAt,'h:mm A')}}</p>
        </template>
        <template #staff="row">
          <p class="text-black" v-if="row.staff">{{row.staff.firstName}} {{row.staff.lastName }}</p>
          <p class="text-gray-600 text-xs">{{row.destinationEmail }}</p>
          <p class="text-gray-600 text-xs" v-if="row.destinationNumber">{{row.destinationNumber}}</p>
          <p class="text-gray-600 text-xs" v-else>Not sent to SMS</p>
        </template>
        <template #subject="row">
          <div class="flex">
            <el-tooltip popper-class="custom-tooltip-higher-z-index" class="item" effect="dark" placement="top-start">
              <div slot="content" class="custom-tooltip-content-overflow">
                <div v-if="row.subject" class="mb-4">{{row.subject}}</div>
                <MessageWithEmojie :bodyText="previewText(row)" />
              </div>
              <div>
                <p class="text-black width-300-px truncate">{{row.subject}}</p>
                <MessageWithEmojie :bodyText="previewText(row)" class="text-gray-600 text-xs width-480-px truncate" />
              </div>
            </el-tooltip>
            <el-tooltip popper-class="custom-tooltip-higher-z-index" class="item" effect="dark" placement="top-start">
              <div slot="content" class="custom-tooltip-content-overflow">
                {{ `View ${previewTitle(row)}` }}
              </div>
              <i class="uil uil-comment-message table-icon-status-message cursor-pointer" @click="previewDialog(row)" />
            </el-tooltip>
          </div>
        </template>
        <template #smsStatus="row">
          <el-tooltip popper-class="max-w-xs custom-tooltip-higher-z-index" :content="row.smsSendInformation" placement="bottom" v-if="row.channelType != 'RESPONSE'">
            <el-tag
              :type="getTagClass(row.smsStatus)"
              disable-transitions>{{row.smsStatus}}</el-tag>
          </el-tooltip>
          <el-tooltip
          :content="'Due to cell phone carrier limitations, this message was split into '+ row.totalParts +' parts before being delivered via SMS.'" popper-class="custom-tooltip-higher-z-index">
            <i v-if="row.totalParts" class="uil text-xl uil-info-circle"></i>
          </el-tooltip>
        </template>
        <template #emailStatus="row">
          <el-tooltip 
            v-if="row.channelType != 'RESPONSE'"
            popper-class="custom-tooltip-higher-z-index" 
            :content="row.messageType == 'messenger' ? 'Sent SMS through Hera messenger' : row.emailSendInformation" 
            placement="bottom" 
          >
            <el-tag
              :type="getTagClass(row.emailStatus)"
              disable-transitions>{{row.emailStatus}}
            </el-tag>
          </el-tooltip>
        </template>
      </CustomTable>
    </TableCard>
    <div class="w-full text-center py-2">
      <el-button round @click="loadData" v-if="nextToken">Load More</el-button>
    </div>
    
    <!--Export Dialog-->
    <el-dialog
        title="Export Messages"
        :show-close="true"
        :before-close="handleClose"
        :center="true"
        :visible.sync="showExportDialog"
        width="300px"
        :custom-class="'customized'">
        <ExportDialog
          :modal="true"
          exportField="message"
          exportFields="messages"
          :number="getResultTotal"
          :statusChecked="getStatusChecked()"
          @export-action="handleExportData"
          @close-dialog="handleClose"
          :hideFilters="!isFiltered">
        </ExportDialog>
    </el-dialog>
    <el-dialog     
      :visible.sync="htmlPreviewOpen"
      :title="dialogTitle"
      :close-on-click-modal="false"
      class="previewDialog"
      >
      <div v-if="htmlMessage.bodyHtml" v-html="processHtml(htmlMessage.bodyHtml)"></div>
      <div v-else-if="htmlMessage.attachment" class="flex justify-start">
        <img v-if="htmlMessage.isImage" :src="htmlMessage.url" :alt="htmlMessage.fileName" class="w-48"/>
        <a
          v-else :href="tempLink(htmlMessage.attachment.id)" target="_blank"
          class="mt-2 px-4 py-2 whitespace-pre-wrap rounded-lg inline-block text-center rounded-br-none bg-blue-600 text-white"
        >
            <i class="text-6xl uil uil-file-alt"></i>
            <div class="underline">{{htmlMessage.fileName}}</div>
        </a>
        <el-dropdown @command=handleDropdownCommand class="my-auto ml-3">
            <i class="context-menu uil uil-ellipsis-h"></i>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ action: 'download', htmlMessage }">Download</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
      </div>
      <MessageWithEmojie 
        v-if="htmlMessage.bodyText && !htmlMessage.bodyHtml"
        :bodyText="processBodyText(htmlMessage.bodyText)"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { messagesByGroup, messagesByStaffId } from './queries'
import { Auth, Storage } from 'aws-amplify'
import { joinMessages, MESSAGE_TYPE } from '@/utilities/joinMessages'
import MessageWithEmojie from '@/components/shared/Messenger/MessageWithEmojie'
import TableCard from "@/components/TableCard/TableCard";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import SearchBox from "@/components/TableCard/SearchBox";
import MultiSelectFilter from "@/components/MultiSelectFilter";
import SingleSelectFilter from "@/components/SingleSelectFilter";
import CounterFilter from "@/components/CounterFilters";
import CustomTable from "@/components/TableCard/CustomTable";
import ExportDialog from '@/components/ExportDialog.vue';
import momentTz from 'moment-timezone'
import { isInRange, calculateRange } from '@/utilities/dateRangeCalculator';
import { initialSingleDefaultFilter, multipleDefaultFilter } from '@/components/filtersFunctions'
import { API } from 'aws-amplify';

const urlRegex = /https?:\/\/[^\s]+\/u\/[^\s]+/g;
const urlReplaceText = "[URL sent to associate]"
export default {
  name: 'Messages',
  props: {
    staffId: {
      type: String,
    },
    group: {
      type: String,
    },
    showAssociateStatusFilter: {
      type: Boolean,
      default: true
    },
  },
  components: { 
    MessageWithEmojie, 
    TableCard, 
    ContainerFilter, 
    ExportDialog,
    SearchBox, 
    MultiSelectFilter, 
    SingleSelectFilter, 
    CounterFilter, 
    CustomTable 
  },
  data() {
    return {
      messages: [],
      nextToken: null,
      htmlPreviewOpen: false,
      htmlMessage: {},
      searchLogs: '',
      dialogTitle: null,
      loading: false,
      columns: [
        { name: undefined, col: "channelType", fixed: false, width: "50", sortable: false, },
        { name: "Sent", col: "createdAt", fixed: false, width: "100", sortable: true, sortMethod: undefined },
        { name: "Sent From", col: "messageTypeMapped", fixed: false, width: "130", sortable: true, sortMethod: undefined },
        { name: `${this.$t('label.associate')}`, col: "staff", fixed: false, width: "150", sortable: false, },
        { name: "Message", col: "subject", fixed: false, width: "450", sortable: false, },
        { name: "Text Status", col: "smsStatus", fixed: false, width: "110", sortable: false, },
        { name: "Email Status", col: "emailStatus", fixed: false, width: "110", sortable: false, },
      ],
      optionsStatus: [
        "Active",
        "Inactive - Failed Onboarding",
        "Inactive - Medical Leave",
        "Inactive - Misc",
        "Inactive - Personal Time/Vacation",
        "Inactive - Terminated",
        "Onboarding"
      ],
      sentFromOptions: Object.values(MESSAGE_TYPE),
      textStatus: ['Success', 'Pending', 'Not Sent', 'Error', 'Other'],
      emailStatus: ['Success', 'Pending', 'Not Sent', 'Error', 'Other'],
      datesOptions: [
        { value: "all dates", label: "All Dates", selected: false },
        { value: "current week", label: "Current Week", selected: false },
        { value: "last week", label: "Last Week", selected: false },
        { value: "last 2 weeks", label: "Last 2 Weeks", selected: false },
        { value: "last 3 weeks", label: "Last 3 Weeks", selected: false },
        { value: "previous month", label: "Previous Month", selected: false },
        { value: "last 30 days", label: "Last 30 Days", selected: true },
        { value: "last 60 days", label: "Last 60 Days", selected: false },
        { value: "last 90 days", label: "Last 90 Days", selected: false },
        { value: "current year", label: "Current Year", selected: false },
        { value: "last year", label: "Last Year", selected: false },
      ],
      dateSelected: "last 30 days",
      totalRecords: 0,
      allRecordsCount: 0,
      temporaryMessageLogList: [],
      showExportDialog: false,
      isFiltered: false,
    }
  },

  created(){
    const type = "dateSent"
    let defaultFilter = "last 30 days"
    const allSelected = true

    if(this.$route.query[type]) {
      const result = initialSingleDefaultFilter(type, defaultFilter, this.datesOptions, allSelected)
      this.dateSelected = result.newFilter
      this.datesOptions = result.newOptions
    }
  },
  mounted(){
    this.loadData()
  },

  computed:{
    ...mapState(['userInfo']),

    filterOptionsStatus: {
      get(){
        const filtersList = this.optionsStatus
        let defaultFilter = ["Active"]
        const query = this.$route.query["status"]

        return multipleDefaultFilter(query, defaultFilter, filtersList)        
      },
      set(items){},
    },

    sentFromOptionsSelected: {
      get(){
        const query = this.$route.query["sent"]
        const filtersList = this.sentFromOptions
        let defaultFilter = [...Object.values(MESSAGE_TYPE)]
        
        return multipleDefaultFilter(query, defaultFilter, filtersList) 
      },
      set(items){},
    },

    textStatusSelected: {
      get(){
        const query = this.$route.query["text"]
        const filtersList = this.textStatus
        let defaultFilter = ['Success', 'Pending', 'Not Sent', 'Error', 'Other']
        
        return multipleDefaultFilter(query, defaultFilter, filtersList)
      },
      set(items){},
    },

    emailStatusSelected: {
      get(){
        const query = this.$route.query["email"]
        const filtersList = this.emailStatus
        let defaultFilter = ['Success', 'Pending', 'Not Sent', 'Error', 'Other']
        
        return multipleDefaultFilter(query, defaultFilter, filtersList)
      },
      set(items){},
    },

    filteredData() {
      const data = this.filterArray(this.messages, this.searchLogs);
      this.totalRecords = data.length
      return data
    },

    getResumeFilters(){
      const countStatus = this.showAssociateStatusFilter ? this.optionsStatus.length === this.filterOptionsStatus.length ? 0 : 1 : 0;
      const countSentFrom = this.sentFromOptions.length === this.sentFromOptionsSelected.length ? 0 : 1;
      const countTextStatus = this.textStatus.length === this.textStatusSelected.length ? 0 : 1;
      const countEmailStatus = this.emailStatus.length === this.emailStatusSelected.length ? 0 : 1;
      const countDateSent = this.dateSelected === "all dates" ? 0 : 1;
      return countStatus + countSentFrom + countTextStatus + countEmailStatus + countDateSent;
    },

    getResultTotal() {
      return this.totalRecords || this.allRecordsCount
    },
  },

  methods: {
    date_timezone(date,format){
      if (!date) return '—'
      return momentTz.tz(date, this.getTenantTimeZone()).format(format)
    },
    previewText(row){
      let attachedText = null
      
      if (row.attachment) {
        if (row.isImage === undefined) {
          row.isImage = row.attachment.contentType && row.attachment.contentType.includes("image") ? true : false;
        }
        attachedText = row.isImage ? "[Image] " : "[File] "
      }

      let bodyText = row.bodyText || '';

      // Replace all occurrences of "/u/" in url"
      bodyText = bodyText.replace(urlRegex, urlReplaceText);

      return (attachedText || '') + bodyText;
    },
    processBodyText(text) {
      return text.replace(urlRegex, urlReplaceText);
    },

    previewTitle(row){
      if(row.bodyHtml){
        return 'HTML Message'
      }
      else if(row.attachment && !row.bodyText){
        return row.isImage ? 'Attached Image' : 'Attached File'
      }else{
        return 'Message'
      }
    },

    /** 
     * Load device data
     * */
    async loadData(){
      try{
        this.loading = true;
        let results = null;
        if (this.staffId) {
          let input = {
            nextToken: this.nextToken,
            sortDirection: 'DESC',
            staffId: this.staffId,
            limit: 20
          }
          results = await this.api(messagesByStaffId, input);
          this.nextToken = results.data.messagesByStaffId.nextToken
          results = this.messages.concat(results.data.messagesByStaffId.items)
        } else {
          let input = {
            group: this.group ? this.group : this.$store.state.userInfo.tenant.group,
            nextToken: this.nextToken,
            sortDirection: 'DESC',
          }
          results = await this.api(messagesByGroup, input);
          this.nextToken = results.data.messagesByGroup.nextToken
          results = this.messages.concat(results.data.messagesByGroup.items)   
        }
        
        const messagesList = joinMessages(results, true).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
        this.allRecordsCount = messagesList.length;
        let messagesFiltered = [];
        messagesFiltered = this.filterMessages( messagesList, this.filterByAssociateStatus );
        messagesFiltered = this.filterMessages( messagesFiltered, this.filterBySentFrom );
        messagesFiltered = this.filterMessages( messagesFiltered, this.filterByTextStatus );
        messagesFiltered = this.filterMessages( messagesFiltered, this.filterByEmailStatus );
        messagesFiltered = this.dateSelected === "all dates" ? messagesFiltered : this.filterByDateSent( messagesFiltered, this.dateSelected );
        this.temporaryMessageLogList = messagesList;
        this.messages = messagesFiltered;
      }catch(e){
        this.displayUserError(e)
      } finally {
        this.loading = false;
      }
    },

    async handleExportData() {
      this.displayUserNotification({
        title: "Export",
        type: "info",
        message: `Exported CSV file is being generated. Notification will be sent with download link once ready.`,
      });
      this.showExportDialog = false;

      let body = {
        type: 'custom',
        owner: this.$store.state.userInfo.cognitoSub,
        group: this.$store.state.userInfo.tenant.group,
        userId: this.$store.state.userInfo.id,
        isFormattingRequired: true,
        report: 'messageLogs',
        staffId: this.staffId,
        titleNotification: 'Message Logs',
      }

      if (this.isFiltered) {
        body.filters = {};
        if (this.filterOptionsStatus.length !== this.optionsStatus.length) body.filters.status = this.filterOptionsStatus;
        if (this.sentFromOptionsSelected.length !== this.sentFromOptions.length) body.filters.sentFrom = this.sentFromOptionsSelected;
        if (this.textStatusSelected.length !== this.textStatus.length) body.filters.textStatus = this.textStatusSelected;
        if (this.emailStatusSelected.length !== this.emailStatus.length) body.filters.emailStatus = this.emailStatusSelected;
        if (this.dateSelected) body.filters.dateSelected = this.dateSelected
        if (this.searchLogs) body.filters.search = this.searchLogs;
      }

      let req = {
        body: body,
      }

      try {
        await API.post('csvDataExport', '/data-export', req);
      } catch (e) {
        this.displayUserError(e)
      } finally {
        this.isFiltered = false;
      }
    },

    /**
     * Determines the correct tag color based on the message status
     */
    getTagClass(status){
      let className = null
      if(status == "Success"){
        className = 'success'
      }
      else if(status == 'Error'){
        className = 'danger'
      }
      return className
    },

    async checkAuth(){
      return await Auth.currentAuthenticatedUser().catch(error => {
          if(error === "not authenticated" || error === "No current user"){
            this.$router.push({
              path: '/',
              query: { redirect: window.location.pathname }
            })
            return {authError: true}
          }
      })
    },

    /**
     * Preview the HTML email sent out
     * @param {object} row - The message row
     */
    async previewDialog(row){
      const auth = await this.checkAuth()
      if(auth.authError){
        return
      }
      if(row.attachment && !row.url){
        await this.getFile(row)
      }
      this.htmlMessage = row
      this.dialogTitle = this.previewTitle(row) + ' Preview'
      this.htmlPreviewOpen = true
    },

    tempLink(id){
      return window.location.origin + '/f/' + id
    },

    async handleDropdownCommand({action, htmlMessage}){
      switch(action){
        case 'download':
          if(htmlMessage?.url){
            const a = document.createElement("a")
            a.href = this.tempLink(htmlMessage.attachment.id)
            a.target = '_blank'
            a.click()
            a.remove()
          }
          break
      }
    },

    async getFile(message){
      //Make S3 Link
      var fileUrl = await Storage.get(message.attachment.s3Key)
      message.fileName = message.attachment.s3Key.substr( message.attachment.s3Key.lastIndexOf('/') + 1)
      message.url = fileUrl
    },

    async handleSearchLogs(word) {
      this.searchLogs = word
    },
    
    updateFilters(e) {
      const { filters, type } = e;
      if ( type === 'status' ) {
        this.filterOptionsStatus = filters;
      } else if ( type === 'sent' ) {
        this.sentFromOptionsSelected = filters;
      } else if ( type === 'text' ) {
        this.textStatusSelected = filters;
      } else {
        this.emailStatusSelected = filters;
      }
      this.clientSideFilterListMessageLogs( this.temporaryMessageLogList );
    },

    getStatusChecked() {
      const dateSent = this.datesOptions.find(item => item.value == this.dateSelected)?.label || "";
      return [
        {
          filter: 'Associate Status',
          values: this.filterOptionsStatus
        },
        {
          filter: 'Sent From',
          values: this.sentFromOptionsSelected
        },
        {
          filter: 'Text Status',
          values: this.textStatusSelected
        },
        {
          filter: 'Email Status',
          values: this.emailStatusSelected
        },
        {
          filter: 'Date Sent',
          values: [dateSent]
        }
      ]
    },
    handleClose() {
      this.showExportDialog = false;
      this.isFiltered = false;
    },
    onExportData(type) {
      this.showExportDialog = true;
      this.isFiltered = type == 'filtered' ? true : false;
    },

    updateOptions(e) {
      this.datesOptions = e.options;
      this.dateSelected = e.currentFilter;
      this.clientSideFilterListMessageLogs( this.temporaryMessageLogList );
    },

    filterMessages( messagesList, filterFunction ) {
      return messagesList.filter( filterFunction );
    },

    filterByAssociateStatus( message ) {
      const { staff } = message;
      return staff?.status ? this.filterOptionsStatus.includes( staff.status ) : false;
    },

    filterBySentFrom( message ) {
      const { messageTypeMapped } = message;
      return this.sentFromOptionsSelected.includes( messageTypeMapped );
    },

    filterByTextStatus( message ) {
      const { smsStatus } = message;
      return this.textStatusSelected.includes( smsStatus );
    },

    filterByEmailStatus( message ) {
      const { emailStatus } = message;
      return this.emailStatusSelected.includes( emailStatus );
    },

    filterByDateSent(data, filterDate){
      const dataClone = [ ...data ];
      if( filterDate == 'all dates' ) return dataClone;
      const [ dateStart, dateEnd ] = calculateRange( filterDate );
      let dataFiltered = [];
      dataFiltered = dataClone.filter(message => {
        const { createdAt } = message;
        if(!createdAt) return false;
        return isInRange(dateStart, dateEnd, createdAt)
      })
      return dataFiltered;
    },

    // filters only client side
    filterMessagesByAssociateStatus( messageList ) {
      return messageList.filter( message => this.filterByAssociateStatus( message ) );
    },

    filterMessagesByEmailStatus( messageList ) {
      return messageList.filter( message => this.filterByEmailStatus( message ) );
    },

    filterMessagesByTextStatus( messageList ) {
      return messageList.filter( message => this.filterByTextStatus( message ) );
    },

    filterMessagesBySentFrom( messageList ) {
      return messageList.filter( message => this.filterBySentFrom( message ) );
    },

    filterMessagesByDateSent( messageList ) {
      return this.filterByDateSent( messageList, this.dateSelected ) 
    },

    clientSideFilterListMessageLogs( list ) {
      let messagesFiltered = this.filterMessagesByAssociateStatus( list );
      messagesFiltered = this.filterMessagesBySentFrom( messagesFiltered );
      messagesFiltered = this.filterMessagesByTextStatus( messagesFiltered );
      messagesFiltered = this.filterMessagesByEmailStatus( messagesFiltered );
      messagesFiltered = this.filterMessagesByDateSent( messagesFiltered );
      this.messages = [ ...messagesFiltered ];
    },
    processHtml(html) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // Define the regex to find all <a> tags with href containing "/u/"
      const anchorTags = tempDiv.querySelectorAll('a[href*="/u/"]');

      anchorTags.forEach(anchor => {
        const textNode = document.createTextNode(urlReplaceText);
        anchor.parentNode.replaceChild(textNode, anchor);
      });
      return tempDiv.innerHTML;
    },

  },
}
</script>
<style scoped>

.width-480-px{
  width: 480px !important;
}
.table-icon-status-message {
  font-size: 1.4em;
}
</style>

<style>
.cell {
  -webkit-line-clamp: unset !important;
}
.previewDialog .el-dialog{
  min-width: 27ch !important;
  width: fit-content !important;
  max-width: 100ch;
}
</style>